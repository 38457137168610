import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  auth: {},
};

const adminAuthSlice = createSlice({
  name: "SelectRow",
  initialState,
  reducers: {
    setIsAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    setUserAuth: (state, { payload }) => {
      state.auth = payload;
    },
    resetAuth: () => {
      return initialState;
    },
  },
});

export const { setIsAuthenticated, setUserAuth } = adminAuthSlice.actions;

export default adminAuthSlice.reducer;
