import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { CONFIRMEMAIL } from "../../constant/endpoints"
import axios from "axios"

const EmailConfirmation = () => {
    const { token } = useParams()

    const { data, isLoading } = useQuery({
        queryKey: [CONFIRMEMAIL],
        queryFn: () => axios({ method: "POST", url: CONFIRMEMAIL, data: { token } })
    })

    return (
        <>
            {isLoading
                ? <section className="w-screen h-screen flex justify-center items-center">
                    <div className="w-full max-w-[620px] p-6">
                        <p className="font-lato text-xl tracking-wider text-center my-10 text-[#aaaaaa]">Please wait ...</p>
                    </div>
                </section>
                : data.data.ResponseCode === "1"
                    ? <section className="w-screen h-screen flex justify-center items-center">
                        <div className="w-full max-w-[620px] p-6">
                            <h1 className="font-kaushan text-[#5892FF] text-7xl text-center">Thank you !</h1>
                            <p className="font-lato text-xl tracking-wider text-center my-10 text-[#aaaaaa]">Thanks for subscribing to our services.</p>
                        </div>
                    </section>
                    : <section className="w-screen h-screen flex justify-center items-center">
                        <div className="w-full max-w-[620px] p-6">
                            <h1 className="font-kaushan text-[#5892FF] text-7xl text-center">Page not found !</h1>
                        </div>
                    </section>}
        </>
    )
}

export default EmailConfirmation