import { format } from "date-fns";
import { toast } from "react-toastify";

const toastHandler = (res) => {
  return res.ResponseCode === "1"
    ? toast.success(res.ResponseMsg)
    : res.ResponseCode === "2"
      ? toast.success(res.ResponseMsg)
      : toast.error(res.ResponseMsg);
};

const columnWidthSizer = (id) => {
  if (id === "select") return "max-w-[3%]";
  if (id === "displayName") return "max-w-[50%]";
  if (id === "updatedAt") return "max-w-[10%]";
  if (id === "size") return "max-w-[6%]";
  if (id === "edit") return "max-w-[31%]";
};

const formatFileSize = (size, decimalPoint) => {
  const bytes = Number(size);
  if (+!bytes) return "0 Bytes";
  const k = 1024,
    dm = decimalPoint ?? 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const dowloadCSV = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const header = "S.No,FileName,FileLocation,AWB,FilePath,Uploaded on,FileSize,Uploaded By,Quality";


      const finalData = data.reduce((acc, curr, index) => {
        const { fileName, fileLocation, filePath, size, createdAt, uploadedBy, quality } = curr

        const awb = fileName.split("_")[0]

        const formatedFileSize = formatFileSize(size, 1)
        const formatedDate = format(new Date(createdAt), "d MMMM yyyy pp")

        const fileData = [fileName, fileLocation, awb, filePath, formatedDate, formatedFileSize, uploadedBy, quality]
        const rows = [index + 1, ...fileData].join(",");
        return acc.concat("\n", rows);
      }, header);

      if (finalData === header && Array.isArray(data) && data.length === 0)
        return reject("There is no files in this folder.");
      if (finalData === header) return reject("Please select files.");

      const blob = new Blob([finalData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      let a = document.createElement("a");
      a.style.visibility = "hidden";
      a.setAttribute("href", url);
      a.setAttribute("download", "download.csv");
      a.click();
      a.remove();
      resolve("CSV dowloaded successfully.");
    } catch (err) {
      console.log(err);
      reject("CSV dowload error.");
    }
  });
};

const isVideo = (type) => {
  if (typeof type === "string" && type.includes("video")) {
    return true;
  } else {
    return false;
  }
};

const isImage = (type) => {
  if (
    typeof type === "string" &&
    (type.includes("image") || type === "multipart/form-data")
  ) {
    return true;
  } else {
    return false;
  }
};

const getPageName = (pathname) => {
  switch (typeof pathname === "string") {
    case pathname.includes("/dispatch"):
      return "dispatch";
    case pathname.includes("/return"):
      return "return";
    default:
      return null;
  }
};

const downloadMultipleFiles = (files) => {
  return new Promise(async (res, rej) => {
    try {
      const downloadPromises = files.map(async (file) => {
        const fileData = await fetch(file.path);
        const fileBlob = await fileData.blob();
        let a = document.createElement("a");
        a.style.visibility = "hidden";
        a.href = window.URL.createObjectURL(fileBlob);
        a.download = file.displayName + "." + file.name.split('.').pop();
        a.click();
        a.remove();
      });

      await Promise.all(downloadPromises); // Wait for all files to be downloaded
      res("All files downloaded successfully.");
    } catch (error) {
      rej("Something went wrong while downloading files!");
    }
  });
};


const dowloadFile = (file) => {
  // return new Promise((resolve, reject) => {
  //   try {
  //     let a = document.createElement("a");
  //     a.style.visibility = "hidden";
  //     a.setAttribute("href", `https://vidscantech.com:4008/download?videourl=${file.path}`);
  //     a.setAttribute("download", file.name);
  //     a.click();
  //     a.remove();
  //     resolve("File dowloaded successfully.");
  //   } catch (err) {
  //     reject("Something went wrong while downloading file !");
  //   }
  // });

  return new Promise(async (res, rej) => {
    try {
      const fileData = await fetch(file.path);
      const fileBlob = await fileData.blob();
      let a = document.createElement("a");
      a.style.visibility = "hidden";
      a.setAttribute("href", window.URL.createObjectURL(fileBlob));
      a.setAttribute("download", file.displayName + "." + file.name.split('.').pop());
      a.click();
      a.remove();
      res("File dowloaded successfully.");
    } catch (error) {
      rej("Something went wrong while downloading file !");
    }
  });
};

const dowloadUserCSV = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const header =
        "S.No,Profile Image,Name,Email,Company Name,Phone Number,Credits,GST Number,Used Storage,Total Storage,Dispatch Credit,Dispatch Credit Invoices,Return Credit,Return Credit Invoices,Created At";

      const finalData = data.reduce((acc, curr, index) => {
        const phoneNumber = `+${curr.ccode} ${curr.phone}`;
        const usedStorage = formatFileSize(curr.usedStorage, 2);
        const totalStorage = formatFileSize(curr.storage, 2);
        const createdAt = format(new Date(curr.createdAt), "d MMMM yyyy pp");
        const {
          logo,
          name,
          email,
          company,
          credits,
          gst,
          totalDispatchCredits,
          totalDispatchInvoices,
          totalReturnCredits,
          totalReturnInvoices,
        } = curr;
        const userData = [
          logo,
          name,
          email,
          company,
          phoneNumber,
          credits,
          gst,
          usedStorage,
          totalStorage,
          totalDispatchCredits,
          totalDispatchInvoices,
          totalReturnCredits,
          totalReturnInvoices,
          createdAt,
        ];
        const rows = [index + 1, ...userData].join(",");
        return acc.concat("\n", rows);
      }, header);

      if (finalData === header && Array.isArray(data) && data.length === 0)
        return reject("There is no files in this folder.");
      if (finalData === header) return reject("Please select files.");

      const blob = new Blob([finalData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      let a = document.createElement("a");
      a.style.visibility = "hidden";
      a.setAttribute("href", url);
      a.setAttribute("download", "download.csv");
      a.click();
      a.remove();
      resolve("CSV dowloaded successfully.");
    } catch (err) {
      console.log(err);
      reject("CSV dowload error.");
    }
  });
};

const accountDeleteReasons = [
  "I'm using different account",
  "The app isn't working properly",
  "I'm worried about my privacy",
  "No one replies",
];

const trimString = (string, wordLength = 15, charLength = 30) => {
  if (typeof string === "string") {
    if (string.split(" ").length <= 15 && string.length <= 30) return string;

    const wordsArray = string.split(" ");

    if (wordsArray.length > 15)
      return wordsArray.splice(0, wordLength).join(" ") + "...";

    if (string.length > 30) return string.substring(0, charLength) + "...";
  } else return;
};

export {
  toastHandler,
  columnWidthSizer,
  dowloadCSV,
  isVideo,
  getPageName,
  formatFileSize,
  isImage,
  dowloadFile,
  dowloadUserCSV,
  accountDeleteReasons,
  trimString,
  downloadMultipleFiles
};
