import { Edit, X } from "lucide-react"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../components/ui/dialog'
import { Switch } from '../../components/ui/switch'
import { Label } from '../../components/ui/label'
import { Textarea } from '../../components/ui/textarea'
import { useRef, useState } from "react"
import { Input } from "../../components/ui/input"
import { Button } from "../../components/ui/button"
import { toast } from "react-toastify"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GETSETTINGS, UPDATESETTINGS } from "../../constant/endpoints"
import { privateAdminAxios } from "../../lib/axios"
import Loader from "../../components/Loader"
import { useForm } from "react-hook-form"
import { toastHandler } from '../../lib/helper'

const Pricing = () => {

  const [isDialog, setIsDialog] = useState({
    dispatch: false,
    return: false,
    credit: false
  })
  const oldValue = useRef({})
  const [isAppUpdateSetting, setIsAppUpdateSetting] = useState(false)

  const queryClient = useQueryClient()

  const { data: settings, isLoading, isFetching } = useQuery({
    queryKey: [GETSETTINGS],
    queryFn: () => privateAdminAxios(GETSETTINGS, null, "GET"),
    select: (res) => {
      if (res.ResponseCode === "1") {
        oldValue.current.dispatch_rate = res.data.dispatchRate
        oldValue.current.return_rate = res.data.returnRate
        oldValue.current.allocate_per_month_credit = res.data.allocatePerMonthCredit
        oldValue.current.allocate_storage = res.data.allocateStorage
        oldValue.current.version = res.data.version
        oldValue.current.force_update = res.data.forceUpdate
        oldValue.current.version_message = res.data.versionMessage
      }
      return res.data
    }
  })


  const { mutate } = useMutation({
    mutationFn: (values) => privateAdminAxios(UPDATESETTINGS, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [GETSETTINGS] });
      toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => {
      reset();
      setIsDialog({
        dispatch: false,
        return: false,
        credit: false
      })
      setIsAppUpdateSetting(false)
      appSetting.reset()
    },
  });

  const { handleSubmit, register, formState: { errors }, reset } = useForm({
    defaultValues: {
      values: ""
    },
    values: { values: isDialog?.return ? settings.returnRate : isDialog.dispatch ? settings.dispatchRate : isDialog.credit ? settings.allocatePerMonthCredit : "" }
  })

  const appSetting = useForm({
    values: { version: "", forceUpdate: "", versionMessage: "" }
  })

  const onSubmit = (val) => {
    const { values } = val
    const key = isDialog.return ? "return_rate" : isDialog.dispatch ? "dispatch_rate" : isDialog.credit ? "allocate_per_month_credit" : null
    return key !== null && mutate({ ...oldValue.current, [key]: values, })
  }

  const handleSubmitAppSettings = (values) => {
    const val = { version_message: values.versionMessage, force_update: values.forceUpdate, version: values.version }
    return mutate({ ...oldValue.current, ...val })
  }

  const closeModal = () => {
    setIsDialog({ dispatch: false, return: false, credit: false })
  }

  return (
    isFetching
      ? <Loader />
      : <div className="mt-6 px-2 l:px-4 gap-4 l:gap-6 grid grid-cols-1 m:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">

        <div className="p-2.5 l:p-4 shadow-[0_1px_6px_rgba(86,89,96)] rounded-xl">
          <h5 className="text-text font-lato text-lg l:text-xl">Credit Cost Per Dispacth</h5>
          <div className="mt-6 flex items-center justify-between text-text">
            <div className="w-full flex items-center">
              <span className="w-full max-w-[60px] l:max-w-[100px]">Cost :</span>
              <span className="py-1.5 l:py-2 px-2 l:px-3 rounded-lg bg-primary text-bg-primary">{settings?.dispatchRate ? settings.dispatchRate : "Not Found"} / dispatch</span>
            </div>
            <div className="cursor-pointr" onClick={() => setIsDialog(prevState => ({ ...prevState, dispatch: true }))}>
              <Edit className="w-5 h-5 l:w-6 l:h-6" />
            </div>
          </div>
        </div>

        <div className="p-2.5 l:p-4 shadow-[0_1px_6px_rgba(86,89,96)] rounded-xl">
          <h5 className="text-text font-lato text-lg l:text-xl">Credit Charge Per Month</h5>
          <div className="mt-6 flex items-center justify-between text-text">
            <div className="w-full flex items-center">
              <span className="w-full max-w-[60px] l:max-w-[100px]">Allocate :</span>
              <span className="py-1.5 l:py-2 px-2 l:px-3 rounded-lg bg-primary text-bg-primary">{settings?.allocatePerMonthCredit ? settings.allocatePerMonthCredit : "Not Found"} / month</span>
            </div>
            <div className="cursor-pointer" onClick={() => setIsDialog(prevState => ({ ...prevState, credit: true }))}>
              <Edit className="w-5 h-5 l:w-6 l:h-6" />
            </div>
          </div>
        </div>

        <div className="p-2.5 l:p-4 shadow-[0_1px_6px_rgba(86,89,96)] rounded-xl">
          <h5 className="text-text font-lato text-lg l:text-xl">Credit Cost Per Return</h5>
          <div className="mt-6 flex items-center justify-between text-text">
            <div className="w-full flex items-center">
              <span className="w-full max-w-[60px] l:max-w-[100px]">Cost :</span>
              <span className="py-1.5 l:py-2 px-2 l:px-3 rounded-lg bg-primary text-bg-primary">{settings?.returnRate ? settings.returnRate : "Not Found"} / return</span>
            </div>
            <div className="cursor-pointer" onClick={() => setIsDialog(prevState => ({ ...prevState, return: true }))}>
              <Edit className="w-5 h-5 l:w-6 l:h-6" />
            </div>
          </div>
        </div>

        <div className="p-2.5 l:p-4 shadow-[0_1px_6px_rgba(86,89,96)] rounded-xl">
          <h5 className="text-text font-lato text-lg l:text-xl">Application update settings</h5>
          <div className="mt-6 flex items-center justify-between gap-x-2 text-text">
            <div className="w-full flex items-center gap-x-2">
              <p className="w-max py-1.5 l:py-2 px-1 l:px-2 whitespace-nowrap text-sm rounded-lg bg-primary text-bg-primary">Version code {settings?.version}</p>
              <p className="w-max py-1.5 l:py-2 px-1 l:px-2 flex gap-x-2 text-sm items-center rounded-lg bg-primary text-bg-primary">Force <Switch className="h-4 w-[36px] data-[state=checked]:bg-bg-primary bg-bg-primary" thumbStyle="h-[14px] w-[14px] bg-black data-[state=checked]:translate-x-[18px]" checked={settings?.forceUpdate === "1"} /></p>
              <p className="w-full h-full max-h-[40px] overflow-auto overflow-ellipsis border px-0.5 border-text rounded-sm text-xs">{settings?.versionMessage}</p>
            </div>
            <div className="cursor-pointer" onClick={() => {
              setIsAppUpdateSetting(true)
              appSetting.reset({ version: settings.version, forceUpdate: settings.forceUpdate, versionMessage: settings.versionMessage })
            }}>
              <Edit className="w-5 h-5 l:w-6 l:h-6" />
            </div>
          </div>
        </div>

        <Dialog open={isDialog.return || isDialog.dispatch || isDialog.credit} onOpenChange={closeModal} className="outline-none border-none">
          <DialogContent className="bg-bg2 outline-none">
            <DialogHeader>
              <DialogTitle className="pb-4 flex justify-between items-center text-text text-2xl font-medium tracking-wider font-lato">
                <span>
                  {isDialog.return && "Edit Return Cost"}
                  {isDialog.dispatch && "Edit Dispatch Cost"}
                  {isDialog.credit && "Edit Upload Cost"}
                </span>
                <X className="w-6 h-6 cursor-pointer" onClick={closeModal} />
              </DialogTitle>
              <hr className="-mx-6 border-text3" />
              <DialogDescription className="pt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input step="any"
                    type="number" placeholder={isDialog.return ? "Enter return cost" : isDialog.dispatch ? "Enter dispatch cost" : isDialog.credit ? "Enter credit per month" : ""} className="bg-bg2-hover" {...(register("values", { required: "This field is required" }))} />
                  {errors.values && <p className="mt-0.5 text-sm font-normal text-red-600">{errors.values.message}</p>}
                  <div className="flex justify-end items-center gap-x-4 mt-4">
                    <Button type="button" onClick={closeModal}>Cancel</Button>
                    <Button type="submit">Save</Button>
                  </div>
                </form>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>

        <Dialog open={isAppUpdateSetting} onOpenChange={() => setIsAppUpdateSetting(false)} className="outline-none border-none">
          <DialogContent className="bg-bg2 outline-none">
            <DialogHeader>
              <DialogTitle className="pb-4 flex justify-between items-center text-text text-2xl font-medium tracking-wider font-lato">
                <span>Application update settings</span>
                <X className="w-6 h-6 cursor-pointer" onClick={() => setIsAppUpdateSetting(false)} />
              </DialogTitle>
              <hr className="-mx-6 border-text3" />
              <DialogDescription className="pt-4">
                <form onSubmit={appSetting.handleSubmit(handleSubmitAppSettings)} className="flex flex-col gap-y-4">
                  <div className="flex flex-col gap-y-1">
                    <Label className="text-base text-text">Version code</Label>
                    <Input type="number" min={0} step={1} placeholder="Enter appllication version" className="bg-bg2-hover" {...(appSetting.register("version", { required: "This field is required" }))} />
                  </div>
                  <div className="flex gap-x-6">
                    <Label className="text-base text-text">Force update</Label>
                    <Switch {...(appSetting.register("forceUpdate", { required: "This field is required" }))} checked={appSetting.watch("forceUpdate") === "1"} onCheckedChange={(e) => appSetting.setValue("forceUpdate", e ? "1" : "0")}
                      className="data-[state=checked]:bg-primary" thumbStyle="data-[state=checked]:translate-x-[20px]" />
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <Label className="text-base text-text">Message</Label>
                    <Textarea className="min-h-[100px] bg-transparent border-text" {...(appSetting.register("versionMessage", { required: "This field is required" }))} />
                  </div>
                  {errors.values && <p className="mt-0.5 text-sm font-normal text-red-600">{errors.values.message}</p>}
                  <div className="flex justify-end items-center gap-x-4 mt-4">
                    <Button type="button" onClick={() => setIsAppUpdateSetting(false)}>Cancel</Button>
                    <Button type="submit">Save</Button>
                  </div>
                </form>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </div>
  )
}

export default Pricing