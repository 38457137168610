const SuccessStories = () => {
    return (
        <section className="py-4">
            <div className="border-b mb-4 pb-4">
                <h3 className="py-2 px-3 text-text text-center font-bold text-4xl font-lato">
                    Customer Success Stories
                </h3>
            </div>
            <div className="p-4 w-full max-w-[750px] mx-auto space-y-6">
                <p className="text-base leading-[30px] tracking-wide">
                    Our success is measured by the success of our users. Here are some stories from satisfied vendors who have experienced the impact of Vidscan Tech:
                </p>
                <hr />
                <div className="space-y-4">
                    <p className="text-xl font-bold leading-[30px] tracking-wide">Customer Success Story :</p>
                    <div className="text-base leading-[30px] tracking-wide"><span>Name:</span> <span className="font-medium">Rajesh's Ethnic Emporium</span></div>
                    <div className="text-base leading-[30px] tracking-wide"><span>Industry:</span> <span className="font-medium">Men's Ethnic Wear Retailer</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Challenge:</span> <span className="font-medium">Rajesh's Ethnic Emporium faced challenges with customers claiming that the received garments did not match the descriptions. Without solid evidence, disputes were common, and it affected the store's credibility.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Solution:</span> <span className="font-medium">Rajesh discovered Vidscan Tech and started using it to record video proof of every traditional outfit being packed and labeled. This helped provide clear evidence of the garments' quality and condition at the time of dispatch.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Results:</span> <span className="font-medium">With Vidscan Tech, Rajesh's Ethnic Emporium witnessed a significant drop in return requests due to product discrepancies. The video proof strengthened trust among customers, leading to an increase in online orders and positive word-of-mouth recommendations.</span></div>
                </div>
                <hr />
                <div className="space-y-4">
                    <p className="text-xl font-bold leading-[30px] tracking-wide">Customer Success Story :</p>
                    <div className="text-base leading-[30px] tracking-wide"><span>Name:</span> <span className="font-medium">Shreya's Trendy Threads</span></div>
                    <div className="text-base leading-[30px] tracking-wide"><span>Industry:</span> <span className="font-medium">Women's Fashion Boutique</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Challenge:</span> <span className="font-medium">Shreya's Trendy Threads dealt with an increase in fraudulent return claims, impacting their profits and inventory management. They needed a reliable solution to minimize such claims.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Solution:</span> <span className="font-medium">Shreya integrated Vidscan Tech into their dispatch process, recording video proof of each trendy outfit being carefully packaged. This video evidence allowed them to verify the condition and authenticity of every item before shipping.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Results:</span> <span className="font-medium">Thanks to Vidscan Tech, Shreya's Trendy Threads experienced a significant reduction in false return claims, resulting in cost savings and better inventory control. The video proof also elevated their brand reputation and inspired customer confidence, leading to increased customer loyalty.</span></div>
                </div>
                <hr />
                <div className="space-y-4">
                    <p className="text-xl font-bold leading-[30px] tracking-wide">Customer Success Story :</p>
                    <div className="text-base leading-[30px] tracking-wide"><span>Name:</span> <span className="font-medium">Little Angels' Fashion Hub</span></div>
                    <div className="text-base leading-[30px] tracking-wide"><span>Industry:</span> <span className="font-medium">Kids' Apparel Retailer</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Challenge:</span> <span className="font-medium">Little Angels' Fashion Hub encountered challenges with customers returning damaged or mismatched clothes for kids. This led to inventory discrepancies and hindered their growth.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Solution:</span> <span className="font-medium">Little Angels' Fashion Hub implemented Vidscan Tech and started recording video proof of each kids' garment being packed and labeled with utmost care. This video evidence ensured the accuracy and condition of every shipment.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Results:</span> <span className="font-medium">With Vidscan Tech, Little Angels' Fashion Hub achieved a substantial reduction in return requests and disputes related to kids' clothing. The video proof instilled trust among parents, leading to positive feedback and an increase in customer referrals.</span></div>
                </div>
                <hr />
                <div className="space-y-4">
                    <p className="text-xl font-bold leading-[30px] tracking-wide">Customer Success Story :</p>
                    <div className="text-base leading-[30px] tracking-wide"><span>Name:</span> <span className="font-medium">Footstyle Family Footwear</span></div>
                    <div className="text-base leading-[30px] tracking-wide"><span>Industry:</span> <span className="font-medium">Footwear Retailer for the Entire Family</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Challenge:</span> <span className="font-medium">Footstyle Family Footwear faced difficulties with customers claiming to receive mismatched or damaged shoes. This led to increased return rates and operational challenges.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Solution:</span> <span className="font-medium">Footstyle Family Footwear adopted Vidscan Tech to record video proof of each pair of shoes being carefully packed and labeled with the utmost attention to detail.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Results:</span> <span className="font-medium">With Vidscan Tech, Footstyle Family Footwear witnessed a remarkable decrease in return requests and complaints about shoe quality. The video proof provided reassurance to customers, resulting in higher customer satisfaction and improved sales.</span></div>
                </div>
                <hr />
                <p className="text-base leading-[30px] tracking-wide">
                    These fictional success stories exemplify how Vidscan Tech can be a valuable tool for men's, women's, kids' garments, and footwear businesses in India, helping them build trust with their customers and streamline their operations. Please note that these stories are for illustrative purposes only and do not represent actual businesses.
                    Customer Success Story: Dress Code Fashion
                </p>
                <hr />
                <div className="space-y-4">
                    <div className="text-base leading-[30px] tracking-wide"><span>Industry:</span> <span className="font-medium">Kids' Apparel Vendor on FirstCry Marketplace</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Challenge:</span> <span className="font-medium">Dress Code, a prominent vendor specializing in kids' apparel on the FirstCry marketplace, faced a significant challenge with recurring penalties amounting to over INR 50,000 each month. These penalties were imposed due to alleged incorrect dispatches and returns, impacting the vendor's profitability and reputation.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Solution:</span> <span className="font-medium">Determined to overcome this challenge and provide undeniable evidence of their dispatch accuracy, Dress Code decided to integrate Vidscan Tech into their order fulfillment process. With Vidscan Tech, Dress Code’s team could now record detailed video proof of each shipment, capturing the impeccable condition of every clothing item during packaging.</span></div>
                    <div className="text-base leading-[26px] tracking-wide"><span>Results:</span> <span className="font-medium">The implementation of Vidscan Tech yielded impressive results for Dress Code. Within the first month, the penalties reduced significantly to less than INR 1,000. The video proof provided indisputable evidence of their precise and reliable dispatches, leading to a substantial decrease in customer disputes and return requests.</span></div>
                </div>
                <hr />

                <div class="space-y-2">
                    <h5 class="text-2xl font-semibold tracking-wider">Additional Benefits:</h5>
                    <p class="text-base leading-[30px] tracking-wide">Enhanced Customer Trust: With fewer penalties and improved order accuracy, Dress Code Fashion earned the trust of customers on the FirstCry marketplace. Positive feedback and testimonials further strengthened their reputation.
                        Operational Efficiency: Vidscan Tech streamlined Dress Code Fashion's dispatch process, optimizing operations and reducing the time spent on dispute resolution.</p>
                    <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Revenue Growth:</span> The reduction in penalties and returns translated into increased revenue and better overall financial performance for the company. Competitive Advantage: With Vidscan Tech's video proof service, Dress Code Fashion gained a competitive edge over other vendors, attracting more customers seeking reliable and trustworthy sellers.</p>
                    <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Vendor Testimonial:</span> "Dress Code owes its success to Vidscan Tech! We were struggling with penalties and customer disputes that impacted our growth. However, since embracing Vidscan Tech, our penalties have significantly decreased, and we've witnessed a boost in customer trust and loyalty. This innovative tool has helped us deliver on our promise of accuracy and reliability, setting us apart as a preferred choice on the FirstCry marketplace." - Ranjana Goyal, Founder, Dress Code.</p>
                </div>
            </div>
        </section>
    )
}

export default SuccessStories