import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateProfileSchema } from "../../lib/schemas";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ChevronDown } from "lucide-react";
import { twMerge } from "tailwind-merge";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import { useDispatch, useSelector } from "react-redux";
import countyCode from "../../lib/countrycode.json";
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "../../components/ui/dialog";
import { useMutation } from "@tanstack/react-query";
import { privateAxios } from "../../lib/axios";
import { DELETEACCOUNT, UPDATEPROFILE } from "../../constant/endpoints";
import { accountDeleteReasons, toastHandler } from "../../lib/helper";
import { setIsAuthenticated, setUserAuth } from "../../redux/reducers/userAuthSlice";

import usericon from "../../assets/images/usericon.png"
import usePaths from "../../hooks/usePaths";
import { cn } from "../../components/utils";
import { toast } from "react-toastify";

const Profile = () => {
  const { phone, name, email, gst, company, ccode, logo } = useSelector((state) => state.userAuthReducer.auth);
  const [isCountrySelector, seIsCountrySelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(`+${ccode}`);
  const [profileImage, setProfileImage] = useState(null);
  const [accountDeleteReason, setAccountDeleteReason] = useState(null)
  const [isDeleteAccount, setIsDeleteAccount] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isMainuser } = usePaths();

  let sholdVefifyOTP = false;

  const form = useForm({
    resolver: zodResolver(updateProfileSchema),
    defaultValues: { phone, name, email, gst, company },
  });

  if (
    phone !== form.getValues("phone") ||
    profileImage !== null ||
    ccode !== selectedCountry.slice(1, selectedCountry.length)
  ) {
    sholdVefifyOTP = true;
  } else {
    sholdVefifyOTP = false;
  }

  const { mutate: editProfile } = useMutation({
    mutationFn: (values) => privateAxios(UPDATEPROFILE, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        dispatch(setUserAuth(res.data));
        const { phone, name, email, gst, company } = res.data;
        form.reset({ phone, name, email, gst, company });
        setProfileImage(null);
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const { mutate: deleteAccount } = useMutation({
    mutationFn: (values) => privateAxios(DELETEACCOUNT, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        dispatch(setIsAuthenticated(false));
        dispatch(setUserAuth({}));
        navigate("/")
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const onSubmit = (values) => {
    const ccode = selectedCountry.slice(1, selectedCountry.length);
    if (sholdVefifyOTP) {
      const data = { phone: values.phone, ccode };
      return updateProfile(data);
    } else {
      const { email, ...values } = form.getValues();

      const updatedProfileData = {
        ...values,
        ccode,
        ...(profileImage?.name && { logo: profileImage }),
      };
      const formData = new FormData();
      Object.entries(updatedProfileData).forEach(([key, value]) =>
        formData.append(key, value)
      );
      return editProfile(formData);
    }
  };

  const updateProfile = () => {
    const { email, ...values } = form.getValues();

    const updatedProfileData = {
      ...values,
      ccode: selectedCountry.slice(1, selectedCountry.length),
      ...(profileImage?.name && { logo: profileImage }),
    };

    const formData = new FormData();

    Object.entries(updatedProfileData).forEach(([key, value]) =>
      formData.append(key, value)
    );

    return editProfile(formData);
  };

  const handleDeleteAccount = () => {
    if (accountDeleteReason && accountDeleteReason !== null) {
      return deleteAccount({ reason: accountDeleteReason })
    } else {
      return toast.error("Please select your reason for deleting account")
    }
  }
  
  return (
    <div className="w-full max-w-3xl h-full mx-auto flex flex-col justify-center">
      <div className="mx-auto">
        <input
          type="file"
          id="profilimage"
          className="hidden w-0 h-0"
          onChange={({ target }) => setProfileImage(target.files[0] ?? null)}
        />
        <label htmlFor="profilimage" className="cursor-pointer">
          <Avatar className="w-20 h-20">
            <AvatarImage
              src={profileImage
                ? URL.createObjectURL(profileImage)
                : logo
                  ? logo
                  : usericon
              }
            />

            <AvatarFallback className="uppercase bg-bg-primary">
              {name.slice(0, 2) ?? ""}
            </AvatarFallback>
          </Avatar>
        </label>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          noValidate
          className="w-full h-max space-y-6"
        >
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel
                  htmlFor="mobileNumber"
                  className="text-text text-base font-lato"
                >
                  Mobile Number
                </FormLabel>
                <FormControl>
                  <div className="flex rounded-lg">
                    <Popover
                      open={isCountrySelector}
                      onOpenChange={() => isMainuser && seIsCountrySelector(!isCountrySelector)}
                    >
                      <PopoverTrigger className={cn("w-full max-w-[85px] px-4 flex items-center justify-between gap-x-1 rounded-tl-lg rounded-bl-lg outline-none bg-bg2-hover", isMainuser ? "opacity-100" : "opacity-50")}>
                        <span className="text-sm text-text">
                          {selectedCountry}
                        </span>
                        <ChevronDown className="w-4 h-4 text-text2" />
                      </PopoverTrigger>
                      <PopoverContent align="start" className="p-0">
                        <Command className="bg-bg2 text-text2">
                          <CommandInput
                            placeholder="Search country name"
                            className="placeholder:text-text2"
                          />
                          <CommandList>
                            <CommandEmpty className="py-4 text-center">
                              No country found
                            </CommandEmpty>
                            {countyCode.countries.map((country, index) => {
                              return (
                                <CommandItem
                                  key={index}
                                  className={twMerge(
                                    "aria-selected:bg-bg2-hover text-text aria-selected:text-text cursor-pointer",
                                    selectedCountry === country.dial &&
                                    "bg-bg2-hover aria-selected:bg-bg2-hover cursor-default"
                                  )}
                                  onSelect={() =>
                                    selectedCountry === country.dial
                                      ? null
                                      : (setSelectedCountry(country.dial),
                                        seIsCountrySelector(false))
                                  }
                                >
                                  {country.name}
                                </CommandItem>
                              );
                            })}
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <Input
                      id="mobileNumber"
                      type="number"
                      placeholder="Enter your mobile number"
                      {...field}
                      className="ps-0 rounded-tl-none rounded-bl-none border-transparent"
                      disabled={!isMainuser}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex gap-x-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Name
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your name" {...field} disabled={!isMainuser} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Email
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your email" {...field} onChange={({ target: { value } }) => form.setValue("email", value.toLowerCase())} disabled={!isMainuser} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex gap-x-4">
            <FormField
              control={form.control}
              name="gst"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    GST Number
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your GST number" {...field} disabled={!isMainuser} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="company"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Company Name
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your company name" {...field} disabled={!isMainuser} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Button
            type="submit"
            className="w-full font-lato font-bold text-lg bg-primary transition-all disabled:cursor-not-allowed"
            disabled={!(form.formState.isDirty || sholdVefifyOTP)}
          >
            Update Profile
          </Button>

          <div className="flex flex-col justify-between items-center gap-y-1 text-center">
            <Link
              to="/change-password"
              className="text-center text-primary text-base cursor-pointer font-semibold font-lato"
            >
              Change Password?
            </Link>
            <Button onClick={() => setIsDeleteAccount(true)} type="button" className="h-max p-0 text-center text-red-600 text-base cursor-pointer font-semibold font-lato bg-transparent hover:bg-transparent">
              Delete account?
            </Button>
          </div>
        </form>
      </Form>

      <Dialog open={isDeleteAccount} onOpenChange={(e) => (setIsDeleteAccount(!isDeleteAccount), setAccountDeleteReason(null))}>
        <DialogContent className="gap-y-10">
          <DialogHeader>
            <h2 className="text-2xl text-text1 font-medium">You sure you want to delete you account?</h2>
            <span className="text-sm text-text2">You will lose all of your data</span>
            <ul className="pt-4 flex flex-col gap-y-1.5">
              {accountDeleteReasons.map((r, i) => {
                return <li key={i} className="flex gap-x-2 items-center">
                  <input type="radio" id={r} name="accoundDeleteReason" className="cursor-pointer" onChange={({ target: { checked } }) => checked && setAccountDeleteReason(r)} />
                  <label className="text-text1 text-sm font-medium cursor-pointer" htmlFor={r}>{r}</label>
                </li>
              })}
              <li className="flex flex-col justify-center gap-x-2">
                <div className="flex gap-x-2 mb-1">
                  <input type="radio" id="other" name="accoundDeleteReason" className="cursor-pointer" onChange={({ target: { checked } }) => checked && setAccountDeleteReason("other")} />
                  <label className="text-text1 text-sm font-medium cursor-pointer" htmlFor="other">Other</label>
                </div>
                <textarea className="px-2 py-1 border rounded-md border-text text-sm" rows="4" disabled={accountDeleteReasons.includes(accountDeleteReason) || accountDeleteReason === null}
                  onChange={({ target: { value } }) => setAccountDeleteReason(value)}></textarea>
              </li>
            </ul>
          </DialogHeader>
          <div className="flex justify-between gap-x-4">
            <Button className="w-full" onClick={() => (setIsDeleteAccount(false), setAccountDeleteReason(null))}>Cancel</Button>
            <Button className="w-full" onClick={handleDeleteAccount} disabled={!isMainuser}>Delete Account</Button>
          </div>
        </DialogContent>
      </Dialog>

    </div>
  );
};

export default Profile;
