import { useMemo, useState } from "react";
import DataTable from "../../components/DataTable";
import { addedUsers as DATA } from "../../Data";
import { createColumnHelper } from "@tanstack/react-table";
import { Checkbox } from "../../components/ui/checkbox";
import {
  Download,
  Edit2,
  MoreVertical,
  Share2,
  Star,
  FolderIcon,
  ArrowUp,
  ArrowDown,
  FileVideo,
  UserMinus,
  UserCog,
  ChevronDown,
} from "lucide-react";
import { Badge } from "../../components/ui/badge";
import ActionDropdown from "../../components/ActionDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyAllSelectedRows,
  removeSelectedRows,
  resetRowSelection,
  setSelectedRows,
} from "../../redux/reducers/rowSlice";
import { useNavigate } from "react-router-dom";
import {
  DELETESUBUSERS,
  GETSUBUSERS,
  SENDOTP,
  UPDATESUBUSER,
} from "../../constant/endpoints";
import { useDebounce } from "@uidotdev/usehooks";
import { resetFilter, setTotalPage } from "../../redux/reducers/filterSlice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { privateAxios } from "../../lib/axios";
import { format } from "date-fns";
import usePageLeave from "../../hooks/usePageLeave";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { toastHandler } from "../../lib/helper";
import { toast } from "react-toastify";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../components/ui/command";
import countyCode from "../../lib/countrycode.json";
import { twMerge } from "tailwind-merge";
import { addNewSubuser } from "../../lib/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import Loader from "../../components/Loader";
import md5 from "md5";

const ManageSubuser = () => {
  const { page, limit, search = "", start, end } = useSelector((state) => state.filterReducer);
  const dispatch = useDispatch();

  const [sorting, setSorting] = useState([]);
  const [editSubUser, setEditSubUser] = useState({ open: false, data: null });
  const [deletSubuser, setDeletSubuser] = useState({ open: false, data: null });
  const [selectedCountry, setSelectedCountry] = useState("+92");
  const [isCountrySelector, seIsCountrySelector] = useState(false);
  const [OTP, setOTP] = useState("");

  const queryClient = useQueryClient();

  const debouncedSearch = useDebounce(search, 300);
  usePageLeave(() => {
    dispatch(resetFilter());
    dispatch(resetRowSelection());
  });

  const columnHelper = createColumnHelper();

  const { data: subuserData, isLoading } = useQuery({
    queryKey: [GETSUBUSERS, page, limit, debouncedSearch, start, end],
    queryFn: () =>
      privateAxios(
        GETSUBUSERS,
        { page, limit, start, end, search: search.replace("+", "").replace(/\s/g, '') },
        null,
        page,
        limit
      ),
    onSuccess: (res) => {
      return dispatch(setTotalPage(Math.ceil((res.totalRecords ?? 1) / limit)));
    },
    initialData: {},
  });

  const data = subuserData?.data ?? [];

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(DELETESUBUSERS, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [GETSUBUSERS, page, limit, debouncedSearch, start, end],
      });
      toastHandler(res);
    },
    onError: (err) => toast(err),
    onSettled: () => setDeletSubuser({ open: false, data: null }),
  });

  const { mutate: editSubuser } = useMutation({
    mutationFn: (values) => privateAxios(UPDATESUBUSER, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        key: [GETSUBUSERS, page, limit, debouncedSearch, start, end],
      });
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
    onSettled: () => {
      form.reset();
      setEditSubUser({ open: false, name: null });
      setSelectedCountry("+91");
      setOTP("");
    },
  });

  const updatableFields = useMemo(
    () => (editSubUser.data ? { phone: editSubUser.data.phone } : {}),
    [editSubUser.data]
  );

  const form = useForm({
    resolver: zodResolver(addNewSubuser),
    defaultValues: {
      phone: "",
      password: "",
    },
    values: updatableFields,
  });

  const columns = [
    columnHelper.accessor("phone", {
      header: (props) => "Phone Number",
      cell: (props) => (
        <span>
          +{props.row.original.ccode} {props.getValue()}
        </span>
      ),
      enableSorting: true,
      size: 500,
    }),

    columnHelper.accessor("createdAt", {
      header: "Added Date",
      cell: (props) => format(new Date(props.getValue()), "dd MMM yyyy"),
      enableSorting: false,
      size: 150,
    }),

    columnHelper.display({
      id: "edit",
      cell: (props) => (
        <div className="flex justify-end gap-x-1">
          <div
            className="w-max p-2.5 rounded-full hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
            onClick={() => {
              setSelectedCountry("+" + props.row.original.ccode);
              setEditSubUser({ open: true, data: props.row.original });
            }}
          >
            <UserCog className="w-4 h-4 text-text" />
          </div>
          <div
            className="w-max p-2.5 rounded-full hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
            onClick={() =>
              setDeletSubuser({ open: true, data: props.row.original })
            }
          >
            <UserMinus className="w-4 h-4 text-text" />
          </div>
        </div>
      ),
      enableSorting: false,
      size: 228,
    }),
  ];

  const handleEditSubUser = () => {
    const { password, phone } = form.getValues();
    const ccode = selectedCountry.slice(1, selectedCountry.length);
    const data = {
      phone,
      password: md5(password),
      ccode,
      ...(OTP && { otp: OTP }),
      sub_user_id: editSubUser.data._id,
    };
    return editSubuser(data);
  };

  const onSubmit = (values) => {
    return handleEditSubUser();
  };

  const closeModal = (bool) => {
    setDeletSubuser({ open: bool ?? !deletSubuser.open, data: null });
  };

  const handleClose = () => {
    setEditSubUser({ open: false, data: null });
    form.reset();
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="mt-4 text-text px-4">
        <DataTable
          data={data}
          columns={columns}
          pagination
          sorting={sorting}
          setSorting={setSorting}
          enableRowSelection={false}
          enableSort={false}
        />
      </div>
      {deletSubuser.data && (
        <Dialog open={deletSubuser.open} onOpenChange={(e) => closeModal(e)}>
          <DialogContent className="flex flex-col gap-y-8">
            <h6 className="text-text text-lg">
              Do you want to delete <b>{deletSubuser.data.email}</b> this
              subuser ?
            </h6>
            <div className="flex items-center justify-end gap-x-4">
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                onClick={() => mutate({ sub_user_id: deletSubuser.data._id })}
              >
                Delete
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {editSubUser.data && (
        <Dialog open={editSubUser.open}>
          <DialogContent
            closeIcon={false}
            className="w-full max-w-[400px] bg-bg-primary"
          >
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-y-6">
                <div className="flex flex-col gap-y-4">
                  <Label className="text-text text-2xl">Edit Subuser</Label>
                  <div className="flex flex-col gap-y-2">
                    <Label className="text-text text-lg">Phone number</Label>
                    <div className="flex">
                      <Popover
                        open={isCountrySelector}
                        onOpenChange={() =>
                          seIsCountrySelector(!isCountrySelector)
                        }
                      >
                        <PopoverTrigger className="w-full max-w-[75px] px-2 flex items-center justify-between gap-x-1 bg-bg2-hover rounded-tl-md rounded-bl-md outline-none">
                          <span className="text-sm text-text">
                            {selectedCountry}
                          </span>
                          <ChevronDown className="w-4 h-4 text-text2" />
                        </PopoverTrigger>
                        <PopoverContent align="start" className="p-0">
                          <Command className="bg-bg2 text-text2">
                            <CommandInput
                              placeholder="Search country name"
                              className="placeholder:text-text2"
                            />
                            <CommandList>
                              <CommandEmpty className="py-4 text-center">
                                No country found
                              </CommandEmpty>
                              {countyCode.countries.map((country, index) => {
                                return (
                                  <CommandItem
                                    key={index}
                                    className={twMerge(
                                      "aria-selected:bg-bg2-hover text-text aria-selected:text-text cursor-pointer",
                                      selectedCountry === country.dial &&
                                      "bg-bg2-hover aria-selected:bg-bg2-hover cursor-default"
                                    )}
                                    onSelect={() =>
                                      selectedCountry === country.dial
                                        ? null
                                        : (setSelectedCountry(country.dial),
                                          seIsCountrySelector(false))
                                    }
                                  >
                                    {country.name}
                                  </CommandItem>
                                );
                              })}
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <Input
                        {...form.register("phone")}
                        type="number"
                        className={`pl-0 py-5 border-0 rounded-md border-l-0 rounded-tl-none rounded-bl-none ${form.formState.errors.phone &&
                          "text-red-400 placeholder:text-red-600 border-red-400"
                          }`}
                        autoComplete="off"
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Label className="text-text text-lg">Password</Label>
                    <Input
                      {...form.register("password")}
                      className={`py-5 border-0 rounded-md ${form.formState.errors.password &&
                        "text-red-400 placeholder:text-red-600 border-red-400"
                        }`}
                      autoComplete="off"
                      placeholder="Enter password"
                    />
                  </div>
                </div>
                <div className="flex justify-end gap-x-3">
                  <Button type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={!form.formState.isDirty}>
                    Update
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ManageSubuser;
