import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    return: {},
    dispatch: {}
}

const intialFolderSlice = createSlice({
    name: "intialFolders",
    initialState,
    reducers: {
        setReturn: (state, { payload }) => {
            state.return = payload
        },
        setDispatch: (state, { payload }) => {
            state.dispatch = payload
        },
        resetInitialFolders: () => {
            return initialState
        }
    }
})

export const { setReturn, setDispatch, resetInitialFolders } = intialFolderSlice.actions

export default intialFolderSlice.reducer

