import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { ArrowDown, ArrowUp, Edit2, QrCode, Trash2 } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  ADDUPDATEBARCODE,
  DELETEBARCODE,
  GETIGNOREDBARCODE,
} from "../../constant/endpoints";
import { privateAxios } from "../../lib/axios";
import { toastHandler } from "../../lib/helper";
import { barcode as barcodeSchema } from "../../lib/schemas";
import { setTotalPage } from "../../redux/reducers/filterSlice";

const Barcode = () => {
  const dispatch = useDispatch();
  const { _id: user_id } = useSelector((state) => state.userAuthReducer.auth);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [barcodeModal, setBarcodeModal] = useState({
    open: false,
    data: null,
    mode: null,
  });

  const { page, limit, search, start, end } = useSelector(
    (state) => state.filterReducer
  );
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [GETIGNOREDBARCODE, page, limit, start, end, search],
    queryFn: () => privateAxios(GETIGNOREDBARCODE, { user_id, start, end, search }, null, page, limit),
    onSuccess: (res) => {
      return dispatch(setTotalPage(Math.ceil((res.totalRecords ?? 1) / limit)));
    },
    initialData: {},
  });

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(ADDUPDATEBARCODE, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        refetch();
      }
      return toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => {
      barcodeForm.reset();
      setBarcodeModal({ open: false, data: null, mode: null });
    },
  });

  const deleteBarcode = useMutation({
    mutationFn: (values) => privateAxios(DELETEBARCODE, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        refetch();
      }
      return toastHandler(res);
    },
    onError: (err) => toast.error(err),
  });

  const barcodeForm = useForm({
    resolver: zodResolver(barcodeSchema),
    defaultValues: { barcode: "" },
  });

  useEffect(() => {
    if (!barcodeModal.data || barcodeModal.mode !== "EDIT") return;
    barcodeForm.reset(barcodeModal.data);

    return () => {
      barcodeForm.reset({ barcode: "" });
    };
  }, [barcodeModal.open]);

  const onSubmit = (values) => {
    if (barcodeModal.mode === "EDIT") {
      values.barcode_id = barcodeModal.data._id;
    }
    mutate(values);
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("barcode", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              Barcode
            </Badge>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <span>{props.getValue()}</span>
          </div>
        ),
      }),
      columnHelper.accessor("createdAt", {
        header: (props) => (
          <p className="py-[6px] px-4 bg-transparent text-text text-sm">
            Created On
          </p>
        ),
        cell: (props) => (
          <span className="pl-4">
            {format(new Date(props.getValue()), "dd MMM yyyy")}
          </span>
        ),
        enableSorting: true,
        size: 120,
      }),
      columnHelper.display({
        id: "edit",
        cell: (props) => (
          <div className="flex justify-end gap-x-1">
            <button
              type="button"
              className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
              onClick={() =>
                setBarcodeModal({
                  open: true,
                  mode: "EDIT",
                  data: props.row.original,
                })
              }
            >
              <Edit2 className="w-4 h-4 text-text" />
            </button>
            <button
              type="button"
              className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
              onClick={() =>
                deleteBarcode.mutate({ barcode_id: props.row.original._id })
              }
            >
              <Trash2 className="w-4 h-4 text-text" />
            </button>
          </div>
        ),
        enableSorting: false,
        size: 80,
      }),
    ],
    []
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="px-4 mt-4">
          <div className="text-end">
            <Button
              className="bg-[#0097CECC] hover:bg-bg2-hover text-white hover:text-black items-center gap-x-3"
              onClick={() =>
                setBarcodeModal({ open: true, data: null, mode: "ADD" })
              }
            >
              <span className="pt-[1px] font-semibold">Add Barcode</span>
              <QrCode className="w-4 h-4" />
            </Button>
          </div>
          <DataTable
            isFetching={isFetching}
            data={data.data ?? []}
            columns={columns}
            pagination
            sorting={sorting}
            setSorting={setSorting}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            sortableColumns={["barcode", "createdAt"]}
            enableSearch={false}
          />
        </div>
      )}

      <Dialog
        open={barcodeModal.open}
        onOpenChange={() =>
          setBarcodeModal({ open: false, data: null, mode: null })
        }
      >
        <DialogContent
          closeIcon={false}
          className="w-full max-w-[400px] bg-bg-primary"
        >
          <form
            onSubmit={barcodeForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-y-6"
          >
            <div className="flex flex-col gap-y-4">
              <Label className="text-text text-2xl">
                {barcodeModal.mode === "ADD" ? "Add" : "Update"} Barcode
              </Label>
              <Input
                {...barcodeForm.register("barcode")}
                className={`py-5 border ${barcodeForm.formState.errors.barcode &&
                  "text-red-400 placeholder:text-red-600 border-red-400"
                  }`}
                placeholder="Enter barcode"
              />
            </div>
            <div className="flex justify-end gap-x-3">
              <Button
                type="button"
                onClick={() =>
                  setBarcodeModal({ open: false, data: null, mode: null })
                }
              >
                Cancel
              </Button>
              <Button type="submit">
                {barcodeModal.mode === "ADD" ? "Add" : "Update"}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Barcode;
