import { Link } from "react-router-dom";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../components/ui/hover-card";
import { useQueryClient } from "@tanstack/react-query";
import { STORAGE } from "../../constant/endpoints";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { formatFileSize } from "../../lib/helper";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { useForm } from "react-hook-form";
import { createNewFolder } from "../../lib/schemas";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";

const Storage = () => {
  const totaleStorage = useSelector((state) => state.userAuthReducer.auth.storage);

  const [modal, setIsModal] = useState({ open: false, name: null });

  const queryClient = useQueryClient();

  const { data } = queryClient.getQueryData({ queryKey: [STORAGE] }) ?? {};
  
  useEffect(() => {
    queryClient.refetchQueries({ queryKey: [STORAGE] });
  }, []);

  const { totalDispatch, totalReturn } = useMemo(
    () => ({
      totalDispatch: data.find((values) => values._id === "Dispatch"),
      totalReturn: data.find((values) => values._id === "Return"),
    }),
    [data]
  );

  const totalUsedStorage = useMemo(
    () => Number(totalDispatch.storage) + Number(totalReturn.storage),
    [data]
  );

  const { totalDispatchPercentage, totalReturnPercentage } = useMemo(
    () => ({
      totalDispatchPercentage:
        (Number(totalDispatch.storage) * 100) / Number(totaleStorage),
      totalReturnPercentage:
        (Number(totalReturn.storage) * 100) / Number(totaleStorage),
    }), [data]);

  const folder = useForm({
    resolver: zodResolver(createNewFolder),
    defaultValues: {
      displayName: "",
    },
  });

  const onSubmit = (values) => { };

  const handleClose = () => {
    setIsModal((prevState) => ({
      ...prevState,
      open: !prevState.open,
      data: null,
    }));
    folder.reset();
  };

  return (
    <>
      <div className="px-4 mt-6">
        <p className="text-text2 text-base font-lato">
          <span className="text-4xl font-semibold font-lato">
            {formatFileSize(totalUsedStorage, 2)}
          </span>
          &nbsp; of {formatFileSize(totaleStorage, 2)} used
        </p>

        <div className="h-2 mt-4 flex">
          <HoverCard openDelay={0} closeDelay={0} className="w-max">
            <HoverCardTrigger
              style={{
                width:
                  totalDispatchPercentage <= 2
                    ? "2%"
                    : totalDispatchPercentage + "%",
              }}
              className="h-full bg-primary rounded-full hover:z-50"
            ></HoverCardTrigger>
            <HoverCardContent className="relative mt-[20px] bg-bg2 border-none">
              <div className="w-0 h-0 absolute border-l-[15px] border-l-transparent border-b-[20px] border-b-bg2 border-r-[15px] border-r-transparent -top-[20px] left-1/2 -translate-x-1/2"></div>
              <div className="text-center">
                <p className="text-text2 text-[13px] tracking-wide">Dispatch</p>
                <span className="font-lato text-3xl text-text3 my-1">
                  {formatFileSize(Number(totalDispatch.storage), 2)}
                </span>
                <p className="text-text2 text-[13px] tracking-wide">
                  used storage by Dispatch
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
          <HoverCard openDelay={0} closeDelay={0} className="w-max">
            <HoverCardTrigger
              style={{
                width:
                  totalReturnPercentage <= 2
                    ? "2%"
                    : totalReturnPercentage + "%",
              }}
              className="h-full -ml-1 rounded-full bg-red-500 hover:z-50"
            ></HoverCardTrigger>
            <HoverCardContent className="relative mt-[20px] bg-bg2 border-none">
              <div className="w-0 h-0 absolute border-l-[15px] border-l-transparent border-b-[20px] border-b-bg2 border-r-[15px] border-r-transparent -top-[20px] left-1/2 -translate-x-1/2"></div>
              <div className="text-center">
                <p className="text-text2 text-[13px] tracking-wide">Return</p>
                <span className="font-lato text-3xl text-text3 my-1">
                  {formatFileSize(Number(totalReturn.storage), 2)}
                </span>
                <p className="text-text2 text-[13px] tracking-wide">
                  used storage by Returns
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
          <HoverCard openDelay={0} closeDelay={0} className="w-max">
            <HoverCardTrigger className="w-full h-full bg-text3 -ml-1 transition-all rounded-full hover:z-50"></HoverCardTrigger>
            <HoverCardContent className="relative mt-[20px] bg-bg2 border-none">
              <div className="w-0 h-0 absolute border-l-[15px] border-l-transparent border-b-[20px] border-b-bg2 border-r-[15px] border-r-transparent -top-[20px] left-1/2 -translate-x-1/2"></div>
              <div className="text-center">
                <p className="text-text2 text-[13px] tracking-wide">
                  AVAILABLE
                </p>
                <span className="font-lato text-3xl text-text3 my-1">
                {formatFileSize(Number(totaleStorage ?? 0) - Number(totalUsedStorage ?? 0))}
                </span>
                <p className="text-text2 text-[13px] tracking-wide">
                  Available storage
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>
        <div className="mt-4 flex gap-x-6">
          <div className="flex items-center gap-x-2">
            <p className="w-2 h-2 bg-primary rounded-full"></p>
            <span className="text-text2 text-xs">Dispatch</span>
          </div>

          <div className="flex items-center gap-x-2">
            <p className="w-2 h-2 bg-red-500 rounded-full"></p>
            <span className="text-text2 text-xs">Return</span>
          </div>
        </div>
        <div className="mt-6">
          <Link
            to="/get-storage"
            className="px-4 py-2 bg-[#e5ecf9] text-primary rounded-full border-2 border-[#747775] text-lg font-lato cursor-pointer"
          >
            Get more storage
          </Link>
        </div>
      </div>
      {/* AddNewFolder  */}

      <Dialog open={modal.open} onOpenChange={handleClose}>
        <DialogContent
          closeIcon={false}
          className="w-full max-w-[400px] bg-bg-primary"
        >
          <form
            onSubmit={folder.handleSubmit(onSubmit)}
            className="flex flex-col gap-y-6"
          >
            <div className="flex flex-col gap-y-4">
              <Label className="text-text text-2xl">New folder</Label>
              <Input
                {...folder.register("displayName")}
                className={`py-5 border ${folder.formState.errors.displayName &&
                  "text-red-400 placeholder:text-red-600 border-red-400"
                  }`}
                autoComplete="off"
                placeholder="Enter folder name"
              />
            </div>
            <div className="flex justify-end gap-x-3">
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Storage;
