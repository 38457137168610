import { RotatingLines } from 'react-loader-spinner'
import { Dialog, DialogContent } from './ui/dialog'

const Loader = () => {
    return (
        <Dialog open={true}>
            <DialogContent className="max-w-max bg-transparent border-0 outline-none shadow-none">
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                />
            </DialogContent>
        </Dialog>
    )
}

export default Loader