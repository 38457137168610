const PricingModel = () => {
    return (
        <section className="py-4">
            <div className="border-b mb-4 pb-4">
                <h3 className="py-2 px-3 text-text text-center font-bold text-4xl font-lato">
                    Pricing Model: Pay-Per-Use (Transaction-Based)
                </h3>
                <h6 className="py-2 px-3 text-text2 text-center font-bold text-base font-lato">Monthly Fee: Rs. 500 (Flat fee per month for 500 credits)</h6>
            </div>
            <div className="p-4 w-full max-w-[750px] mx-auto space-y-6">
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Credit System:</h5>
                    <ul className="list-decimal marker:font-semibold list-inside space-y-1">
                        <li className="text-base leading-[25px] tracking-wide">Each credit can be purchased for Rs. 1.</li>
                        <li className="text-base leading-[25px] tracking-wide">The credits act as the currency for recording dispatch and return transactions.</li>
                    </ul>
                </div>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Transaction Costs:</h5>
                    <ul className="list-decimal marker:font-semibold list-inside space-y-1">
                        <li className="text-base leading-[25px] tracking-wide">Dispatch: 1 credit per transaction.</li>
                        <li className="text-base leading-[25px] tracking-wide">Return: 1 credit per transaction.</li>
                    </ul>
                </div>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Example Scenarios:</h5>
                    <ul className="list-decimal marker:font-semibold list-inside space-y-1">
                        <li className="text-base leading-[25px] tracking-wide">A vendor records a dispatch: 1 credit is deducted from their account.</li>
                        <li className="text-base leading-[25px] tracking-wide">A vendor records a return: 1 credits are deducted from their account.</li>
                    </ul>
                </div>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Benefits of the Model:</h5>
                    <div className="space-y-2">
                        <p className="text-[15px] leading-[20px] tracking-wide"><span className="text-lg text-text font-bold">Simple and Transparent:</span> The pricing model is easy to understand, with a clear monthly fee and a straightforward credit-based system for transactions.</p>
                    </div>
                    <div className="space-y-2">
                        <p className="text-[15px] leading-[20px] tracking-wide"><span className="text-lg text-text font-bold">Cost-Effective for All:</span> Small vendors with fewer transactions pay only for what they use, while larger vendors can scale their usage without facing prohibitive costs.</p>
                    </div>
                    <div className="space-y-2">
                        <p className="text-[15px] leading-[20px] tracking-wide"><span className="text-lg text-text font-bold">Predictable Budgeting:</span> The flat monthly fee allows vendors to budget their expenses more easily, and the credit-based system provides flexibility in using the service as needed.</p>
                    </div>
                    <div className="space-y-2">
                        <p className="text-[15px] leading-[20px] tracking-wide"><span className="text-lg text-text font-bold">Encourages Usage:</span> The low cost per credit incentivizes vendors to use the service consistently to capture video proof of their dispatches and returns.</p>
                    </div>
                    <div className="space-y-2">
                        <p className="text-[15px] leading-[20px] tracking-wide"><span className="text-lg text-text font-bold">Aligns with Value:</span> The transaction-based charging ensures that vendors pay based on their actual usage, reflecting the value they receive from the software.</p>
                    </div>
                </div>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Account Deletion Policy:</h5>
                    <ul className="list-decimal marker:font-semibold list-inside space-y-1">
                        <li className="text-base leading-[25px] tracking-wide">If the account balance goes to zero or negative and is not recharged within 30 days, the user data and account will be deleted.</li>
                        <li className="text-base leading-[25px] tracking-wide">This policy encourages users to maintain an adequate credit balance to ensure continuity of their account and access to their data.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default PricingModel