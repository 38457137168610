const data = [
    {
        id: 1,
        name: "Urvesh",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 4,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 2,
        name: "C",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 3,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 3,
        name: "A",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 1,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 4,
        name: "B",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 4,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 5,
        name: "C",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 3,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 6,
        name: "A",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 1,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 7,
        name: "B",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 4,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 8,
        name: "C",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 3,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 9,
        name: "A",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 1,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 10,
        name: "B",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 4,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 11,
        name: "C",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 3,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    },
    {
        id: 12,
        name: "A",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 1,
        isFolder: false,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: []
    }, {
        id: 1115,
        name: "Work Stuff",
        owner: "me69",
        updatedAt: "14 Aug 2023",
        fileSize: 1000,
        isFolder: true,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: [
            {
                id: 1116,
                name: "Presentation on the new marketing campaign",
                owner: "me69",
                updatedAt: "14 Aug 2023",
                fileSize: 500,
                isFolder: false,
                sharedBy: "Nikunj 1 Kmphasis",
                shareDate: "17 AUG 2023",
                items: []
            },
            {
                id: 1117,
                name: "Financial report for Q2",
                owner: "me69",
                updatedAt: "14 Aug 2023",
                fileSize: 250,
                isFolder: false,
                sharedBy: "Nikunj 1 Kmphasis",
                shareDate: "17 AUG 2023",
                items: []
            }
        ]
    },
    {
        id: 103,
        name: "Zolder",
        owner: "me69",
        updatedAt: "16 Aug 2023",
        fileSize: 2,
        isFolder: true,
        sharedBy: "Nikunj 1 Kmphasis",
        shareDate: "17 AUG 2023",
        items: [
            {
                id: 111,
                name: "Vidscan Tech sub",
                owner: "me69",
                updatedAt: "16 Aug 2023",
                fileSize: 150,
                isFolder: false,
                sharedBy: "Nikunj 1 Kmphasis",
                shareDate: "17 AUG 2023",
                items: []
            },
            {
                id: 222,
                name: "Vidscan Tech sub 103",
                owner: "me69",
                updatedAt: "16 Aug 2023",
                fileSize: 150,
                isFolder: false,
                sharedBy: "Nikunj 1 Kmphasis",
                shareDate: "17 AUG 2023",
                items: []
            },
            {
                id: 333,
                name: "Vidscan Tech sub 103",
                owner: "me69",
                updatedAt: "16 Aug 2023",
                fileSize: 150,
                isFolder: false,
                sharedBy: "Nikunj 1 Kmphasis",
                shareDate: "17 AUG 2023",
                items: []
            },
            {
                id: 444,
                name: "Vidscan Tech sub 103",
                owner: "me69",
                updatedAt: "16 Aug 2023",
                fileSize: 150,
                isFolder: true,
                sharedBy: "Nikunj 1 Kmphasis",
                shareDate: "17 AUG 2023",
                items: [{
                    id: 100,
                    name: "Vidscan Tech sub 444",
                    owner: "me69",
                    updatedAt: "16 Aug 2023",
                    fileSize: 150,
                    isFolder: false,
                    sharedBy: "Nikunj 1 Kmphasis",
                    shareDate: "17 AUG 2023",
                    items: []
                },
                {
                    id: 200,
                    name: "Vidscan Tech sub 444",
                    owner: "me69",
                    updatedAt: "16 Aug 2023",
                    fileSize: 150,
                    isFolder: false,
                    sharedBy: "Nikunj 1 Kmphasis",
                    shareDate: "17 AUG 2023",
                    items: []
                },
                {
                    id: 300,
                    name: "Vidscan Tech sub 444",
                    owner: "me69",
                    updatedAt: "16 Aug 2023",
                    fileSize: 150,
                    isFolder: true,
                    sharedBy: "Nikunj 1 Kmphasis",
                    shareDate: "17 AUG 2023",
                    items: [{
                        id: 123,
                        name: "AAA",
                        owner: "me69",
                        updatedAt: "16 Aug 2023",
                        fileSize: 150,
                        isFolder: false,
                        sharedBy: "Nikunj 1 Kmphasis",
                        shareDate: "17 AUG 2023",
                        items: []
                    },
                    {
                        id: 456,
                        name: "TTT",
                        owner: "me69",
                        updatedAt: "16 Aug 2023",
                        fileSize: 150,
                        isFolder: false,
                        sharedBy: "Nikunj 1 Kmphasis",
                        shareDate: "17 AUG 2023",
                        items: []
                    },
                    {
                        id: 789,
                        name: "YYY",
                        owner: "me69",
                        updatedAt: "16 Aug 2023",
                        fileSize: 150,
                        isFolder: false,
                        sharedBy: "Nikunj 1 Kmphasis",
                        shareDate: "17 AUG 2023",
                        items: []
                    },
                    ]
                },
                ]
            },
        ]
    },
]

const addedUsers = [
    {
        id: 1,
        name: "abc",
        email: "abc@gmail.com",
        addedDate: "25 AUG 2023"
    },
    {
        id: 2,
        name: "abc",
        email: "abc@gmail.com",
        addedDate: "25 AUG 2023"
    },
    {
        id: 3,
        name: "abc",
        email: "abc@gmail.com",
        addedDate: "25 AUG 2023"
    },
    {
        id: 4,
        name: "abc",
        email: "abc@gmail.com",
        addedDate: "25 AUG 2023"
    },
    {
        id: 5,
        name: "abc",
        email: "abc@gmail.com",
        addedDate: "25 AUG 2023"
    },
]

const adminData = [
    {
        id: 1,
        profileImage: "",
        userName: "Dishant",
        userEmail: "dishant786@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "120",
        totalReturn: "120",
        storageUsage: "10GB/100GB",
        creditUsage: "261",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 2,
        profileImage: "",
        userName: "Fenil",
        userEmail: "fenil123@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "1020",
        totalReturn: "600",
        storageUsage: "99GB/100GB",
        creditUsage: "499",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 3,
        profileImage: "",
        userName: "Kaushik",
        userEmail: "fenil444@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "1",
        totalReturn: "60",
        storageUsage: "900KB/100GB",
        creditUsage: "3",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 4,
        profileImage: "",
        userName: "Dishant",
        userEmail: "dishant786@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "120",
        totalReturn: "120",
        storageUsage: "10GB/100GB",
        creditUsage: "261",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 5,
        profileImage: "",
        userName: "Fenil",
        userEmail: "fenil123@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "1020",
        totalReturn: "600",
        storageUsage: "99GB/100GB",
        creditUsage: "499",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 6,
        profileImage: "",
        userName: "Kaushik",
        userEmail: "fenil444@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "1",
        totalReturn: "60",
        storageUsage: "900KB/100GB",
        creditUsage: "3",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 7,
        profileImage: "",
        userName: "Dishant",
        userEmail: "dishant786@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "120",
        totalReturn: "120",
        storageUsage: "10GB/100GB",
        creditUsage: "261",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 8,
        profileImage: "",
        userName: "Fenil",
        userEmail: "fenil123@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "1020",
        totalReturn: "600",
        storageUsage: "99GB/100GB",
        creditUsage: "499",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 9,
        profileImage: "",
        userName: "Kaushik",
        userEmail: "fenil444@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "1",
        totalReturn: "60",
        storageUsage: "900KB/100GB",
        creditUsage: "3",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 10,
        profileImage: "",
        userName: "Dishant",
        userEmail: "dishant786@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "120",
        totalReturn: "120",
        storageUsage: "10GB/100GB",
        creditUsage: "261",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 11,
        profileImage: "",
        userName: "Fenil",
        userEmail: "fenil123@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "1020",
        totalReturn: "600",
        storageUsage: "99GB/100GB",
        creditUsage: "499",
        creditTime: "18 Aug - 18 Dec"
    },
    {
        id: 12,
        profileImage: "",
        userName: "Kaushik",
        userEmail: "fenil444@gmail.com",
        mobileNumber: "1234567890",
        totalDispatch: "1",
        totalReturn: "60",
        storageUsage: "900KB/100GB",
        creditUsage: "3",
        creditTime: "18 Aug - 18 Dec"
    },
]

export { adminData }
export { addedUsers }
export default data