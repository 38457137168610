import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useCopyToClipboard, useDebounce } from "@uidotdev/usehooks";
import { format } from "date-fns";
import {
  ArrowDown,
  ArrowUp,
  Download,
  Edit2,
  FileImage,
  FileVideo,
  FolderIcon,
  Info,
  Link2,
  Minus,
  MoreVertical,
  Star,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import ActionDropdown from "../../components/ActionDropdown";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import DatePicker from "../../components/modals/DatePicker";
import MoveFolder from "../../components/modals/MoveFolder";
import VideoPreview from "../../components/modals/VideoPreview";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  ADDREMOVETOSTARRED,
  DOWNLOADCSV,
  GETIFOLDERDETAILS,
  RENAMEFOLDER,
} from "../../constant/endpoints";
import usePageLeave from "../../hooks/usePageLeave";
import usePaths from "../../hooks/usePaths";
import { privateAxios } from "../../lib/axios";
import {
  dowloadCSV,
  dowloadFile,
  formatFileSize,
  isImage,
  isVideo,
  toastHandler,
  trimString,
} from "../../lib/helper";
import { createNewFolder } from "../../lib/schemas";
import { resetFilter, setTotalPage } from "../../redux/reducers/filterSlice";
import {
  removeSelectedRows,
  resetRowSelection,
  setSelectedRows,
} from "../../redux/reducers/rowSlice";
import ShareCSV from "../../components/modals/ShareCSV";

const Dispatch = () => {
  const { logo } = useSelector((state) => state.userAuthReducer.auth)
  const { state } = useLocation();
  const [currentPath, setCurrentPath] = useState(useLocation());

  useEffect(() => {
    setCurrentPath(useLocation)
  }, [useLocation()])

  useEffect(() => {
    localStorage.setItem("currentPath", JSON.stringify(currentPath))
  }, [currentPath])

  const { selectedRows } = useSelector((data) => data.rowReducer);
  const dispatchParent = useSelector(
    (state) => state.intialFoldersReducer.dispatch
  );
  const { page, limit, search, start, end } = useSelector(
    (state) => state.filterReducer
  );
  const { _id: userID, email } = useSelector(
    (state) => state.userAuthReducer.auth
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [renameFolder, setRenameFolder] = useState({
    isOpen: false,
    data: null,
  });
  const [shartCsvOpen, setShareCsvOpen] = useState({
    open: false,
    data: null
  })

  const [moveFolder, setMoveFolder] = useState({ open: false, data: null });
  const [videoPlayer, setVideoPlayer] = useState({ open: true, url: null });
  const [isCSVDowloading, setIsCSVDowloading] = useState(false);

  const debouncedSearch = useDebounce(search, 300);
  usePageLeave(() => {
    dispatch(resetFilter());
    dispatch(resetRowSelection());
  });
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const { isMainuser } = usePaths();

  useEffect(() => {
    if (!search) {
      dispatch(resetRowSelection());
    }
  }, [search]);

  const queryClient = useQueryClient();

  const {
    data: dispatchData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [GETIFOLDERDETAILS, page, limit, debouncedSearch, start, end],
    queryFn: () =>
      privateAxios(
        GETIFOLDERDETAILS,
        {
          parent_folder: dispatchParent._id,
          folder_type: "Dispatch",
          start,
          end,
          search,
        },
        null,
        page,
        limit
      ),
    onSuccess: (res) => {
      return dispatch(setTotalPage(Math.ceil((res.totalRecords ?? 1) / limit)));
    },
    initialData: {},
    enabled: !!dispatchParent?._id,
  });

  const data = dispatchData?.data ?? [];

  // endcoded query perameters
  function encodeParams(folderId, token, name, profile, userID, stateData) {
    const params = { folder_id: folderId, token: token, name: name, profile: profile, user_id: userID, parentName: dispatchParent?.name, stateData: currentPath };
    const encodedParams = btoa(JSON.stringify(params));
    return encodedParams;
  }

  const handleNavigate = (item) => {
    setTimeout(() => {
      window.open(`https://web.vidscantech.com/?data=${encodeParams(item.id, localStorage.getItem('token'), item.displayName, logo, userID, JSON.parse(localStorage.getItem("currentPath")))}`)
    }, 500);
  }

  const folder = useForm({
    resolver: zodResolver(createNewFolder),
    defaultValues: { displayName: renameFolder.data?.displayName },
    values: renameFolder.data,
  });

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(RENAMEFOLDER, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        queryClient.invalidateQueries({
          queryKey: [GETIFOLDERDETAILS, page, limit, debouncedSearch],
        });
      }
      return toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => {
      folder.reset();
      setRenameFolder({ isOpen: false, data: null });
    },
  });

  const { mutate: addToStar } = useMutation({
    mutationFn: (values) => privateAxios(ADDREMOVETOSTARRED, values),
    onSuccess: (res) =>
      res.ResponseCode === "1" &&
      queryClient.invalidateQueries({
        queryKey: {
          queryKey: [GETIFOLDERDETAILS, page, limit, debouncedSearch],
        },
      }),
    onError: (err) => toast.error(err),
  });

  const { mutate: download } = useMutation({
    mutationFn: (values) => privateAxios(DOWNLOADCSV, values),
    onSuccess: async (res) => {
      setIsCSVDowloading(true);
      try {
        const message = await dowloadCSV(res.data);
        toast.success(message);
      } catch (err) {
        toast.error(err);
      } finally {
        setIsCSVDowloading(false);
        dispatch(resetRowSelection());
      }
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: (props) => (
          <div className="w-max flex">
            <Checkbox
              checked={props.table.getIsAllPageRowsSelected()}
              onCheckedChange={(e) => handleSelectAllRows(props, e)}
              aria-label="Select all"
            />
          </div>
        ),
        cell: (props) => (
          <div className="w-max flex">
            <Checkbox
              checked={props.row.getIsSelected()}
              onCheckedChange={(e) => handleSelectRow(props, e)}
              aria-label="Select row"
            />
          </div>
        ),
        enableSorting: false,
        size: 48,
      }),

      columnHelper.accessor("displayName", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              Name
            </Badge>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <div
              className="w-max h-full flex items-center justify-center hover:cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                props.row.original.isFolder === "1"
                  ? handleFolderClicked(props.row.original)
                  : handlePreview(props.row.original);
              }}
            >
              {props.row.original.isFolder === "1" ? (
                <FolderIcon className="w-4 h-4" />
              ) : isVideo(props.row.original.mimetype) ? (
                <FileVideo className="w-4 h-4" />
              ) : isImage(props.row.original.mimetype) ? (
                <FileImage className="w-4 h-4" />
              ) : (
                <Minus />
              )}
            </div>

            <span>{trimString(props.getValue())}</span>

            <div> {props.row.original.isFolder === "1" && <div
              // to={`https://web.vidscantech.com/?data=${encodeParams(props.row.original.id, localStorage.getItem('token'), props.row.original.displayName, logo, userID, props.row.original)}`} target="_blank"
              onClick={() => { handleNavigate(props.row.original) }}
            > <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5286 3.71875H12.2474L11.9818 2.92188C11.8538 2.53495 11.6069 2.19828 11.2764 1.95981C10.9459 1.72135 10.5486 1.59325 10.141 1.59375H6.85897C6.45152 1.59344 6.05435 1.72163 5.72396 1.96007C5.39357 2.19852 5.14679 2.53508 5.01872 2.92188L4.75256 3.71875H2.47137C1.95699 3.71931 1.46384 3.9239 1.10012 4.28762C0.736398 4.65134 0.531812 5.1445 0.53125 5.65888V13.4683C0.532374 13.9823 0.737209 14.4749 1.10087 14.8381C1.46453 15.2014 1.95736 15.4057 2.47137 15.4063H14.5307C15.0448 15.4051 15.5374 15.2003 15.9006 14.8366C16.2639 14.473 16.4682 13.9801 16.4688 13.4661V5.65675C16.4676 5.14274 16.2628 4.65013 15.8991 4.28687C15.5355 3.92361 15.0426 3.71931 14.5286 3.71875ZM15.4062 13.4661C15.406 13.6988 15.3134 13.9219 15.1489 14.0864C14.9844 14.2509 14.7613 14.3435 14.5286 14.3438H2.47137C2.2387 14.3435 2.01564 14.2509 1.85111 14.0864C1.68659 13.9219 1.59403 13.6988 1.59375 13.4661V5.65675C1.59459 5.42445 1.68739 5.20193 1.85186 5.03786C2.01632 4.87379 2.23907 4.78153 2.47137 4.78125H5.13559C5.24705 4.78117 5.35565 4.74603 5.44603 4.68082C5.53642 4.61561 5.604 4.52362 5.63922 4.41788L6.0265 3.25603C6.08466 3.08128 6.19638 2.92928 6.34581 2.82162C6.49524 2.71395 6.67479 2.65609 6.85897 2.65625H10.141C10.3253 2.65605 10.5049 2.71396 10.6544 2.82173C10.8038 2.92951 10.9155 3.08167 10.9735 3.25656L11.3608 4.41788C11.396 4.52362 11.4636 4.61561 11.554 4.68082C11.6444 4.74603 11.753 4.78117 11.8644 4.78125H14.5286C14.7613 4.78153 14.9844 4.87409 15.1489 5.03861C15.3134 5.20314 15.406 5.4262 15.4062 5.65888V13.4661Z" fill="#1F1F1F" />
                <path d="M8.5 5.3125C7.71197 5.3125 6.94163 5.54618 6.2864 5.98399C5.63117 6.4218 5.12049 7.04407 4.81892 7.77212C4.51735 8.50017 4.43845 9.30129 4.59219 10.0742C4.74592 10.8471 5.1254 11.557 5.68262 12.1143C6.23985 12.6715 6.9498 13.051 7.72269 13.2047C8.49558 13.3584 9.29671 13.2795 10.0248 12.978C10.7528 12.6764 11.3751 12.1657 11.8129 11.5105C12.2507 10.8552 12.4844 10.0849 12.4844 9.29687C12.4833 8.2405 12.0631 7.22771 11.3161 6.48074C10.5692 5.73377 9.55638 5.31363 8.5 5.3125ZM8.5 12.2187C7.92211 12.2187 7.35719 12.0474 6.87669 11.7263C6.39619 11.4053 6.02169 10.9489 5.80054 10.415C5.57939 9.88112 5.52153 9.29363 5.63427 8.72685C5.74701 8.16006 6.02529 7.63943 6.43392 7.2308C6.84256 6.82217 7.36318 6.54388 7.92997 6.43114C8.49676 6.3184 9.08425 6.37626 9.61815 6.59741C10.1521 6.81856 10.6084 7.19307 10.9295 7.67357C11.2505 8.15407 11.4219 8.71898 11.4219 9.29687C11.421 10.0715 11.1129 10.8142 10.5651 11.362C10.0174 11.9098 9.27467 12.2179 8.5 12.2187Z" fill="#1F1F1F" />
                <path d="M13.8125 6.90625C14.1059 6.90625 14.3438 6.6684 14.3438 6.375C14.3438 6.0816 14.1059 5.84375 13.8125 5.84375C13.5191 5.84375 13.2812 6.0816 13.2812 6.375C13.2812 6.6684 13.5191 6.90625 13.8125 6.90625Z" fill="#1F1F1F" />
              </svg>
            </div>
            }</div>
          </div>
        ),
        enableSorting: true,
        size: 1000,
      }),

      columnHelper.accessor("uploadedBy", {
        header: <span className="text-text">Uploaded By</span>,
        cell: (props) => (
          <span>
            {props.getValue()
              ? props.getValue()._id === props.row.original?.userId
                ? props.getValue().email
                : props.getValue()?.ccode && props.getValue()?.phone
                  ? `+${props.getValue().ccode} ${props.getValue().phone}`
                  : "uploaded by subuser"
              : email}
          </span>
        ),
        enableSorting: false,
        size: 320,
      }),

      columnHelper.accessor("createdAt", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              Uploaded On
            </Badge>
            <DatePicker table={props} />
          </div>
        ),
        cell: (props) => (
          <span className="pl-4">
            {format(new Date(props.getValue()), "dd MMM yyyy hh:mm a")}
          </span>
        ),
        enableSorting: true,
        size: 120,
      }),

      // columnHelper.accessor("updatedAt", {
      //   header: (props) => (
      //     <div className="flex items-center cursor-pointer">
      //       <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
      //         Last modified
      //       </Badge>
      //       <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
      //         {props.header.column.getIsSorted() === "asc" ? (
      //           <ArrowDown className="w-4 h-4" />
      //         ) : (
      //           <ArrowUp className="w-4 h-4" />
      //         )}
      //       </div>
      //     </div>
      //   ),
      //   cell: (props) => (
      //     <span className="pl-4">
      //       {format(new Date(props.getValue()), "dd MMM yyyy")}
      //     </span>
      //   ),
      //   enableSorting: true,
      //   size: 120,
      // }),

      columnHelper.accessor("size", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              File Size
            </Badge>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <span className="pl-4 flex">
            {props.getValue() === "0" ? (
              <Minus className="w-4 h-4" />
            ) : (
              formatFileSize(props.getValue(), 1)
            )}
          </span>
        ),
        enableSorting: true,
        size: 150,
      }),

      columnHelper.display({
        id: "edit",
        cell: (props) => (
          <div className="flex justify-between gap-x-1">
            <div
              className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
              onClick={() => handleCopyLink(props.row.original)}
            >
              <Link2 className="w-4 h-4" />
            </div>
            <div
              className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
              onClick={() => handleDownload(props.row.original, 0)}
            >
              <Download className="w-4 h-4 text-text" />
            </div>
            {isMainuser && (
              <div
                className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
                onClick={() =>
                  setRenameFolder((prevState) => ({
                    ...prevState,
                    isOpen: true,
                    data: props.row.original,
                  }))
                }
              >
                <Edit2 className="w-4 h-4 text-text" />
              </div>
            )}
            <div
              className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
              onClick={() => handleStar({ folder_id: props.row.original._id })}
            >
              <Star
                className={twMerge(
                  "w-4 h-4 text-text transition-all",
                  props.row.original.isStarred === "1" &&
                  "text-[#ffbc37] fill-[#ffbc37]"
                )}
              />
            </div>
            {props.table.getSelectedRowModel().rows.length <= 1 ? (
              <ActionDropdown
                data={props.row.original}
                setRenameFolder={setRenameFolder}
                handleStar={handleStar}
                setMoveFolder={setMoveFolder}
                handlePreview={handlePreview}
                handleDownload={handleDownload}
                handleCopyLink={handleCopyLink}
                setShareCsvOpen={setShareCsvOpen}
              >
                <div className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
                  <MoreVertical className="w-4 h-4 text-text" />
                </div>
              </ActionDropdown>
            ) : (
              <div className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer opacity-50">
                <MoreVertical className="w-4 h-4 text-text" />
              </div>
            )}
          </div>
        ),
        enableSorting: false,
        size: 228,
      }),
    ],
    []
  );

  const handleFolderClicked = (item) => {
    dispatch(resetRowSelection());
    item.isFolder === "1" &&
      navigate(item._id, {
        state: {
          from: [
            {
              id: item._id,
              name: item.displayName,
            },
          ],
        },
      });
  };

  const handleSelectAllRows = (props, e) => {
    dispatch(resetRowSelection());
    if (e) {
      props.table
        .getRowModel()
        .rows.forEach((row) => dispatch(setSelectedRows(row.original)));
      return props.table.toggleAllPageRowsSelected(true);
    } else {
      return props.table.toggleAllPageRowsSelected(false);
    }
  };

  const handleSelectRow = (props, e) => {
    if (e) {
      dispatch(setSelectedRows(props.row.original));
      return props.row.toggleSelected(true);
    } else {
      dispatch(removeSelectedRows(props.row.original));
      return props.row.toggleSelected(false);
    }
  };

  const handlePreview = (data) => {
    setVideoPlayer({ open: true, data });
  };

  const onSubmit = (values) => {
    mutate({ name: values.displayName, folder_id: renameFolder.data._id });
  };

  const handleStar = (values) => {
    addToStar(values);
  };

  const handleDownload = async (values, flag = 0) => {
    if (flag) {
      if (values.isFolder === "1") {
        return download({
          folder_ids: selectedRows.map(({ _id }) => _id),
        });
      } else {
        // return dowloadFile(values);
        try {
          setIsCSVDowloading(true);
          const res = await dowloadFile(values);
          return toast.success(res);
        } catch (err) {
          return toast.error(err);
        } finally {
          setIsCSVDowloading(false);
        }
      }
    } else {
      if (values.isFolder === "1") {
        return download({ folder_ids: [values._id] });
      } else {
        // return dowloadFile(values);
        try {
          setIsCSVDowloading(true);
          const res = await dowloadFile(values);
          return toast.success(res);
        } catch (err) {
          return toast.error(err);
        } finally {
          setIsCSVDowloading(false);
        }
      }
    }
  };

  const handleCopyLink = (data) => {
    const path = window.location.href;
    let p = path.split("/");
    if (data.isFolder === "0") {
      // copyToClipboard(data.path);
      copyToClipboard(
        `${window.location.origin}/preview?url=${btoa(data.path)}&type=${isImage(data.mimetype) ? "image" : "video"
        }`
      );
    } else {
      copyToClipboard(p.join("/").concat("/", data._id));
    }
    return toast.success("Link copied successfully.");
  };



  return isLoading || isCSVDowloading ? (
    <Loader />
  ) : (
    <div className="px-4 mt-4">
      <DataTable
        isFetching={isFetching}
        data={data}
        columns={columns}
        pagination
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        sortableColumns={["displayName", "size", "createdAt"]}
      />

      <div className="block md:hidden">
        <div>
          <h4 className="text-text text-sm">Folders</h4>
          <div className="grid grid-cols-1 s:grid-cols-2 m:grid-cols-3 gap-1 mt-3">
            {data.map(
              (item) =>
                item.isFolder === "1" && (
                  <div
                    key={item._id}
                    className="p-2 flex justify-between items-center gap-x-2 text-text bg-bg2"
                    onClick={() => handleFolderClicked(item)}
                  >
                    <div className="flex items-center gap-x-2">
                      <FolderIcon className="w-4 h-4" />
                      <span className="text-xs">{item.displayName}</span>
                      <div
                        // to={`https://web.vidscantech.com/?data=${encodeParams(item.id, localStorage.getItem('token'), item.displayName, logo, userID)}`} target="_blank"
                        onClick={() => { handleNavigate(item) }}
                      > <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.5286 3.71875H12.2474L11.9818 2.92188C11.8538 2.53495 11.6069 2.19828 11.2764 1.95981C10.9459 1.72135 10.5486 1.59325 10.141 1.59375H6.85897C6.45152 1.59344 6.05435 1.72163 5.72396 1.96007C5.39357 2.19852 5.14679 2.53508 5.01872 2.92188L4.75256 3.71875H2.47137C1.95699 3.71931 1.46384 3.9239 1.10012 4.28762C0.736398 4.65134 0.531812 5.1445 0.53125 5.65888V13.4683C0.532374 13.9823 0.737209 14.4749 1.10087 14.8381C1.46453 15.2014 1.95736 15.4057 2.47137 15.4063H14.5307C15.0448 15.4051 15.5374 15.2003 15.9006 14.8366C16.2639 14.473 16.4682 13.9801 16.4688 13.4661V5.65675C16.4676 5.14274 16.2628 4.65013 15.8991 4.28687C15.5355 3.92361 15.0426 3.71931 14.5286 3.71875ZM15.4062 13.4661C15.406 13.6988 15.3134 13.9219 15.1489 14.0864C14.9844 14.2509 14.7613 14.3435 14.5286 14.3438H2.47137C2.2387 14.3435 2.01564 14.2509 1.85111 14.0864C1.68659 13.9219 1.59403 13.6988 1.59375 13.4661V5.65675C1.59459 5.42445 1.68739 5.20193 1.85186 5.03786C2.01632 4.87379 2.23907 4.78153 2.47137 4.78125H5.13559C5.24705 4.78117 5.35565 4.74603 5.44603 4.68082C5.53642 4.61561 5.604 4.52362 5.63922 4.41788L6.0265 3.25603C6.08466 3.08128 6.19638 2.92928 6.34581 2.82162C6.49524 2.71395 6.67479 2.65609 6.85897 2.65625H10.141C10.3253 2.65605 10.5049 2.71396 10.6544 2.82173C10.8038 2.92951 10.9155 3.08167 10.9735 3.25656L11.3608 4.41788C11.396 4.52362 11.4636 4.61561 11.554 4.68082C11.6444 4.74603 11.753 4.78117 11.8644 4.78125H14.5286C14.7613 4.78153 14.9844 4.87409 15.1489 5.03861C15.3134 5.20314 15.406 5.4262 15.4062 5.65888V13.4661Z" fill="#1F1F1F" />
                          <path d="M8.5 5.3125C7.71197 5.3125 6.94163 5.54618 6.2864 5.98399C5.63117 6.4218 5.12049 7.04407 4.81892 7.77212C4.51735 8.50017 4.43845 9.30129 4.59219 10.0742C4.74592 10.8471 5.1254 11.557 5.68262 12.1143C6.23985 12.6715 6.9498 13.051 7.72269 13.2047C8.49558 13.3584 9.29671 13.2795 10.0248 12.978C10.7528 12.6764 11.3751 12.1657 11.8129 11.5105C12.2507 10.8552 12.4844 10.0849 12.4844 9.29687C12.4833 8.2405 12.0631 7.22771 11.3161 6.48074C10.5692 5.73377 9.55638 5.31363 8.5 5.3125ZM8.5 12.2187C7.92211 12.2187 7.35719 12.0474 6.87669 11.7263C6.39619 11.4053 6.02169 10.9489 5.80054 10.415C5.57939 9.88112 5.52153 9.29363 5.63427 8.72685C5.74701 8.16006 6.02529 7.63943 6.43392 7.2308C6.84256 6.82217 7.36318 6.54388 7.92997 6.43114C8.49676 6.3184 9.08425 6.37626 9.61815 6.59741C10.1521 6.81856 10.6084 7.19307 10.9295 7.67357C11.2505 8.15407 11.4219 8.71898 11.4219 9.29687C11.421 10.0715 11.1129 10.8142 10.5651 11.362C10.0174 11.9098 9.27467 12.2179 8.5 12.2187Z" fill="#1F1F1F" />
                          <path d="M13.8125 6.90625C14.1059 6.90625 14.3438 6.6684 14.3438 6.375C14.3438 6.0816 14.1059 5.84375 13.8125 5.84375C13.5191 5.84375 13.2812 6.0816 13.2812 6.375C13.2812 6.6684 13.5191 6.90625 13.8125 6.90625Z" fill="#1F1F1F" />
                        </svg>
                      </div>
                    </div>
                    <Info className="w-4 h-4" />
                  </div>
                )
            )}
          </div>
        </div>

        <div className="mt-5">
          <h4 className="text-text text-sm">Files</h4>
          <div className="grid grid-cols-1 s:grid-cols-2 m:grid-cols-3 gap-1 mt-3">
            {data.map(
              (item) =>
                item.isFolder !== "1" && (
                  <div
                    key={item._id}
                    className="flex flex-col justify-between items-center gap-x-2 text-text bg-bg2 overflow-hidden"
                  >
                    <div className="relative overflow-hidden rounded-xl"></div>
                    <div className="w-full p-2 flex justify-between items-center gap-x-2 text-text">
                      <div className="w-full flex items-center gap-x-2">
                        <FileVideo className="w-4 h-4" />
                        <span className="text-xs">{item.displayName}</span>
                      </div>
                      <Info className="w-5 h-5 cursor-pointer" />
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>

      {/* renameFolder  */}

      {renameFolder?.data && (
        <Dialog
          open={renameFolder.isOpen}
          onOpenChange={() => {
            setRenameFolder((prevState) => ({
              ...prevState,
              isOpen: !prevState.isOpen,
            }));
            return folder.reset();
          }}
        >
          <DialogContent
            closeIcon={false}
            className="w-full max-w-[400px] bg-bg-primary"
          >
            <form
              onSubmit={folder.handleSubmit(onSubmit)}
              className="flex flex-col gap-y-6"
            >
              <div className="flex flex-col gap-y-4">
                <Label className="text-text text-2xl">Rename folder</Label>
                <Input
                  defaultValues={renameFolder.data?.data?.displayName}
                  {...folder.register("displayName")}
                  className={`py-5 border ${folder.formState.errors.displayName &&
                    "text-red-400 placeholder:text-red-600 border-red-400"
                    }`}
                  placeholder="Enter folder name"
                />
              </div>
              <div className="flex justify-end gap-x-3">
                <Button
                  type="button"
                  onClick={() => {
                    setRenameFolder((prevState) => ({
                      ...prevState,
                      isOpen: false,
                    }));
                    return folder.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit">OK</Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}

      {/* MoveFolder Modal */}
      {moveFolder.data && (
        <MoveFolder modal={moveFolder} setModal={setMoveFolder} />
      )}

      {/* share csv file  */}
      {
        shartCsvOpen?.data && <ShareCSV modal={shartCsvOpen} setModal={setShareCsvOpen} />
      }

      {/* VideoPlayer Modal */}
      {videoPlayer.data && (
        <VideoPreview
          open={videoPlayer.open}
          setOpen={setVideoPlayer}
          data={videoPlayer.data}
        />
      )}
    </div>
  );
};

export default Dispatch;
