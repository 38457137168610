import React from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog'
import { Label } from '../ui/label'
import { Button } from '../ui/button'
import { useSelector } from 'react-redux'

const CreditModal = ({ modal, setModal }) => {
    const { credits } = useSelector(
        (state) => state.userAuthReducer.auth
    );
    
    return (
        <>
            <Dialog open={modal.open} onOpenChange={(open) => setModal({ open, data: null })}>
                <DialogHeader>
                    <DialogTitle>

                    </DialogTitle>
                </DialogHeader>
                <DialogContent className="max-w-[400px]">
                    <Label className="text-text text-2xl">
                        Credit Details
                    </Label>
                    <p className='text-base font-medium'>Your available credit is <span className='font-semibold'>{credits} </span> </p>
                    <button className='text-sm font-medium text-white rounded-md bg-[#0097CE] py-2' onClick={() => { setModal({ open: false, data: null }) }}>Ok</button>
                    {/* <Button onClick={() => { setModal({ open: false, data: null }) }}>Ok</Button> */}
                </DialogContent>
                {/* <DialogFooter>
                   
                </DialogFooter> */}
            </Dialog>
        </>
    )
}

export default CreditModal