const SecureTransactions = () => {
  return (
    <section className="py-4">
      <div className="border-b mb-4 pb-4">
        <h3 className="py-2 px-3 text-text text-center font-bold text-4xl font-lato">
          Vidscan Tech: Empowering Secure E-commerce Transactions
        </h3>
      </div>
      <div className="p-4 w-full max-w-[750px] mx-auto space-y-6">
        <p className="text-base leading-[30px] tracking-wide">
          Vidscan Tech is a cutting-edge software service dedicated to revolutionizing the e-commerce landscape. We provide a robust solution that enables vendors to record video-proof of their dispatches and returns with ease. Our video-verified dispatches offer tamper-proof evidence of each shipment, showcasing product packing and condition, while fraud-proof returns help distinguish genuine claims from fraudulent ones.
        </p>
        <p className="text-base leading-[30px] tracking-wide">With a user-friendly interface and seamless integration, Vidscan Tech ensures a transparent and trustworthy platform for both vendors and customers. Our pay-per-use model allows vendors of all sizes to access our innovative features at nominal costs, boosting customer trust, minimizing disputes, and enhancing overall operational efficiency. Join us in creating a marketplace built on authenticity and reliability with Vidscan Tech.</p>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Overview of Vidscan Tech Software Service</h5>
          <p className="text-base leading-[30px] tracking-wide">Vidscan Tech provides a stand-alone software service that enables vendors to record video proof of product dispatches and returns. The process is simple and efficient, requiring vendors to push a record button to start recording the packing process and then use a barcode scanner to associate the shipment number with the video.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Benefits for Vendors</h5>
          <p className="text-base leading-[30px] tracking-wide">By using Vidscan Tech's software, vendors can protect themselves from false claims of incorrect dispatches and fraud returns. The video recordings, along with date and time stamps, and the Vidscan logo, ensure the integrity and authenticity of the proof, making it tamper-proof.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Cost-Effective Implementation</h5>
          <p className="text-base leading-[30px] tracking-wide">Implementing the Vidscan Tech software is straightforward and comes at a nominal cost, making it accessible to vendors of all sizes.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">User-Friendly Interface</h5>
          <p className="text-base leading-[30px] tracking-wide">The software's user-friendly interface allows the existing dispatch team to learn and use it effectively within just five minutes, saving time and resources.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Easy Sharing</h5>
          <p className="text-base leading-[30px] tracking-wide">Vendors can easily share the video proof with relevant parties by copying a URL, streamlining communication and dispute resolution processes.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Multiple Devices For Upload</h5>
          <p className="text-base leading-[30px] tracking-wide">The ability to use multiple devices for upload also implies that your software is adaptable and compatible with various platforms and devices, making it highly flexible for vendors who prefer different hardware options. This further enhances the user experience and allows large vendors to seamlessly integrate the software into their existing dispatch processes without any disruptions.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Centralized Dashboard</h5>
          <p className="text-base leading-[30px] tracking-wide">Vendors can access and manage their video proof records through a centralized dashboard on the web, providing convenient access to their data. Centralizing all video proof records from multiple devices in one account streamlines data management and simplifies reporting and analytics. This consolidated approach enables vendors to have a comprehensive overview of their dispatch and return processes, identify patterns, and make data-driven decisions to optimize their operations.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Customizable Branding</h5>
          <p className="text-base leading-[30px] tracking-wide">Allow vendors to customize the application's branding with their logo and colors, reinforcing their identity while sharing video proof with customers.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Branding Configuration Section</h5>
          <p className="text-base leading-[30px] tracking-wide">Include a specific section within the Vidscan Tech dashboard where premium vendors can easily configure their branding preferences.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Upload Custom Logo</h5>
          <p className="text-base leading-[30px] tracking-wide">Allow premium vendors to upload their logo directly from their computer or provide a link to their logo hosted on their website. This logo will be displayed in the application's interface.</p>
          <p className="text-base leading-[30px] tracking-wide">By offering premium branding customization, Vidscan Tech provides a tailored experience to premium vendors, enabling them to reinforce their brand identity and maintain a consistent look and feel across their interactions with customers. This feature enhances the perception of the application's flexibility and adaptability, catering to the diverse branding needs of businesses using the software.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Geolocation Tagging</h5>
          <p className="text-base leading-[30px] tracking-wide">Add geolocation tagging to the recorded videos, providing additional context and verifying the physical location of dispatch and return activities.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Offline Mode</h5>
          <p className="text-base leading-[30px] tracking-wide">Enable users to record video proof even when they are offline, with data automatically synced once an internet connection is available.</p>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Offline Recording and Auto-Sync Feature</h5>
          <div class="space-y-2">
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Offline Recording:</span> Users can initiate the video recording process even when there is no internet connection. This feature ensures that they can continue capturing video proof without interruptions, regardless of their location or network availability.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Local Storage:</span> When the user records a video while offline, the video file and associated metadata (such as timestamp, shipment details, etc.) are temporarily stored locally on the device.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Syncing Mechanism:</span> The Vidscan Tech application will have a built-in auto-syncing mechanism that periodically checks for an active internet connection.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Auto-Sync Priority:</span> Once an internet connection is detected, the application automatically prioritizes and initiates the syncing process for recorded videos.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Data Upload:</span> The locally stored video files and metadata are uploaded to the central server when the internet connection is established, ensuring that all video proof records are securely stored.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Sync Status Indicators:</span> Users are provided with clear indicators on the status of their videos, distinguishing between synced and pending videos. This helps users track which videos are yet to be uploaded to the server.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Offline Queue Management:</span> The application efficiently manages the queue of pending videos, ensuring that videos are synced in the order they were recorded and that no data is lost during the process.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Offline Recording Limitations:</span> Depending on the device's storage capacity, there may be a limit to the number of videos that can be recorded while offline. Users may receive notifications when the local storage is close to capacity.</p>
            <p className="text-base leading-[30px] tracking-wide">
              <p className="mt-6 text-base leading-[26px] font-medium tracking-wide">
                Vidscan Tech is a cutting-edge software service dedicated to revolutionizing the e-commerce landscape. We provide a robust solution that enables vendors to record video-proof of their dispatches and returns with ease. Our video-verified dispatches offer tamper-proof evidence of each shipment, showcasing product packing and condition, while fraud-proof returns help distinguish genuine claims from fraudulent ones.
              </p>
            </p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Advanced Search and Filters:</span> Implement robust search and filtering options in the centralized dashboard, making it easy for vendors to find specific video proof records quickly.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">User Roles and Permissions:</span> Introduce role-based access control, allowing vendors to define different access levels for their team members based on their responsibilities.</p>
            <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Analytics and Insights:</span> Offer detailed analytics and insights into dispatch and return trends, enabling vendors to make data-driven decisions to improve their processes.</p>
          </div>
        </div>
        <div className="space-y-6">
          <div className="space-y-2">
            <h5 className="text-2xl font-semibold tracking-wider">Choose Your Video Quality: Control Your Data, Customize Your Experience</h5>
            <p className="text-base leading-[30px] tracking-wide"><span className="font-medium">Option to set the quality</span> of videos they upload is a valuable feature that allows for flexibility and customization based on their specific needs and data constraints. Here are some benefits and considerations for providing this option:</p>
          </div>
          <div className="space-y-2">
            <p className="text-base leading-[30px] tracking-wide font-bold">Benefits:</p>
            <div class="space-y-2">
              <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">User Control:</span> Allowing users to choose the video quality empowers them to manage their data usage and storage preferences. Users with limited data plans or storage space may opt for lower quality to conserve resources.</p>
              <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Bandwidth Efficiency:</span> Lower-quality videos consume less bandwidth during upload, making it faster and more efficient for users, especially in areas with limited internet connectivity.</p>
              <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Storage Space Management:</span> High-quality videos tend to take up more space on the device or cloud storage. Giving users the option to select lower quality helps them manage storage requirements.</p>
              <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Reduced Costs:</span> For users who are charged based on data usage, selecting a lower quality can lead to cost savings.</p>
              <p class="text-[15px] leading-[20px] tracking-wide"><span class="text-lg text-text font-bold">Customization for Specific Needs:</span> Some users may have specific use cases where lower-quality videos are sufficient, while others may require higher quality for more detailed documentation.</p>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Data Security and Privacy</h5>
          <p className="text-base leading-[30px] tracking-wide">We understand the importance of protecting your data. Vidscan Tech utilizes robust security measures, encryption protocols, and compliance standards to safeguard your information and ensure your peace of mind.</p>
        </div>
      </div>
    </section>

  )
}

export default SecureTransactions