import { RWebShare } from "react-web-share"
import { cn } from '../components/utils'

const ShareBtn = ({ children, url, title, text, className, disabled = false }) => {

    const data = {
        url: "http://localhost:3000/vidscan-tech",
        title: "Vidscan Tech",
        text: "Vidscan Tech",
        ...(url && { url }),
        ...(title && { title }),
        ...(text && { text }),
    }

    return (
        <RWebShare data={data} disableNative={true}>
            <button id="share-btn" className={cn("w-full flex items-center gap-x-5 disabled:cursor-not-allowed z-50", className)} disabled={disabled}>
                {children}
            </button>
        </RWebShare>
    )
}

export default ShareBtn