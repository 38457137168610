import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { forgotPasswordSchema } from '../../lib/schemas'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { privateAxios } from '../../lib/axios'
import { FORGOTPASSWORD } from '../../constant/endpoints'
import { toastHandler } from '../../lib/helper'
import Logo from '../../assets/images/logoWithSlogan.jpg'


const ForgotPassword = () => {
    const navigate = useNavigate()

    const form = useForm({
        resolver: zodResolver(forgotPasswordSchema),
        defaultValues: {
            email: "",
        }
    })

    const { mutate } = useMutation({
        mutationFn: (values) => privateAxios(FORGOTPASSWORD, values),
        onSuccess: (res) => {
            if (res.ResponseCode === "1" || res.ResponseCode === "2") {
                form.reset()
                navigate("/login")
            }
            return toastHandler(res)
        }
    })

    const onSubmit = (values) => {
        mutate(values)
    }

    return (
        <div className='w-full max-w-sm h-screen mx-auto flex flex-col justify-center'>

            <div className='space-y-10'>
                <div className='space-y-2'>
                    <div className='max-w-[350px] mx-auto mb-6'>
                        <img src={Logo} alt="logo" />
                    </div>
                    <h1 className='text-primary text-2xl font-lato font-semibold'>Forgot password?</h1>
                    <p className='text-text2'>No worries, we'll send you reset instructions.</p>
                </div>

                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="w-full h-max space-y-8"
                    >
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-text text-base font-lato">
                                        Email
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="Enter your email"
                                            onChange={({ target: { value } }) => form.setValue("email", value.toLowerCase())}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <Button className="w-full">Send Email</Button>

                    </form>
                </Form>

            </div>
        </div>
    )
}

export default ForgotPassword