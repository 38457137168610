import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PROFILE } from "../constant/endpoints";
import { privateAxios } from "../lib/axios";
import {
  setIsAuthenticated,
  setUserAuth,
} from "../redux/reducers/userAuthSlice";
import { toastHandler } from "../lib/helper";
import Loader from "../components/Loader";

const PrivateRouter = ({ children }) => {
  const isAuthenticated = useSelector(
    (state) => state.userAuthReducer.isAuthenticated
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let shouldCallProfile = false;

  if (
    localStorage.getItem("token") &&
    isAuthenticated &&
    localStorage.getItem("token") === null
  ) {
    shouldCallProfile = false;
  } else {
    shouldCallProfile = true;
  }

  const { isLoading } = useQuery({
    queryKey: [PROFILE],
    queryFn: () => privateAxios(PROFILE, null, "GET"),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        dispatch(setIsAuthenticated(true));
        dispatch(setUserAuth(res.data));
      } else {
        return navigate("login");
      }
    },
    onError: (res) => {
      return navigate("login");
    },
    enabled: shouldCallProfile && !pathname.includes("/admin"),
    staleTime: Infinity,
  });

  return isLoading ? (
    <Loader />
  ) : isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRouter;
