import { ChevronRight, FolderIcon } from "lucide-react";

const Folder = ({
  folders,
  handleFolderClicked,
  moveAbleFolder,
  moveFolder,
}) => {
  return folders.map((values) => {
    return (
      values._id !== moveAbleFolder._id &&
      values.isFolder === "1" && (
        <div
          key={values._id}
          className="w-full max-w-[98%] pl-4 rounded-r-full flex justify-between items-center text-text hover:bg-bg2-hover transition-all"
        >
          <div className="flex items-center gap-x-3">
            <FolderIcon
              className="w-4 h-4 cursor-pointer"
              onClick={() => handleFolderClicked(values)}
            />
            <span>{values.displayName}</span>
          </div>
          <div className="flex items-center gap-x-4">
            <button
              className="text-primary font-medium cursor-pointer disabled:opacity-50"
              onClick={() =>
                moveFolder({
                  folder_ids: Array.isArray(moveAbleFolder)
                    ? moveAbleFolder.map(({ _id }) => _id)
                    : [moveAbleFolder._id],
                  new_parent_folder: values._id,
                })
              }
            >
              Move
            </button>
            <span
              className="p-1.5 hover:bg-bg2 rounded-full transition-all cursor-pointer"
              onClick={() => handleFolderClicked(values)}
            >
              <ChevronRight className="w-5 h-5" />
            </span>
          </div>
        </div>
      )
    );
  });
};

export default Folder;
