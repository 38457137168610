import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useCopyToClipboard, useDebounce } from "@uidotdev/usehooks";
import { format } from "date-fns";
import {
  ArrowDown,
  ArrowUp,
  Download,
  Edit2,
  FileImage,
  FileVideo,
  FolderIcon,
  Info,
  Link2,
  Minus,
  MoreVertical,
  Star,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import DATA from "../../Data";
import ActionDropdown from "../../components/ActionDropdown";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import MoveFolder from "../../components/modals/MoveFolder";
import VideoPreview from "../../components/modals/VideoPreview";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  ADDREMOVETOSTARRED,
  DOWNLOADCSV,
  GETSTARREDLIST,
  RENAMEFOLDER,
} from "../../constant/endpoints";
import usePageLeave from "../../hooks/usePageLeave";
import usePaths from "../../hooks/usePaths";
import { privateAxios } from "../../lib/axios";
import {
  dowloadCSV,
  dowloadFile,
  formatFileSize,
  isImage,
  isVideo,
  toastHandler,
  trimString,
} from "../../lib/helper";
import { createNewFolder } from "../../lib/schemas";
import { resetFilter, setTotalPage } from "../../redux/reducers/filterSlice";
import {
  removeSelectedRows,
  resetRowSelection,
  setSelectedRows,
} from "../../redux/reducers/rowSlice";

const Starred = () => {
  const { selectedRows } = useSelector((data) => data.rowReducer);
  const { page, limit, search, start, end } = useSelector(
    (state) => state.filterReducer
  );
  const { _id: userID, email } = useSelector(
    (state) => state.userAuthReducer.auth
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [renameFolder, setRenameFolder] = useState({
    isOpen: false,
    data: null,
  });
  const [moveFolder, setMoveFolder] = useState({ open: false, data: null });
  const [videoPlayer, setVideoPlayer] = useState({ open: true, url: null });
  const [isCSVDowloading, setIsCSVDowloading] = useState(false);

  const debouncedSearch = useDebounce(search, 300);
  usePageLeave(() => {
    dispatch(resetFilter());
    dispatch(resetRowSelection());
  });
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const { isMainuser } = usePaths();

  useEffect(() => {
    if (!search) {
      dispatch(resetRowSelection());
    }
  }, [search]);

  const queryClient = useQueryClient();

  const {
    data: starredData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [GETSTARREDLIST, page, limit, debouncedSearch, start, end],
    queryFn: () =>
      privateAxios(GETSTARREDLIST, { start, end, search }, null, page, limit),
    onSuccess: (res) => {
      return dispatch(setTotalPage(Math.ceil((res.totalRecords ?? 1) / limit)));
    },
    initialData: [],
  });

  const data = starredData?.data ?? [];

  const folder = useForm({
    resolver: zodResolver(createNewFolder),
    defaultValues: { displayName: renameFolder.data?.displayName },
    values: renameFolder.data,
  });

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(RENAMEFOLDER, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        queryClient.invalidateQueries({
          queryKey: [GETSTARREDLIST, page, limit, debouncedSearch],
        });
      }
      return toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => {
      folder.reset();
      setRenameFolder({ isOpen: false, data: null });
    },
  });

  const { mutate: addToStar } = useMutation({
    mutationFn: (values) => privateAxios(ADDREMOVETOSTARRED, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        return queryClient.invalidateQueries({
          queryKey: {
            queryKey: [GETSTARREDLIST, page, limit, debouncedSearch],
          },
        });
      }
    },
    onError: (err) => toast.error(err),
  });

  const columnHelper = createColumnHelper();

  const { mutate: download } = useMutation({
    mutationFn: (values) => privateAxios(DOWNLOADCSV, values),
    onSuccess: async (res) => {
      setIsCSVDowloading(true);
      try {
        const message = await dowloadCSV(res.data);
        toast.success(message);
      } catch (err) {
        toast.error(err);
      } finally {
        setIsCSVDowloading(false);
        dispatch(resetRowSelection());
      }
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: (props) => (
          <div className="flex">
            <Checkbox
              checked={props.table.getIsAllPageRowsSelected()}
              onCheckedChange={(e) => handleSelectAllRows(props, e)}
              aria-label="Select all"
            />
          </div>
        ),
        cell: (props) => (
          <div className="flex">
            <Checkbox
              checked={props.row.getIsSelected()}
              onCheckedChange={(e) => handleSelectRow(props, e)}
              aria-label="Select row"
            />
          </div>
        ),
        enableSorting: false,
        size: 48,
      }),

      columnHelper.accessor("displayName", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              Name
            </Badge>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <div
              className="w-max h-full flex items-center justify-center hover:cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                props.row.original.isFolder === "1"
                  ? handleFolderClicked(props.row.original)
                  : handlePreview(props.row.original);
              }}
            >
              {props.row.original.isFolder === "1" ? (
                <FolderIcon className="w-4 h-4" />
              ) : isVideo(props.row.original.mimetype) ? (
                <FileVideo className="w-4 h-4" />
              ) : isImage(props.row.original.mimetype) ? (
                <FileImage className="w-4 h-4" />
              ) : (
                <Minus />
              )}
            </div>
            <span>{trimString(props.getValue())}</span>
          </div>
        ),
        enableSorting: true,
        size: 1000,
      }),

      columnHelper.accessor("uploadedBy", {
        header: <span className="text-text">Uploaded By</span>,
        cell: (props) => (
          <span>
            {props.getValue()
              ? props.getValue()._id === userID
                ? email
                : props.getValue()?.ccode && props.getValue()?.phone
                ? `+${props.getValue().ccode} ${props.getValue().phone}`
                : "uploaded by subuser"
              : email}
          </span>
        ),
        enableSorting: false,
        size: 320,
      }),

      columnHelper.accessor("createdAt", {
        header: <span className="text-text">Uploaded By</span>,
        cell: (props) => format(new Date(props.getValue()), "dd MMM yyyy"),
        enableSorting: false,
        size: 120,
      }),

      // columnHelper.accessor("updatedAt", {
      //   header: "Last modified",
      //   cell: (props) => format(new Date(props.getValue()), "dd MMM yyyy"),
      //   enableSorting: false,
      //   size: 120,
      // }),

      columnHelper.accessor("size", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              File Size
            </Badge>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <span className="pl-4 flex">
            {props.getValue() === "0" ? (
              <Minus className="w-4 h-4" />
            ) : (
              formatFileSize(props.getValue(), 1)
            )}
          </span>
        ),
        enableSorting: true,
        size: 150,
      }),

      columnHelper.display({
        id: "edit",
        cell: (props) => (
          <div className="flex justify-between gap-x-1">
            <div
              className={`w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all invisible group-hover:visible ${
                props.row.original.isFolder === "0"
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={() =>
                props.row.original.isFolder === "1"
                  ? handleCopyLink(props.row.original)
                  : null
              }
            >
              <Link2 className="w-4 h-4" />
            </div>
            <div
              className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
              onClick={() => handleDownload(props.row.original, 1)}
            >
              <Download className="w-4 h-4 text-text" />
            </div>
            {isMainuser && (
              <div
                className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
                onClick={() =>
                  setRenameFolder((prevState) => ({
                    ...prevState,
                    isOpen: true,
                    data: props.row.original,
                  }))
                }
              >
                <Edit2 className="w-4 h-4 text-text" />
              </div>
            )}
            <div
              className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer invisible group-hover:visible"
              onClick={() => handleStar({ folder_id: props.row.original._id })}
            >
              <Star
                className={twMerge(
                  "w-4 h-4 text-text transition-all",
                  props.row.original.isStarred === "1" &&
                    "text-[#ffbc37] fill-[#ffbc37]"
                )}
              />
            </div>
            {props.table.getSelectedRowModel().rows.length <= 1 ? (
              <ActionDropdown
                data={props.row.original}
                setRenameFolder={setRenameFolder}
                handleStar={handleStar}
                setMoveFolder={setMoveFolder}
                handlePreview={handlePreview}
                handleDownload={handleDownload}
                handleCopyLink={handleCopyLink}
              >
                <div className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
                  <MoreVertical className="w-4 h-4 text-text" />
                </div>
              </ActionDropdown>
            ) : (
              <div className="w-max p-2.5 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer opacity-50">
                <MoreVertical className="w-4 h-4 text-text" />
              </div>
            )}
          </div>
        ),
        enableSorting: false,
        size: 228,
      }),
    ],
    []
  );

  const handleFolderClicked = (item) => {
    dispatch(resetRowSelection());
    item.isFolder &&
      navigate(`folders/${item.id}`, {
        state: {
          from: [
            {
              id: item.id,
              name: item.name,
            },
          ],
        },
      });
  };

  const handleSelectAllRows = (props, e) => {
    dispatch(resetRowSelection());
    if (e) {
      props.table
        .getRowModel()
        .rows.forEach((row) => dispatch(setSelectedRows(row.original)));
      return props.table.toggleAllPageRowsSelected(true);
    } else {
      return props.table.toggleAllPageRowsSelected(false);
    }
  };

  const handleSelectRow = (props, e) => {
    if (e) {
      dispatch(setSelectedRows(props.row.original));
      return props.row.toggleSelected(true);
    } else {
      dispatch(removeSelectedRows(props.row.original));
      return props.row.toggleSelected(false);
    }
  };

  const handlePreview = (data) => {
    setVideoPlayer({ open: true, data });
  };

  const onSubmit = (values) => {
    mutate({ name: values.displayName, folder_id: renameFolder.data._id });
  };

  const handleStar = (values) => {
    addToStar(values);
  };

  const handleDownload = async (values, flag = 0) => {
    if (flag) {
      if (values.isFolder === "1") {
        return download({
          folder_ids: selectedRows.map(({ _id }) => _id),
        });
      } else {
        try {
          setIsCSVDowloading(true);
          const res = await dowloadFile(values);
          return toast.success(res);
        } catch (err) {
          return toast.error(err);
        } finally {
          setIsCSVDowloading(false);
        }
      }
    } else {
      if (values.isFolder === "1") {
        return download({ folder_ids: [values._id] });
      } else {
        try {
          setIsCSVDowloading(true);
          const res = await dowloadFile(values);
          return toast.success(res);
        } catch (err) {
          return toast.error(err);
        } finally {
          setIsCSVDowloading(false);
        }
      }
    }
  };

  const handleCopyLink = (data) => {
    const path = window.location.href;
    let p = path.split("/");
    if (data.isFolder === "0") {
      // copyToClipboard(data.path);
      copyToClipboard(
        `${window.location.origin}/preview?url=${btoa(data.path)}&type=${
          isImage(data.mimetype) ? "image" : "video"
        }`
      );
    } else {
      if (id) {
        p.pop();
        copyToClipboard(p.join("/").concat("/", data._id));
      } else {
        copyToClipboard(p.join("/").concat("/", data._id));
      }
    }
    return toast.success("Link copied successfully.");
  };

  return isLoading || isCSVDowloading ? (
    <Loader />
  ) : (
    <div className="px-4 mt-4">
      <DataTable
        isFetching={isFetching}
        data={data}
        columns={columns}
        pagination
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        filterableColumns={["displayName"]}
        sortableColumns={["displayName", "size"]}
      />

      <div className="block md:hidden">
        <div>
          <h4 className="text-text text-sm">Folders</h4>
          <div className="grid grid-cols-1 s:grid-cols-2 m:grid-cols-3 gap-1 mt-3">
            {DATA.map(
              (item) =>
                item.isFolder && (
                  <div
                    key={item.id}
                    className="p-2 flex justify-between items-center gap-x-2 text-text bg-bg2"
                    onClick={() => handleFolderClicked(item)}
                  >
                    <div className="flex items-center gap-x-2">
                      <FolderIcon className="w-4 h-4" />
                      <span className="text-xs">{item.name}</span>
                    </div>
                    <Info className="w-4 h-4" />
                  </div>
                )
            )}
          </div>
        </div>

        <div className="mt-5">
          <h4 className="text-text text-sm">Files</h4>
          <div className="grid grid-cols-1 s:grid-cols-2 m:grid-cols-3 gap-1 mt-3">
            {DATA.map(
              (item) =>
                !item.isFolder && (
                  <div
                    key={item.id}
                    className="flex flex-col justify-between items-center gap-x-2 text-text bg-bg2 overflow-hidden"
                  >
                    <div className="relative overflow-hidden rounded-xl"></div>
                    <div className="w-full p-2 flex justify-between items-center gap-x-2 text-text">
                      <div className="w-full flex items-center gap-x-2">
                        <FileVideo className="w-4 h-4" />
                        <span className="text-xs">{item.name}</span>
                      </div>
                      <Info className="w-5 h-5 cursor-pointer" />
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>

      {/* renameFolder  */}

      {renameFolder?.data && (
        <Dialog
          open={renameFolder.isOpen}
          onOpenChange={() =>
            setRenameFolder((prevState) => ({
              ...prevState,
              isOpen: !prevState.isOpen,
            }))
          }
        >
          <DialogContent
            closeIcon={false}
            className="w-full max-w-[400px] bg-bg-primary"
          >
            <form
              onSubmit={folder.handleSubmit(onSubmit)}
              className="flex flex-col gap-y-6"
            >
              <div className="flex flex-col gap-y-4">
                <Label className="text-text text-2xl">Rename folder</Label>
                <Input
                  defaultValues={renameFolder.data?.data?.displayName}
                  {...folder.register("displayName")}
                  className={`py-5 border ${
                    folder.formState.errors.displayName &&
                    "text-red-400 placeholder:text-red-600 border-red-400"
                  }`}
                  placeholder="Enter folder name"
                />
              </div>
              <div className="flex justify-end gap-x-3">
                <Button
                  type="button"
                  onClick={() =>
                    setRenameFolder((prevState) => ({
                      ...prevState,
                      isOpen: false,
                    }))
                  }
                >
                  Cancel
                </Button>
                <Button type="submit">OK</Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}

      {/* MoveFolder Modal */}
      {moveFolder.data && (
        <MoveFolder modal={moveFolder} setModal={setMoveFolder} />
      )}

      {/* VideoPlayer Modal */}
      {videoPlayer.data && (
        <VideoPreview
          open={videoPlayer.open}
          setOpen={setVideoPlayer}
          data={videoPlayer.data}
        />
      )}
    </div>
  );
};

export default Starred;
