import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginSchema } from "../../lib/schemas";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logoWithSlogan.jpg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import isEmail from "validator/lib/isEmail";
import { privateAxios, publibAxios } from "../../lib/axios";
import { GETINITIALFOLDER, LOGIN, PROFILE } from "../../constant/endpoints";
import { toastHandler } from "../../lib/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAuthenticated,
  setUserAuth,
} from "../../redux/reducers/userAuthSlice";
import md5 from "md5";
import Loader from "../../components/Loader";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state) => state.userAuthReducer.isAuthenticated
  );
  const queryClient = useQueryClient();

  const form = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate, isLoading: isLogingIn } = useMutation({
    mutationFn: (values) => publibAxios(LOGIN, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        localStorage.setItem("token", res.data.token);
        dispatch(setIsAuthenticated(true));
        dispatch(setUserAuth(res.data));
        navigate("/dispatch");
        queryClient.invalidateQueries({ queryKey: [GETINITIALFOLDER] });
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const { isLoading } = useQuery({
    queryKey: [PROFILE],
    queryFn: () => privateAxios(PROFILE, null, "GET"),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        dispatch(setIsAuthenticated(true));
        dispatch(setUserAuth(res.data));
      }
    },
    staleTime: Infinity,
  });

  const onSubmit = (values) => {
    const { email, password } = values;

    const body = {
      password: md5(password),
      // password: password,
      [isEmail(email) ? "email" : "phone"]: email,
    };
    return mutate(body);
  };

  return isAuthenticated ? (
    <Navigate to="/dispatch" />
  ) : isLoading || isLogingIn ? (
    <Loader />
  ) : (
    <div className="w-full max-w-sm h-screen mx-auto flex flex-col justify-center">
      <div className="space-y-10">
        <div className="text-center space-y-6">
          <div className="max-w-[350px] mx-auto">
            <img src={Logo} alt="logo" />
          </div>
          <h1 className="text-primary text-2xl font-lato font-semibold">
            Sign in to Vidscan Tech
          </h1>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full h-max space-y-8"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Mobile number or email
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Enter your mobile number or email"
                      onChange={({ target: { value } }) =>
                        form.setValue("email", value.toLowerCase())
                      }
                    />
                  </FormControl>
                  <FormDescription className="text-[13px] text-text">
                    Please enter a prefix (e.g. for India 91XXXXX)
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Password
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter your password"
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full font-lato font-bold text-lg bg-primary"
            >
              Sign In
            </Button>

            <div className="space-y-1 text-center">
              <p className="text-center text-text2 text-sm">
                Don’t have an account?&nbsp;
                <Link to="/signup" className="text-primary font-semibold">
                  Sign Up
                </Link>
              </p>
              <Link
                to="/forgot-password"
                className="text-center  text-sm text-primary font-semibold"
              >
                Forgot Password?
              </Link>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};
export default Login;
