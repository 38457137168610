import { Link, useLocation } from "react-router-dom";
import { cn } from '../components/utils'
import Logo from "../assets/images/logo.jpg";

const Header = () => {
  const { pathname } = useLocation()
  return (
    <header className="sticky top-0 bg-bg-primary shadow-md">
      <div className="w-full max-w-[1440px] mx-auto px-6 py-4 flex justify-between items-center">
        <Link to="/">
          <div className="w-full max-w-[140px] l:max-w-[180px]">
            <img src={Logo} alt="logo" />
          </div>
        </Link>
        <nav className="w-full max-w-[560px] l:max-w-[620px] lg:max-w-[720px] flex justify-between items-center">
          <ul className="flex items-center gap-x-2 l:gap-x-5">
            <li className="flex">
              <Link to="pricing-model" className={cn("text-base lg:text-lg font-lato font-medium transition-colors",
                pathname === "/pricing-model" ? "text-primary hover:text-primary" : "text-text hover:text-primary")}>
                Pricing Model
              </Link>
            </li>
            <li className="flex">
              <Link to="secure-transactions" className={cn("text-base lg:text-lg font-lato font-medium transition-colors",
                pathname === "/secure-transactions" ? "text-primary hover:text-primary" : "text-text hover:text-primary")}>
                Secure Transactions
              </Link>
            </li>
            <li className="flex">
              <Link to="success-stories" className={cn("text-base lg:text-lg font-lato font-medium transition-colors",
                pathname === "/success-stories" ? "text-primary hover:text-primary" : "text-text hover:text-primary")}>
                Success Stories
              </Link>
            </li>
            <li className="flex">
              <Link to="about-us" className={cn("text-base lg:text-lg font-lato font-medium transition-colors",
                pathname === "/about-us" ? "text-primary hover:text-primary" : "text-text hover:text-primary")}>
                About Us
              </Link>
            </li>
          </ul>

          <div className="flex items-center justify-between gap-x-4">
            <Link to="signup" className="text-base lg:text-lg font-lato font-medium tracking-wider hover:text-primary transition-colors">
              Signup
            </Link>
            <Link to="login" className="text-base lg:text-lg font-lato font-medium tracking-wider hover:text-primary transition-colors">
              Login
            </Link>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header