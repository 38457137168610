import { Children, Fragment, cloneElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEnd, setStart } from "../../redux/reducers/filterSlice";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { toast } from "react-toastify";
import { ArrowDown } from "lucide-react";
import { cn } from "../utils";
import { addDays, formatISO } from "date-fns";

const DatePicker = ({ table }) => {
  const [isCaleder, setIsCaleder] = useState(false);
  const startDate = useSelector((state) => state.filterReducer.start);
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsCaleder(false);
  };

  return (
    <Popover open={isCaleder} onOpenChange={handleClose}>
      <PopoverTrigger>
        <div
          className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setIsCaleder(true);
          }}
        >
          <ArrowDown
            className={cn(
              "w-4 h-4 transition-all",
              table.header.column.getIsSorted() === "asc"
                ? "rotate-0"
                : "-rotate-180"
            )}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={new Date(startDate)}
          onSelect={(date) => {
            if (date) {
              dispatch(setStart(formatISO(date)));
              dispatch(setEnd(formatISO(date)));
              handleClose();
            } else {
              toast.error("Please try to select different date");
            }
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;
