import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
axios.defaults.headers.key = process.env.REACT_APP_API_KEY

const publibAxios = async (url, body = null, method = "POST") => {
    try {
        const { data } = await axios({ url, method, ...(body !== null && { data: body }) })
        return data
    } catch (err) {
        const { response: { data } } = err
        return data
    }
}

const privateAxios = async (URL, BODY, METHOD, PAGE, LIMIT) => {
    const body = BODY || null
    const method = METHOD || "POST"
    const page = PAGE?.toString() || null
    const limit = LIMIT?.toString() || null

    try {
        const { data } = await axios({
            url: URL,
            method,
            ...((body !== null)
                ? (page !== null && limit !== null)
                    ? { data: { ...body, page, limit } }
                    : { data: body }
                : {}),
            headers: {
                token: localStorage.getItem("token")
            }
        })
        if (data.ResponseCode === "9") {
            toast("Your account has been deleted")
            localStorage.clear()
            return window.location.href = window.location.origin + "/"
        }
        return data
    } catch (err) {
        const { response: { data } } = err
        return data
    }
}

const privateAdminAxios = async (URL, BODY, METHOD, PAGE, LIMIT) => {
    const body = BODY || null
    const method = METHOD || "POST"
    const page = PAGE?.toString() || null
    const limit = LIMIT?.toString() || null

    try {
        const { data } = await axios({
            url: URL,
            method,
            ...((body !== null)
                ? (page !== null && limit !== null)
                    ? { data: { ...body, page, limit } }
                    : { data: body }
                : {}),
            headers: {
                token: localStorage.getItem("adminToken")
            }
        })
        return data
    } catch (err) {
        const { response: { data } } = err
        return data
    }
}

export { publibAxios, privateAxios, privateAdminAxios }