import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AdminPrivateRouter = () => {
  const isAuthenticated = useSelector(
    (state) => state.adminAuthReducer.isAuthenticated
  );

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/admin/login" />
  );
};

export default AdminPrivateRouter;
