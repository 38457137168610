import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import PrivateRouter from "./auth/PrivateRouter";
import AdminPrivateRouter from "./auth/AdminPrivateRouter";
import Layout from "./pages/user/Layout";
import Login from "./pages/user/Login";
import Signup from "./pages/user/Signup";
import Return from "./pages/user/Return";
import Dispatch from "./pages/user/Dispatch";
import Folder from "./pages/user/Folder";
import Storage from "./pages/user/Storage";
import GetStorage from "./pages/user/GetStorage";
import Starred from "./pages/user/Starred";
import Trash from "./pages/user/Trash";
import PrivacyPolicy from "./pages/user/PrivacyPolicy";
import Help from "./pages/user/Help";
import Analytics from "./pages/user/Analytics";
import ManageSubuser from "./pages/user/ManageSubuser";
import TermsandConditions from "./pages/user/TermsandConditions";
import ForgotPassword from "./pages/user/ForgotPassword";
import Profile from "./pages/user/Profile";
import ChangePassword from "./pages/user/ChangePassword";
import ChangeAdminPassword from "./pages/admin/ChangePassword";
import AdminLayout from "./pages/admin/Layout";
import AdminLogin from "./pages/admin/Login";
import Dashboard from "./pages/admin/Dashboard";
import Pricing from "./pages/admin/Pricing";
import AdminProfile from "./pages/admin/Profile";
import Notification from "./pages/admin/Notification";
import History from "./pages/user/History";
import UserHistory from "./pages/admin/History";
import AboutUs from "./pages/user/AboutUs";
import EmailConfirmation from "./pages/user/EmailConfirmation";
import ResetPassword from "./pages/user/ResetPassword";
import LandingLayout from "./pages/Layout";
import Home from "./pages/Home";
import PricingModel from "./pages/PricingModel";
import SecureTransactions from "./pages/SecureTransactions";
import SuccessStories from "./pages/SuccessStories";
import AboutVidscan from "./pages/Aboutus";
import AboutUsMobile from "./pages/AboutUsMobile";
import TermsandConditionsMobile from "./pages/TermsandConditionsMobile";
import PrivacyPolicyMobile from "./pages/PrivacyPolicyMobile";
import ErrorBoundary from "./ErrorBoundary";
import Plans from "./pages/admin/Plans";
import VideoPreview from "./pages/VideoPreview";
import GetCredits from "./pages/user/GetCredits";
import Barcode from "./pages/user/Barcode";

const App = () => {
  const router = createBrowserRouter(
    [
      {
        element: <Layout />,
        children: [
          {
            element: <LandingLayout />,
            children: [
              {
                index: true,
                element: <Home />,
              },
              {
                path: "pricing-model",
                element: <PricingModel />,
              },
              {
                path: "secure-transactions",
                element: <SecureTransactions />,
              },
              {
                path: "success-stories",
                element: <SuccessStories />,
              },
            ],
          },
          {
            path: "dispatch",
            children: [
              {
                index: true,
                element: (
                  <PrivateRouter>
                    <Dispatch />
                  </PrivateRouter>
                ),
              },
              {
                path: ":id",
                element: (
                  <PrivateRouter>
                    <Folder />
                  </PrivateRouter>
                ),
              },
            ],
          },
          {
            path: "return",
            children: [
              {
                index: true,
                element: (
                  <PrivateRouter>
                    <Return />
                  </PrivateRouter>
                ),
              },
              {
                path: ":id",
                element: (
                  <PrivateRouter>
                    <Folder />
                  </PrivateRouter>
                ),
              },
            ],
          },
          {
            path: "barcode",
            element: (
              <PrivateRouter>
                <Barcode />
              </PrivateRouter>
            ),
          },
          {
            path: "starred",
            children: [
              {
                index: true,
                element: (
                  <PrivateRouter>
                    <Starred />
                  </PrivateRouter>
                ),
              },
              {
                path: ":id",
                element: (
                  <PrivateRouter>
                    <Folder />
                  </PrivateRouter>
                ),
              },
            ],
          },
          {
            path: "trash",
            children: [
              {
                index: true,
                element: (
                  <PrivateRouter>
                    <Trash />
                  </PrivateRouter>
                ),
              },
              {
                path: ":id",
                element: (
                  <PrivateRouter>
                    <Folder />
                  </PrivateRouter>
                ),
              },
            ],
          },
          {
            path: "history",
            element: (
              <PrivateRouter>
                <History />
              </PrivateRouter>
            ),
          },
          {
            path: "storage",
            element: (
              <PrivateRouter>
                <Storage />
              </PrivateRouter>
            ),
          },
          {
            path: "get-storage",
            element: (
              <PrivateRouter>
                <GetStorage />
              </PrivateRouter>
            ),
          },
          {
            path: "get-credits",
            element: (
              <PrivateRouter>
                <GetCredits />
              </PrivateRouter>
            ),
          },
          {
            path: "manage-subusers",
            element: (
              <PrivateRouter>
                <ManageSubuser />
              </PrivateRouter>
            ),
          },
          {
            path: "analytics",
            element: (
              <PrivateRouter>
                <Analytics />
              </PrivateRouter>
            ),
          },
          {
            path: "profile",
            element: (
              <PrivateRouter>
                <Profile />
              </PrivateRouter>
            ),
          },
          {
            path: "change-password",
            element: (
              <PrivateRouter>
                <ChangePassword />
              </PrivateRouter>
            ),
          },
          {
            path: "privacy-policy",
            element: <PrivacyPolicy />,
          },
          {
            path: "aboutus",
            element: <AboutVidscan />,
          },
          {
            path: "terms-and-conditions",
            element: <TermsandConditions />,
          },
          {
            path: "help",
            element: <Help />,
          },
          {
            path: "about-us",
            element: <AboutUs />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "signup",
            element: <Signup />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "admin",
            element: <AdminLayout />,
            children: [
              {
                element: <AdminPrivateRouter />,
                children: [
                  {
                    index: true,
                    element: <Dashboard />,
                  },
                  {
                    path: "pricing",
                    element: <Pricing />,
                  },
                  {
                    path: "plans",
                    element: <Plans />,
                  },
                  {
                    path: "notification",
                    element: <Notification />,
                  },
                  {
                    path: "profile",
                    element: <AdminProfile />,
                  },
                  {
                    path: "change-password",
                    element: <ChangeAdminPassword />,
                  },
                  {
                    path: "history",
                    children: [
                      {
                        path: ":userId",
                        element: <UserHistory />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "login",
                element: <AdminLogin />,
              },
            ],
          },
        ],
      },
      {
        path: "/contact-us",
        element: <Help />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms",
        element: <TermsandConditions />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/email-confirmation/:token",
        element: <EmailConfirmation />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "/privacy-mobile",
        element: <PrivacyPolicyMobile />,
      },
      {
        path: "/terms-mobile",
        element: <TermsandConditionsMobile />,
      },
      {
        path: "/about-mobile",
        element: <AboutUsMobile />,
      },
      {
        path: "/preview",
        element: <VideoPreview />,
      },
    ],
    {
      basename: process.env.REACT_APP_BASE_NAME,
    }
  );

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
