import { ChevronDown } from "lucide-react"
import { useState } from "react"
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover"
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from "../../components/ui/command"
import { Checkbox } from "../../components/ui/checkbox"
import { Label } from "../../components/ui/label"
import { Button } from "../../components/ui/button"
import { Textarea } from "../../components/ui/textarea"
import { Fragment } from "react"
import { useEffect } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { GETALLUSER, SENDNOTIFICATIONS } from "../../constant/endpoints"
import { privateAdminAxios } from "../../lib/axios"
import { toast } from "react-toastify"


const Notification = () => {
    const [isUserSelector, setIsUserSelector] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [isAllUserSelected, setIsAllUserSelected] = useState(false)
    const [message, setMessage] = useState("")

    const { data = [], isFetching } = useQuery({
        queryKey: [GETALLUSER],
        queryFn: () => privateAdminAxios(GETALLUSER, null, "GET"),
        select: res => res.data
    })

    const { mutate } = useMutation({
        mutationFn: () => {
            const values = { message, user_ids: selectedUsers }
            return privateAdminAxios(SENDNOTIFICATIONS, values)
        },
        onSettled: (res) => {
            if (res.ResponseCode === "1") {
                toast.success(res.ResponseMsg)
                setSelectedUsers([])
                setMessage("")
            } else {
                return toast.error(res.ResponseMsg)
            }
        }
    })

    useEffect(() => {
        if (!isFetching) {
            selectedUsers.length === data.length ? setIsAllUserSelected(true) : setIsAllUserSelected(false)
        }
    }, [selectedUsers])

    const handleSelecteUser = (e, text) => {
        e
            ? setSelectedUsers(prevState => [...prevState, text])
            : setSelectedUsers(prevState => prevState.filter(user => user !== text))
    }

    return (
        <div className="w-full max-w-[600px] flex flex-col px-4 mt-6">
            <Popover open={isUserSelector} onOpenChange={() => setIsUserSelector(!isUserSelector)}>
                <PopoverTrigger className='w-full max-w-[300px] py-3 px-4 flex items-center justify-between gap-x-1 bg-bg2 rounded-lg outline-none'>
                    <span className='text-base text-text2'>Select users</span>
                    <ChevronDown className='w-5 h-5 text-text2' />
                </PopoverTrigger>
                <PopoverContent align="start" className="w-[300px] p-0">
                    <Command className="bg-bg2 text-text2">
                        <CommandInput placeholder="Search user name" className="placeholder:text-text2" />
                        <CommandList>
                            <CommandEmpty className="py-4 text-center">No user found</CommandEmpty>
                            <CommandItem key={isAllUserSelected + "checkBoxContainer"} className="px-3 aria-selected:text-text aria-selected:bg-bg2-hover space-x-3">
                                <Checkbox defaultChecked={isAllUserSelected} id="selectAll" className="w-4 h-4" onCheckedChange={e => e ? setSelectedUsers(data.map(({ _id }) => _id)) : setSelectedUsers([])} />
                                <Label htmlFor="selectAll" className="w-full py-3 cursor-pointer">Select all</Label>
                            </CommandItem>
                            <Fragment key={isAllUserSelected + "usersCheckBoxContainer"}>
                                {data.map((u, i) => {
                                    return <CommandItem key={u._id + i} className="px-3 aria-selected:text-text aria-selected:bg-bg2-hover space-x-3">
                                        <Checkbox id={u._id} defaultChecked={selectedUsers.includes(u._id)} className="w-4 h-4" onCheckedChange={e => handleSelecteUser(e, u._id)} />
                                        <Label htmlFor={u._id} className="w-full py-3 cursor-pointer">{u.email}</Label>
                                    </CommandItem>
                                })}
                            </Fragment>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
            <div className="mt-4">
                <h6 className=" mb-2 text-text">Message</h6>
                <Textarea value={message} onChange={({ target: { value } }) => setMessage(value)} placeholder="Enter message" className="bg-bg2 placeholder:text-text text-text !outline-none border-none !focus-visible:outline-none" />
            </div>
            <Button onClick={mutate} className="w-full max-w-[300px] mx-auto bg-primary mt-6">Send</Button>
        </div>
    )
}

export default Notification