import { Link } from "react-router-dom";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Check, Tag } from "lucide-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PROFILE, UPDATEPLAN, USERPLAN } from "../../constant/endpoints";
import { privateAxios } from "../../lib/axios";
import Loader from "../../components/Loader";
import { formatFileSize, toastHandler } from "../../lib/helper";
import { useSelector } from "react-redux";
import { cn } from "../../components/utils";

const GetStorage = () => {
  const queryClient = useQueryClient();
  const { plan_id } = useSelector((state) => state.userAuthReducer.auth);

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [USERPLAN],
    queryFn: () => privateAxios(USERPLAN, null, "GET"),
    select: (res) => res.data,
  });

  const { mutate, isLoading: isUpdatingPlan } = useMutation({
    mutationFn: (values) => privateAxios(UPDATEPLAN, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        queryClient.invalidateQueries({ queryKey: [PROFILE] });
        refetch();
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  return (
    <section className="flex flex-col items-center justify-center">
      <div className="space-y-5">
        <h2 className="text-text2 text-3xl font-lato text-center leading-10 tracking-wide [word-spacing:3px]">
          Choose the Vidscan Tech plan that's right for you
          <br />
          More space, extra benefits
        </h2>
        <p className="text-text text-sm text-center font-light">
          All Vidscan Tech Accounts give you 200 GB of storage. By upgrading to
          a Vidscan Tech plan, you agree to the Vidscan Tech{" "}
          <Link
            to="/terms-and-conditions"
            className="text-primary underline font-medium"
          >
            Terms of Service
          </Link>
          .
          <br />
          Note: The Vidscan Tech{" "}
          <Link
            to="/privacy-policy"
            className="text-primary underline font-medium"
          >
            Privacy Policy
          </Link>{" "}
          describes how data is handled in this service.
        </p>
      </div>

      <Tabs defaultValue="monthly" className="w-full mt-8">
        <TabsList className="w-full h-max py-0 bg-transparent">
          <TabsTrigger
            value="monthly"
            className="w-full h-[58px] max-w-[250px] flex flex-col font-lato font-semibold text-lg border border-white data-[state=active]:bg-primary data-[state=active]:text-text3 data-[state=inactive]:text-text data-[state=inactive]:border-r-0"
          >
            <span>Introductory Pricing</span>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="monthly" className="data-[state=inactive]:mt-0">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="px-4 max-w-5xl mx-auto mt-6 grid grid-cols-3 gap-x-5">
              {data.map((p, index) => {
                return (
                  <div
                    key={p.plan_id}
                    className={cn(
                      "px-2 py-4 border-2 border-transparent rounded-lg flex flex-col items-center shadow-[0_1px_6px_rgba(86,89,96)]",
                      p._id === plan_id
                        ? "border-primary"
                        : "border-transparent"
                    )}
                  >
                    <Badge className="w-max bg-[#40704e] text-[#e6f4ea] gap-x-1.5 text-base font-normal hover:bg-[#40704e]">
                      <Tag className="w-4 h-4" />
                      {p.plan_name}
                    </Badge>
                    <div className="mt-4 space-y-1 text-center">
                      <h6 className="text-text2 text-3xl font-lato">
                        {formatFileSize(p.storage)}
                      </h6>
                    </div>
                    <div className="mt-6 space-y-1 text-center">
                      <h6 className="text-text2 text-lg text-center">
                        {p.credits} Credit per Month
                      </h6>
                    </div>

                    <div className="mt-5 text-center space-y-3">
                      {data[2]._id === plan_id ? (
                        <Button className="px-8 bg-transparent font-lato font-semibold text-base border opacity-50 hover:bg-transparent cursor-default text-text">
                          Current plan
                        </Button>
                      ) : data[1]._id === plan_id ? (
                        index === 2 ? (
                          <Button
                            className="px-8 border border-transparent bg-primary font-lato font-semibold text-base"
                            onClick={() => mutate({ plan_id: p._id })}
                            disabled={isUpdatingPlan}
                          >
                            UPGRADE
                          </Button>
                        ) : (
                          <Button className="px-8 bg-transparent font-lato font-semibold text-base border opacity-50 hover:bg-transparent cursor-default text-text">
                            Current plan
                          </Button>
                        )
                      ) : index === 0 ? (
                        <Button className="px-8 bg-transparent font-lato font-semibold text-base border opacity-50 hover:bg-transparent cursor-default text-text">
                          Current plan
                        </Button>
                      ) : (
                        <Button
                          className="px-8 border border-transparent bg-primary font-lato font-semibold text-base"
                          onClick={() => mutate({ plan_id: p._id })}
                          disabled={isUpdatingPlan}
                        >
                          UPGRADE
                        </Button>
                      )}
                    </div>
                    <hr className="w-full border-white mt-6 mb-3" />
                    <div className="w-full px-2">
                      <p className="text-text2 text-sm font-lato">
                        Vidscan Tech includes
                      </p>
                      <ul className="mt-3 space-y-3">
                        <li className="flex items-center gap-x-4 text-sm text-text2">
                          <Check className="w-4 h-4 text-primary" />
                          <span>Share with up to {p.share_upto} others</span>
                        </li>
                        {p.description.length &&
                          p.description.map((d, i) => {
                            return (
                              <li
                                key={p.plan_id + i}
                                className="flex items-center gap-x-4 text-sm text-text2"
                              >
                                <Check className="w-4 h-4 text-primary" />
                                <span>{d}</span>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-6 flex flex-col items-center">
            <strong>
              Upgrades will only be charged at the begining of the following
              month.
            </strong>
            <strong>
              Upgrade benifits will be instantly applied to your account.
            </strong>
          </div>
        </TabsContent>
      </Tabs>
    </section>
  );
};

export default GetStorage;
