import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 2,
            cacheTime: 1000 * 60 * 1,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    }
})

root.render(
    <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <App />
    </QueryClientProvider>
);

reportWebVitals();
