import * as React from "react"

import { cn } from "../utils"

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    (<input
      type={type}
      className={cn("flex h-10 w-full rounded-md border border-bg2 bg-bg2-hover px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium text-text placeholder:text-text focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
        className)}
      ref={ref}
      {...props} />)
  );
})
Input.displayName = "Input"

export { Input }
