import { Button } from "../../components/ui/button"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import Logo from "../../assets/images/logo.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../lib/schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "../../components/utils";
import { RESETPASSWORD } from "../../constant/endpoints";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import md5 from "md5";

const ResetPassword = () => {
    const { token } = useParams()
    const navigate = useNavigate()

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        resolver: zodResolver(resetPassword),
        defaultValues: {
            new_password: "",
            confirm_new_password: ""
        },
    });

    const { mutate, isLoading } = useMutation({
        mutationFn: (values) => axios({ method: "POST", url: RESETPASSWORD, data: values }),
        onSettled: (res) => {
            if (res.data.ResponseCode === "1") {
                reset()
                return navigate("/")
            } else {
                return reset()
            }
        }
    })

    const onSubmit = (values) => {
        const { new_password } = values
        return mutate({ new_password: md5(new_password), token })
    }

    return (
        <section className="w-screen h-screen flex flex-col gap-y-6 justify-center items-center">
            <div className="max-w-[280px]">
                <img src={Logo} alt="logo" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-xl flex flex-col space-y-6">
                <div className="space-y-3">
                    <Label className="text-lg font-lato font-semibold">Password</Label>
                    <div>
                        <Input {...register("new_password")} placeholder="Enter new password" className={cn("h-12 text-base placeholder:text-base placeholder-text2 font-lato font-semibold placeholder:font-medium",
                            errors.new_password ? "bg-red-200 text-red-600 placeholder:text-red-600" : "text-text bg-bg2-hover")} />
                        <p className="text-sm text-red-600">{errors.new_password?.message}</p>
                    </div>
                </div>
                <div className="space-y-3">
                    <Label className="text-lg font-lato font-semibold">Confirm Password</Label>
                    <div>
                        <Input {...register("confirm_new_password")} placeholder="Enter new password" className={cn("h-12 text-base placeholder:text-base placeholder-text2 font-lato font-semibold placeholder:font-medium",
                            errors.confirm_new_password ? "bg-red-200 text-red-600 placeholder:text-red-600" : "text-text bg-bg2-hover")} />
                        <p className="text-sm text-red-600">{errors.confirm_new_password?.message}</p>
                    </div>
                </div>
                <Button type="submit" className="text-lg font-lato font-semibold h-12" disabled={isLoading}>Reset Password</Button>
            </form>
        </section>
    )
}

export default ResetPassword