import { useLocation } from "react-router-dom"

const TermsandConditionsMobile = () => {
    const { pathname } = useLocation()

    const data = [{
        heading: "Intellectual Property:",
        points: ["All content, trademarks, logos, graphics, and software on the Website are the intellectual property of Vidscan Tech and are protected by applicable intellectual property laws. Unauthorized use of any content is strictly prohibited."]
    }, {
        heading: "Use of the Website:",
        points: ["The Website is provided solely for informational purposes and to promote Vidscan Tech's software service. You may not use the Website for any unlawful or unauthorized purposes."]
    }, {
        heading: "Privacy Policy:",
        points: ["Your use of the Website is subject to Vidscan Tech's Privacy Policy, which can be found [link to the Privacy Policy]. Please review the Privacy Policy to understand how we collect, use, and protect your personal information."]
    }, {
        heading: "Account Registration:",
        points: ["To access certain features or services on the Website, you may need to create a Vidscan Tech account. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account."]
    }, {
        heading: "Pricing and Payments:",
        points: ["The pricing and payment details for Vidscan Tech's software service are subject to change without prior notice. Please review the pricing information on the Website for the most current rates."]
    }, {
        heading: "Limitation of Liability:",
        points: ["Vidscan Tech shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Website or any services provided therein."]
    }, {
        heading: "Third-Party Links:",
        points: ["The Website may contain links to third-party websites or services. Vidscan Tech does not endorse or have control over the content, policies, or practices of these third-party websites. We are not responsible for any damages or losses resulting from your use of third-party websites."]
    }, {
        heading: "Account Deletion:",
        points: ["If your Vidscan Tech account goes to zero or negative credits and is not recharged within 30 days, your account and all associated data will be permanently deleted."]
    }, {
        heading: "Indemnification:",
        points: ["You agree to indemnify and hold Vidscan Tech, its officers, directors, employees, and affiliates harmless from any claims, losses, damages, liabilities, or expenses (including attorney fees) arising out of your use of the Website or violation of these Terms and Conditions."]
    }, {
        heading: "Modification of Terms:",
        points: ["Vidscan Tech reserves the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website after changes are made constitutes your acceptance of the revised Terms and Conditions."]
    }, {
        heading: "Governing Law:",
        points: ["These Terms and Conditions shall be governed by and construed in accordance with the laws of [your country or state]."]
    }, {
        heading: "Limitation of Liability for Data Deletion:",
        points: ["Vidscan Tech shall only be liable up to the amount charged for the video proof service by Vidscan Inc in the previous month to the vendor in the event of accidental data deletion. In no event shall Vidscan Tech be liable for any consequential, incidental, indirect, or special damages arising from the deletion of user data."]
    },
    ]
    return (
        <section className="py-4 bg-black text-white">
            {!pathname.includes("/terms-and-conditions")
                ? <h3 className="py-2 px-3 text-center font-bold text-4xl font-heebo">
                    Terms and Conditions
                </h3>
                : null}
            <div className="p-4 w-full max-w-[750px] mx-auto space-y-6">
                <p className="text-base leading-[30px] tracking-wide font-heebo">By accessing and using the Vidscan Tech website ("the Website"), you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using the Website.</p>
                {data.map((values, i) => {
                    return <div key={i} className="space-y-2">
                        <h5 className="text-2xl font-semibold tracking-wider font-heebo">{i + 1}. {values.heading}</h5>
                        <ul className="pl-[23px] list-disc marker:font-semibold space-y-4 font-heebo">
                            {values.points.map((val, j) => {
                                return <li key={j} className="text-base leading-[25px] tracking-wide font-heebo">{val}</li>
                            })}
                        </ul>
                    </div>
                })}
                <p className="text-base leading-[30px] tracking-wide font-heebo">By using the Vidscan Tech website and its services, you acknowledge and accept the limitation of liability outlined in these Terms and Conditions.</p>
                <p className="text-base leading-[30px] tracking-wide font-heebo">If you have any questions or concerns about these Terms and Conditions, please contact us at.</p>
                <address className="flex flex-col gap-y-1 text-lg font-medium leading-[25px] tracking-wider font-heebo">
                    <p className="font-heebo">Vidscan Tech</p>
                    <p className="font-heebo">E-1102 Suncity Heights, Gurgaon, Haryana.</p>
                    <a className="font-heebo text-primary" href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=Naman@vidscantech.com">Naman@vidscantech.com</a>
                </address>
            </div>
        </section>
    )
}

export default TermsandConditionsMobile