import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";

const VideoPreview = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const videoPlyerRef = useRef();

  const source = {
    type: "video",
    title: "Sample - Code-128_video",
    sources: atob(searchParams.get("url")),
  };

  return (
    <section className="preview-video h-dvh w-dvw flex justify-center items-center bg-black">
      {searchParams.get("type") === "image" ? (
        <img
          className="w-full h-[90%] object-contain"
          src={atob(searchParams.get("url"))}
          alt="preview"
        />
      ) : (
        <Plyr ref={videoPlyerRef} source={source} />
      )}
    </section>
  );
};

export default VideoPreview;
