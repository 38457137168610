import { useQueryClient } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GETIFOLDERDETAILS } from "../constant/endpoints";

const usePaths = () => {
  const auth = useSelector((state) => state.userAuthReducer.auth);
  const { page, limit, search, start, end } = useSelector(
    (state) => state.filterReducer
  );
  const { state, pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const debouncedSearch = useDebounce(search, 300);
  const queryClient = useQueryClient();
  const [title, setTitle] = useState({ id: "", name: "" });

  const isMainuser = useMemo(
    () => (auth.parentUser && auth.parentUser.trim() !== "" ? false : true),
    [auth?.parentUser]
  );

  const mainUser = {
    "/return": {
      title: "My Return",
      isAddDropdown: true,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/dispatch": {
      title: "My Dispatch",
      isAddDropdown: true,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/manage-subusers": {
      title: "Manage Subusers",
      isAddDropdown: true,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/starred": {
      title: "Starred",
      isAddDropdown: false,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/barcode": {
      title: "Barcode",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/trash": {
      title: "Trash",
      isAddDropdown: false,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/history": {
      title: "History",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/storage": {
      title: "Storage",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/analytics": {
      title: "Analytics",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/privacy-policy": {
      title: "Privacy Policy - Vidscan Tech",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/terms-and-conditions": {
      title: "Terms and Conditions",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/help": {
      title: "Help",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/about-us": {
      title: "About us",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/aboutus": {
      title: "About Us: Vidscan Tech",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/profile": {
      title: "Profile",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/change-password": {
      title: "Change Password",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/pricing": {
      title: "Pricing",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/notification": {
      title: "Notification",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin": {
      title: "Dashboard",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin/pricing": {
      title: "Pricing",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin/notification": {
      title: "Notification",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin/plans": {
      title: "Membership",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin/history/": {
      title: "History",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: true,
      isPlainText: false,
    },
  };

  const subuser = {
    "/return": {
      title: "My Return",
      isAddDropdown: false,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/dispatch": {
      title: "My Dispatch",
      isAddDropdown: false,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/manage-subusers": {
      title: "Manage Subusers",
      isAddDropdown: false,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/starred": {
      title: "Starred",
      isAddDropdown: false,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/barcode": {
      title: "Barcode",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/trash": {
      title: "Trash",
      isAddDropdown: false,
      isDateFilter: true,
      isYearFilter: false,
      isPlainText: false,
    },
    "/history": {
      title: "History",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/storage": {
      title: "Storage",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/analytics": {
      title: "Analytics",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/privacy-policy": {
      title: "Privacy Policy - Vidscan Tech",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/terms-and-conditions": {
      title: "Terms and Conditions",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/help": {
      title: "Help",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/about-us": {
      title: "About us",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/profile": {
      title: "Profile",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/change-password": {
      title: "Change Password",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: true,
    },
    "/pricing": {
      title: "Pricing",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/notification": {
      title: "Notification",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin": {
      title: "Dashboard",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin/pricing": {
      title: "Pricing",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin/notification": {
      title: "Notification",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin/plans": {
      title: "Membership",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: false,
      isPlainText: false,
    },
    "/admin/history/": {
      title: "History",
      isAddDropdown: false,
      isDateFilter: false,
      isYearFilter: true,
      isPlainText: false,
    },
  };

  const folderDetails = queryClient.getQueriesData({
    queryKey: [GETIFOLDERDETAILS, page, limit, debouncedSearch, start, end, id],
  });
  const folder =
    (folderDetails &&
      folderDetails.at(0) &&
      folderDetails.at(0).at(1) &&
      folderDetails.at(0).at(1).currentDetails) ??
    {};

  useEffect(() => {
    if (id && !state && !!!state?.from && folder.name) {
      const path = Object.keys(mainUser).findLast((key) =>
        pathname.includes(key)
      );
      navigate(`${path}/${id}`, {
        state: {
          from: [
            {
              id: folder._id ?? id,
              name: folder.name ?? "No folder name found",
            },
          ],
        },
      });
    }
  }, [folder.name, title.id]);

  return isMainuser
    ? { PATHS: mainUser, isMainuser }
    : { PATHS: subuser, isMainuser };
};

export default usePaths;
