import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    details: null,
}

const detailsSlice = createSlice({
    name: "FileDetails",
    initialState,
    reducers: {
        setDetails: (state, { payload }) => {
            state.details = payload
        },
    }
})

export const { setDetails } = detailsSlice.actions

export default detailsSlice.reducer