import {
  ChevronDown,
  ChevronFirst,
  ChevronLast,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { useDispatch, useSelector } from "react-redux";
import { setLimit, setPage } from "../redux/reducers/filterSlice";
import { resetRowSelection } from "../redux/reducers/rowSlice";
import { useMemo } from "react";

const Pagination = ({
  table,
  rowSelection,
  enableRowSelection,
  pageSizes = [100, 200, 300, 400],
}) => {
  const { totalPage, page, limit } = useSelector(
    (state) => state.filterReducer
  );


  const dispatch = useDispatch();

  const { startIndex, endIndex } = useMemo(() => {
    const startIndex = (page - 1) * limit + 1;
    const endIndex = Math.min(page * limit, totalPage * limit);

    return { startIndex, endIndex };
  }, [page, limit, totalPage]);

  return (
    <div className="flex items-center justify-between space-x-2 pt-4">
      <div className="text-text text-sm">
        {enableRowSelection ? (
          <p className="font-lato">
            {Object.keys(rowSelection).length}
            &nbsp; of &nbsp;
            {table.getPreFilteredRowModel().rows.length}
            &nbsp; Total Rows Selected
          </p>
        ) : (
          <p className="font-lato">
            {table.getPreFilteredRowModel().rows.length} Total Rows
          </p>
        )}
      </div>
      <div>
        <p className="text-text text-sm font-lato">
          Displaying {startIndex}-{endIndex} on page {page}/{totalPage}
        </p>
      </div>
      <div className="flex items-center gap-x-4">
        <div className="flex items-center">
          <p className="text-text text-sm">Rows per page:</p>
          <DropdownMenu>
            <DropdownMenuTrigger className="px-2 py-1 flex items-center gap-x-2 text-text text-sm">
              <p>{limit}</p>
              <ChevronDown className="w-4 h-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="min-w-max bg-bg2 text-text border-none">
              {pageSizes.map((s, i) => {
                return (
                  <DropdownMenuItem
                    key={i}
                    DropdownMenuItem
                    onSelect={(e) =>
                      dispatch(setLimit(Number(e.target.innerHTML)))
                    }
                  >
                    {s}
                  </DropdownMenuItem>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              dispatch(setPage(1));
              dispatch(resetRowSelection());
              table.resetRowSelection();
            }}
            disabled={page === 1}
            className="text-text2 border-none bg-bg2 hover:bg-bg2-hover"
          >
            <ChevronFirst />
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              dispatch(setPage(page - 1));
              dispatch(resetRowSelection());
              table.resetRowSelection();
            }}
            disabled={page <= 1}
            className="text-text2 border-none bg-bg2 hover:bg-bg2-hover"
          >
            <ChevronLeft />
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              dispatch(setPage(page + 1));
              dispatch(resetRowSelection());
              table.resetRowSelection();
            }}
            disabled={page >= totalPage}
            className="text-text2 border-none bg-bg2 hover:bg-bg2-hover"
          >
            <ChevronRight />
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              dispatch(setPage(totalPage));
              dispatch(resetRowSelection());
              table.resetRowSelection();
            }}
            disabled={page === totalPage}
            className="text-text2 border-none bg-bg2 hover:bg-bg2-hover"
          >
            <ChevronLast />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
