import { useQuery, } from "@tanstack/react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import { GETCREDITS, PLAN_HISTORY } from "../../constant/endpoints";
import { privateAdminAxios } from '../../lib/axios'
import { useDispatch, useSelector } from "react-redux";
import usePageLeave from "../../hooks/usePageLeave";
import { resetFilter, setSelectedYear } from "../../redux/reducers/filterSlice";
import { ArrowDown, ArrowUp, CalendarDays, ChevronRight, CreditCard, Dot, Wallet2 } from "lucide-react";
import { useEffect, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";
import { useState } from "react";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { cn } from "../../components/utils";
import { Button, buttonVariants } from "../../components/ui/button";
import { formatFileSize } from "../../lib/helper";
import AddOfflinePayment from "../../components/modals/AddOfflinePayment";

const History = () => {
  const defaultYear = new Date().getFullYear();
  const { selectedYear = defaultYear } = useSelector((state) => state.filterReducer);
  const { userId } = useParams()
  const { state } = useLocation();
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(2)
  const [selectedTab, setSelectedTab] = useState("Dispatch")
  const [offlinePayment, setOfflinePayment] = useState({
    open: false,
    data: null
  })
  const [expanded, setExpanded] = useState({});
  const [sorting, setSorting] = useState([]);
  const [paymentSorting, setPaymentSorting] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([])

  usePageLeave(() => {
    dispatch(resetFilter());
  });

  // const paymentHistory = useQuery({
  //   queryKey: [PLAN_HISTORY],
  //   queryFn: () => privateAdminAxios(PLAN_HISTORY, { user_id: userId }),
  // });

  const getPaymentHistoryData = async () => {
    const response = await privateAdminAxios(PLAN_HISTORY, { user_id: userId })
    response?.data && setPaymentHistory(response?.data)
  }

  useEffect(() => {
    if (offlinePayment?.open === false) {
      getPaymentHistoryData();
    }

  }, [offlinePayment?.open])

  const { data = [], isLoading, isFetching, } = useQuery({
    queryKey: [GETCREDITS, selectedYear, selectedTab],
    queryFn: () => privateAdminAxios(GETCREDITS, { user_id: userId, year: selectedYear.toString(), type: selectedTab }, "POST"),
    select: (res) => {
      // we are clongin month field and days field for better sorting
      const clonedData = res.data.map(obj => {
        const subRows = []
        if (obj.subRows && Array.isArray(obj.subRows) && obj.subRows.length) {
          obj.subRows.forEach(sr => {
            subRows.push({
              ...sr,
              sortable: sr.day
            })
          })
          return {
            ...obj,
            subRows,
            sortable: obj.month
          }
        }
      })
      return clonedData
    }
  });

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "expand",
        header: (props) => (
          <div
            className="w-max p-2 hover:bg-bg-primary rounded-full aspect-square cursor-pointer"
            onClick={() =>
              props.table.toggleAllRowsExpanded(
                !props.table.getIsSomeRowsExpanded()
              )
            }
          >
            <ChevronRight
              className={twMerge(
                "w-5 h-5 rounded-full transition-all",
                props.table.getIsSomeRowsExpanded() ? "rotate-90" : "rotate-0"
              )}
            />
          </div>
        ),
        cell: (props) =>
          props.row.depth === 0 ? (
            <div
              className="w-max p-2 hover:bg-bg-primary rounded-full aspect-square cursor-pointer"
              onClick={props.row.getToggleExpandedHandler()}
            >
              <ChevronRight
                className={twMerge(
                  "w-5 h-5 rounded-full transition-all",
                  props.row.getIsExpanded() ? "rotate-90" : "rotate-0"
                )}
              />
            </div>
          ) : (
            <div className="w-max p-2 hover:bg-bg-primary rounded-full aspect-square cursor-pointer">
              <Dot className={twMerge("w-5 h-5 rounded-full")} />
            </div>
          ),
        size: 48,
        enableExpanding: true,
      }),

      columnHelper.accessor("sortable", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              Month
            </div>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="py-2 flex items-center gap-x-4">
            <span>
              {props.row.original.day
                ? props.row.original.day
                : format(
                  new Date(new Date().setMonth(Number(props.getValue() - 1))),
                  "MMM"
                )}
              {!props.row.original.day && `, ${selectedYear}`}
            </span>
          </div>
        ),
        enableSorting: true,
        size: 525,
      }),

      columnHelper.accessor("totalInvoices", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              Invoices
            </div>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <span>{props.getValue()}</span>
          </div>
        ),
        enableSorting: true,
        size: 525,
      }),

      columnHelper.accessor("totalCredits", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              Credits
            </div>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <span>{props.getValue()}</span>
          </div>
        ),
        enableSorting: true,
        size: 525,
      }),
    ],
    []
  );

  const historyColumn = useMemo(() => [
    columnHelper.accessor("order_id", {
      header: (props) => (
        <div className="flex items-center cursor-pointer">
          <div className="py-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            Order Id
          </div>
          <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            {props.header.column.getIsSorted() === "asc" ? (
              <ArrowDown className="w-4 h-4" />
            ) : (
              <ArrowUp className="w-4 h-4" />
            )}
          </div>
        </div>
      ),
      cell: (props) => (
        <div className="py-2 flex items-center gap-x-4">
          {props.getValue()}
        </div>
      ),
      enableSorting: true,
      size: 525,
    }),

    columnHelper.accessor("payment_status", {
      header: (props) => (
        <div className="flex items-center cursor-pointer">
          <div className="py-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            Payment status
          </div>
          <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            {props.header.column.getIsSorted() === "asc" ? (
              <ArrowDown className="w-4 h-4" />
            ) : (
              <ArrowUp className="w-4 h-4" />
            )}
          </div>
        </div>
      ),
      cell: (props) => (
        <div className="py-2 flex items-center gap-x-4">
          {props.getValue()}
        </div>
      ),
      enableSorting: true,
      size: 525,
    }),

    columnHelper.accessor("amount", {
      header: (props) => (
        <div className="flex items-center cursor-pointer">
          <div className="py-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            Credits
          </div>
          <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            {props.header.column.getIsSorted() === "asc" ? (
              <ArrowDown className="w-4 h-4" />
            ) : (
              <ArrowUp className="w-4 h-4" />
            )}
          </div>
        </div>
      ),
      cell: (props) => (
        <div className="py-2 flex items-center gap-x-4">
          {props.getValue()}
        </div>
      ),
      enableSorting: true,
      size: 525,
    }),
  ], [])

  return isLoading ? (
    <Loader />
  ) : (
    <div className="p-4">
      <Tabs
        value={activeTab === 1 ? "account" : activeTab === 2 ? "payment" : "usage"}
        className="w-full relative"
        onValueChange={(e) => {
          dispatch(setSelectedYear(defaultYear))
          setActiveTab(e === "account" ? 1 : e === "payment" ? 2 : 3)
        }}
      >
        <div className="w-full flex justify-between items-center border-b-2 border-text3 transition-all">
          <TabsList className="h-full p-0 bg-transparent">
            {/* <TabsTrigger
              value="account"
              className="py-1 px-2 -mb-[2px]  flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:border-text3 rounded-t-md data-[state=active]:shadow-none"
            >
              <span className="text-lg text-primary">Account Summary</span>
              <span className="text-text2">Usage Snapshot</span>
            </TabsTrigger> */}
            <TabsTrigger
              value="payment"
              className="py-1 px-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:border-text3 rounded-t-md data-[state=active]:shadow-none"
            >
              <span className="text-lg text-primary">Payment History</span>
              <span className="text-text2">Payment for services</span>
            </TabsTrigger>
            <TabsTrigger
              value="usage"
              className="py-1 px-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:border-text3 rounded-t-md data-[state=active]:shadow-none"
            >
              <span className="text-lg text-primary">Usage History</span>
              <span className="text-text2">Periodic Billing Pattern</span>
            </TabsTrigger>
          </TabsList>
          <div className="absolute top-0 right-0">
            {activeTab === 2 && <Button onClick={() => { setOfflinePayment({ open: true, data: { name: state?.name, user_id: userId } }) }}>Offline Payment</Button>}
          </div>
        </div>

        <TabsContent value="account" className="m-0 py-4 overflow-hidden">
          <div className="flex gap-y-6 flex-col">
            <div className="p-2 pe-4 pb-4 border-2 rounded-md">
              <h6 className="text-text font-medium">MY USAGE</h6>
              <p className="text-text2 text-sm">Current month to date usage for {format(new Date(), "LLL")}</p>

              <hr className="-mx-4 my-2" />

              <div className="flex justify-between items-stretch gap-10">
                <div className="w-full flex flex-col space-y-1">
                  <h6 className="font-medium text-text2 text-lg">{formatFileSize("totalUsedStorage", 2)}</h6>
                  <div className="h-6 bg-text3 relative">
                    <div style={{ width: "totalUsedStoragePercentage" + "%" }} className="absolute h-full bg-primary left-0 transition-all"></div>
                  </div>
                </div>
                <div className="w-full max-w-[485px] flex items-stretch gap-8">
                  <div className="w-full max-w-[85px] whitespace-nowrap text-sm flex flex-col justify-end items-end">
                    <p>Total Storage</p>
                    <p className="text-text font-medium whitespace-nowrap">{formatFileSize("totaleStorage")}</p>
                  </div>
                  <div className="w-full flex items-center">
                    <Link to="/get-storage" className={cn(buttonVariants("default"), "w-full bg-primary rounded-sm")}>Buy More Storage</Link>
                  </div>
                </div>
              </div>

              <hr className="-mx-4 my-4" />

              <div className="flex justify-between items-stretch gap-10">
                <div className="w-full flex flex-col space-y-1">
                  <h6 className="font-medium text-text2 text-lg">2500 Credit</h6>
                  <div className="h-6 bg-text3 relative">
                    <div style={{ width: "50%" }} className="absolute h-full bg-primary left-0 transition-all"></div>
                  </div>
                </div>
                <div className="w-full max-w-[485px] flex items-stretch gap-8">
                  <div className="w-full max-w-[85px] whitespace-nowrap text-sm flex flex-col justify-end items-end">
                    <p>Used Credit</p>
                    <p className="text-text font-medium whitespace-nowrap">5000</p>
                  </div>
                  <div className="w-full items-center hidden">
                    <Link to="/get-storage" className={cn(buttonVariants("default"), "w-full bg-primary rounded-sm")}>Buy More Credits</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2 pe-4 border-2 rounded-md">
              <h6 className="text-text font-medium">MY PLAN</h6>
              <hr className="-mx-4 my-2" />
              <div className="py-2 flex justify-between">
                <div className="flex gap-x-2">
                  <CreditCard className="w-5 h-5 text-text1" />
                  <div>
                    <p className="text-sm text-text2">Current Plan</p>
                    <p className="font-medium">STANDARD</p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <CalendarDays className="w-5 h-5 text-text1" />
                  <div>
                    <p className="text-sm text-text2">Activation Date</p>
                    <p className="font-medium">{format(new Date(), "LLL, dd, yyyy")}</p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <Wallet2 className="w-5 h-5 text-text1" />
                  <div>
                    <p className="text-sm text-text2">Price Per Dispatch</p>
                    <p className="font-medium">2 Credit</p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <Wallet2 className="w-5 h-5 text-text1" />
                  <div>
                    <p className="text-sm text-text2">Price Per Return</p>
                    <p className="font-medium">5 Credit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="payment" className="m-0">
          {(paymentHistory && paymentHistory?.length > 0)
            ? <DataTable
              isFetching={paymentHistory.isFetching}
              data={paymentHistory}
              columns={historyColumn}
              pagination={false}
              sorting={paymentSorting}
              setSorting={setPaymentSorting}
              sortableColumns={["sortable", "totalInvoices", "totalCredits"]}
              enableSearch={false}
              enableRowSelection={false}
            />
            : <div className="h-[300px] flex items-center justify-center border-2 border-t-0">
              <h4 className="text-xl text-center">No data found</h4>
            </div>}
        </TabsContent>

        <TabsContent value="usage" className="m-0 py-4">
          <Tabs
            value={selectedTab.toLocaleLowerCase()}
            className="w-full"
            onValueChange={e => {
              setSelectedTab(e === "dispatch" ? "Dispatch" : e === "return" ? "Return" : "Monthly")
            }}
          >
            <div className="pl-3 w-full border-b-2 border-text3 transition-all">
              <TabsList className="h-full p-0 bg-transparent">
                <TabsTrigger
                  value="dispatch"
                  className="p-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:text-primary data-[state=active]:border-text3 rounded-t-[4px] data-[state=active]:shadow-none"
                >
                  <span className="text-base">Dispatch</span>
                </TabsTrigger>
                <TabsTrigger
                  value="return"
                  className="p-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:text-primary data-[state=active]:border-text3 rounded-t-[4px] data-[state=active]:shadow-none"
                >
                  <span className="text-base">Return</span>
                </TabsTrigger>
                {/* <TabsTrigger
                  value="monthly"
                  className="p-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:text-primary data-[state=active]:border-text3 rounded-t-[4px] data-[state=active]:shadow-none"
                >
                  <span className="text-base">Monthly</span>
                </TabsTrigger> */}
              </TabsList>
            </div>
            <TabsContent value="dispatch" className={cn("m-0", (data && data.length > 0) ? "py-4" : "py-0")}>
              {(data && data.length > 0)
                ? <DataTable
                  isFetching={isFetching}
                  data={data}
                  columns={columns}
                  pagination={false}
                  sorting={sorting}
                  setSorting={setSorting}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  sortableColumns={["sortable", "totalInvoices", "totalCredits"]}
                  enableSearch={false}
                  enableRowSelection={false}
                />
                : <div className="h-[300px] flex items-center justify-center border-2 border-t-0">
                  <h4 className="text-xl text-center">No data found</h4>
                </div>}
            </TabsContent>
            <TabsContent value="return" className={cn("m-0", (data && data.length > 0) ? "py-4" : "py-0")}>
              {(data && data.length > 0)
                ? <DataTable
                  isFetching={isFetching}
                  data={data}
                  columns={columns}
                  pagination={false}
                  sorting={sorting}
                  setSorting={setSorting}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  sortableColumns={["sortable", "totalInvoices", "totalCredits"]}
                  enableSearch={false}
                  enableRowSelection={false}
                />
                : <div className="h-[300px] flex items-center justify-center border-2 border-t-0">
                  <h4 className="text-xl text-center">No data found</h4>
                </div>}
            </TabsContent>
            <TabsContent value="monthly" className={cn("m-0", (data && data.length > 0) ? "py-4" : "py-0")}>
              {(data && data.length > 0)
                ? <DataTable
                  isFetching={isFetching}
                  data={data}
                  columns={columns}
                  pagination={false}
                  sorting={sorting}
                  setSorting={setSorting}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  sortableColumns={["month", "totalInvoices", "totalCredits"]}
                  enableSearch={false}
                  enableRowSelection={false}
                />
                : <div className="h-[300px] flex items-center justify-center border-2 border-t-0">
                  <h4 className="text-xl text-center">No data found</h4>
                </div>}
            </TabsContent>
          </Tabs>
        </TabsContent>
      </Tabs>
      {
        offlinePayment && <AddOfflinePayment modal={offlinePayment} setModal={setOfflinePayment} />
      }
    </div>
  );
}

export default History