import { HelpCircle, LogOut, Menu, Settings, Bell } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";
import InfoText from "../InfoText";
import Logo from "../../assets/images/logo.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { privateAdminAxios, privateAxios } from "../../lib/axios";
import { LOGOUT, ADMINLOGOUT, NOTIFICATION_LIST, NOTIFICATION_DELETE } from "../../constant/endpoints";
import { useMutation } from "@tanstack/react-query";
import { resetAuth } from "../../redux/reducers/userAuthSlice";
import { resetAuth as resetAdminAuth } from "../../redux/reducers/userAuthSlice";
import { toastHandler } from "../../lib/helper";
import { useDispatch, useSelector } from "react-redux";
import { resetInitialFolders } from "../../redux/reducers/intialFoldersSlice";
import usericon from "../../assets/images/usericon.png";
import usePaths from "../../hooks/usePaths";
import moment from "moment";
import { Trash2 } from 'lucide-react';

const Header = () => {
  const { email, name, logo } = useSelector(
    (state) => state.userAuthReducer.auth
  );
  const [isSettingDropdown, setIsSettingDropdown] = useState(false);
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false)
  const [isInfoDropdown, setIsInfoDropdown] = useState(false);
  const [isProfileInfoDropdown, setIsProfileInfoDropdown] = useState(false);
  const [isSidebar, setIsSidebar] = useState(false);
  const [notificationData, setNotificationData] = useState([])
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [limit, setLimit] = useState(5)
  const [totalNotification, setTotalNotification] = useState();

  const { pathname } = useLocation();
  const { isMainuser } = usePaths();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationRef = useRef(null);

  useEffect(() => {
    setIsSettingDropdown(false);
    setIsInfoDropdown(false);
    setIsProfileInfoDropdown(false);
    setIsSidebar(false);
  }, [pathname]);

  const { mutate } = useMutation({
    mutationFn: () => privateAxios(LOGOUT),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        localStorage.removeItem("token");
        dispatch(resetAuth());
        dispatch(resetInitialFolders());
        navigate("login");
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const { mutate: adminLogout } = useMutation({
    mutationFn: () => privateAdminAxios(ADMINLOGOUT),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        localStorage.removeItem("adminToken");
        dispatch(resetAdminAuth());
        navigate("admin/login");
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  // const notificationList = useMutation({
  //   mutationFn: (values) => privateAxios(NOTIFICATION_LIST, values),
  //   onSuccess: (res) => {
  //     res && res?.data && setNotificationData(res?.data?.notifications)
  //     console.log("onSuccessonSuccessonSuccess", res?.data?.notifications);
  //   }
  // })

  const getNotificationData = async (payload) => {
    const resonse = await privateAxios(NOTIFICATION_LIST, payload);
    resonse && resonse?.data && resonse?.data?.notifications && setNotificationData(resonse?.data?.notifications)
    resonse && setTotalNotification(parseInt(resonse?.totalRecords))
    console.log("getNotificationData", resonse?.data?.notifications)
  }

  const handleSelectNotification = (id) => {
    if (selectedNotifications.includes(id)) {
      setSelectedNotifications(selectedNotifications.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedNotifications([...selectedNotifications, id]);
    }
  };

  const handleDeleteSelected = async () => {
    const response = await privateAxios(NOTIFICATION_DELETE, { notificaitonIds: selectedNotifications?.map((val) => val).join(",") })
    if (response?.ResponseCode === "1" && response?.Result === "True") {
      toast.success(response?.ResponseMsg)
      getNotificationData({ page: 1, limit: limit })
    }
    else {
      toast.error(response?.ResponseMsg)  
    }
    // if (selectedNotifications.length > 0) {
    //   console.log('Deleting notifications:', selectedNotifications);
    //   setSelectedNotifications([]);
    // } else {
    //   alert('No notifications selected');
    // }
  };
  const isSelected = (id) => selectedNotifications.includes(id);

  const handleScroll = () => {
    const scrollElement = notificationRef.current;
    if (scrollElement) {
      const { scrollTop, scrollHeight, clientHeight } = scrollElement;
      if (
        scrollTop + clientHeight >= scrollHeight - 5
      ) {
        limit < totalNotification && setLimit((prevLimit) => prevLimit + 5);
      }
    }
  };

  useEffect(() => {
    const scrollElement = notificationRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);


  useEffect(() => {
    getNotificationData({ page: 1, limit: limit })
  }, [limit, isNotificationDropdown])

  const handleLogout = () => {
    if (pathname.includes("/admin")) {
      return adminLogout();
    } else {
      return mutate();
    }
  };

  // useEffect(() => {

  // }, [])

  const handleDeleteAccount = () => {
    navigate("login");
    toast.success("Your account delete successfully");
  };

  return (
    <header className="w-screen py-3 px-4 flex justify-between items-center text-blue-300 bg-bg-primary fixed top-0 left-0 z-10">
      <div className="w-full flex items-center gap-x-4 justify-between md:justify-start">
        <Link
          to={pathname.includes("/admin") ? "/admin" : "/dispatch"}
          className="hidden md:block w-[280px]"
        >
          <div className="max-w-[180px]">
            <img src={Logo} alt="logo" />
          </div>
        </Link>
        <Sheet open={isSidebar} onOpenChange={() => setIsSidebar(!isSidebar)}>
          <SheetTrigger onClick={() => setIsSidebar(true)}>
            <Menu className="w-7 h-7 block md:hidden text-text2" />
          </SheetTrigger>
          <SheetContent
            closeBtn={false}
            side="left"
            className="w-full max-w-max py-4 px-0 bg-bg-primary border-r-0 overflow-y-auto"
          >
            <Sidebar visiable />
          </SheetContent>
        </Sheet>
      </div>

      <div className="hidden md:flex items-center gap-x-2">
        {!pathname.includes("/admin") && (
          <>
            <Popover
              open={isNotificationDropdown}
              onOpenChange={() => {
                // notificationList.mutate({ page: 1, limit: 10 }); 
                setIsNotificationDropdown(!isNotificationDropdown)
              }}
              modal={false}
            >
              <PopoverTrigger className="flex">
                <InfoText text="Info">
                  <div className="p-2 rounded-full hover:bg-bg2-hover transition-all cursor-pointer">
                    <Bell className="text-text" />
                  </div>
                </InfoText>
              </PopoverTrigger>
              <PopoverContent className="w-[500px] h-[150px] bg-bg2 border-none overflow-y-scroll" subRef={notificationRef}>
                <div className="flex justify-end ">
                  <Trash2 className="size-5 text-[#DE3A3B]" onClick={() => { handleDeleteSelected() }} />
                </div>
                {
                  notificationData?.length > 0 ? <>
                    {
                      notificationData?.map((val) => {
                        return (
                          <div className="flex justify-between items-center py-2.5 border-b">
                            <div className="flex gap-x-3 items-center">
                              <input
                                type="checkbox"
                                checked={isSelected(val?._id)}
                                onChange={() => handleSelectNotification(val?._id)}
                                className="w-[15px] h-[15px]"
                              />
                              <p className="text-base font-normal">{val?.message}</p>
                            </div>
                            <p className="text-sm font-normal">{moment(val?.createdAt).format("DD MMM YYYY HH:MM A")}</p>
                          </div>
                        )
                      })
                    }
                  </> : <div className="flex justify-center items-center h-full"><p className="text-center">No Data Found</p></div>
                }
              </PopoverContent>
            </Popover>
            <Popover
              open={isInfoDropdown}
              onOpenChange={() => setIsInfoDropdown(!isInfoDropdown)}
              modal={false}
            >
              <PopoverTrigger className="flex">
                <InfoText text="Info">
                  <div className="p-2 rounded-full hover:bg-bg2-hover transition-all cursor-pointer">
                    <HelpCircle className="text-text" />
                  </div>
                </InfoText>
              </PopoverTrigger>
              <PopoverContent className="w-auto bg-bg2 border-none">
                <ul className="text-text space-y-3">
                  <li>
                    <Link to="privacy-policy" className="block">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="terms-and-conditions" className="block">
                      Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="help" className="block">
                      Help
                    </Link>
                  </li>
                </ul>
              </PopoverContent>
            </Popover>
            <Popover
              open={isSettingDropdown}
              onOpenChange={() => setIsSettingDropdown(!isSettingDropdown)}
              modal={false}
            >
              <PopoverTrigger className="flex">
                <InfoText text="Settings">
                  <div className="p-2 rounded-full hover:bg-bg2-hover transition-all cursor-pointer">
                    <Settings className="text-text" />
                  </div>
                </InfoText>
              </PopoverTrigger>
              <PopoverContent className="w-auto bg-bg2 border-none">
                <ul className="text-text space-y-3">
                  {isMainuser && (
                    <li>
                      <Link to="manage-subusers" className="block">
                        Manage subuser
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="analytics" className="block">
                      Analytics
                    </Link>
                  </li>
                </ul>
              </PopoverContent>
            </Popover>
          </>
        )}

        <Popover
          open={isProfileInfoDropdown}
          onOpenChange={() => setIsProfileInfoDropdown(!isProfileInfoDropdown)}
        >
          <PopoverTrigger asChild>
            <Avatar className="w-7 h-7">
              <AvatarImage src={logo ? logo : usericon} />
              <AvatarFallback className="bg-bg-primary uppercase">
                {name?.slice(0, 2) ?? ""}
              </AvatarFallback>
            </Avatar>
          </PopoverTrigger>
          <PopoverContent className="w-[340px] bg-bg2 border-none">
            <p className="text-text text-center text-sm">
              {email ? email : "No email found"}
            </p>
            <div className="text-center mt-4">
              <Link
                to={pathname.includes("/admin") ? "/admin/profile" : "profile"}
              >
                <Avatar className="w-20 h-20 mx-auto relative">
                  <AvatarImage src={logo ? logo : usericon} />
                  <AvatarFallback className="uppercase">
                    {name?.slice(0, 2) ?? ""}
                  </AvatarFallback>
                </Avatar>
              </Link>
              <p className="mt-2 text-lg text-text font-lato">
                Hi, {name ?? "No name found"}!
              </p>
            </div>
            <div className="max-w-max mx-auto mt-3 flex gap-x-0.5">
              <div
                className="w-full py-3 px-10 space-x-2 rounded-full flex text-text bg-bg-primary text-sm items-center cursor-pointer transition-all hover:bg-bg2-hover"
                onClick={handleLogout}
              >
                <LogOut className="w-4.5 h-4.5" />
                <span>Logout</span>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </header>
  );
};

export default Header;
