import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { updatePasswordSchema } from "../../lib/schemas";
import { useMutation } from "@tanstack/react-query";
import { privateAxios } from "../../lib/axios";
import { toastHandler } from "../../lib/helper";
import { CHANGEPASSWORD } from "../../constant/endpoints";
import md5 from "md5";
import { Link } from "react-router-dom";
import usePaths from "../../hooks/usePaths";

const ChangePassword = () => {
  const { isMainuser } = usePaths();

  const form = useForm({
    resolver: zodResolver(updatePasswordSchema),
    defaultValues: {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    },
  });

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(CHANGEPASSWORD, values),
    onSuccess: (res) => {
      form.reset();
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const onSubmit = (values) => {
    const { confirm_new_password, old_password, new_password } = values;
    return mutate({
      old_password: md5(old_password),
      new_password: md5(new_password),
    });
  };

  return (
    <div className="w-full max-w-3xl h-full mx-auto flex flex-col justify-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full h-max space-y-6"
        >
          <FormField
            control={form.control}
            name="old_password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="text-text text-base font-lato">
                  Current Password
                </FormLabel>
                <FormControl>
                  <Input placeholder="Enter current password" {...field} disabled={!isMainuser} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="new_password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="text-text text-base font-lato">
                  New Password
                </FormLabel>
                <FormControl>
                  <Input placeholder="Enter new password" {...field} disabled={!isMainuser} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="confirm_new_password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="text-text text-base font-lato">
                  Confirm New Password
                </FormLabel>
                <FormControl>
                  <Input placeholder="Re-enter new password" {...field} disabled={!isMainuser} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="w-full font-lato font-bold text-lg bg-primary"
            disabled={!form.formState.isDirty}
          >
            Update Password
          </Button>
        </form>
      </Form>
      <div className="flex flex-col justify-between items-center gap-y-1 text-center mt-6">
        <Link
          to="/profile"
          className="text-center text-primary text-base cursor-pointer font-semibold font-lato"
        >
          Update Profile?
        </Link>
      </div>
    </div>
  );
};

export default ChangePassword;
