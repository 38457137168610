import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '../ui/dialog'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { Button } from '../ui/button'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../ui/select'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { privateAxios } from '../../lib/axios'
import { GETIFOLDERDETAILS, UPDATEVIDEOCONDITION } from '../../constant/endpoints'
import { toastHandler } from '../../lib/helper'
import { useDispatch, useSelector } from 'react-redux'
import { resetFilter } from '../../redux/reducers/filterSlice'
import { resetRowSelection } from '../../redux/reducers/rowSlice'
import { useDebounce } from '@uidotdev/usehooks'
import usePageLeave from '../../hooks/usePageLeave'
import { useParams } from 'react-router-dom'

const UpdateVideoCondition = ({ modal, setModal, onChange, handleSubmit }) => {
  const [selectedValue, setSelectedValue] = useState(modal?.data?.condition || "");
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { page, limit, search, start, end } = useSelector(
    (state) => state.filterReducer
  );

  useEffect(() => {
    if (modal?.data) {
      setSelectedValue(modal.data?.condition);
    }
  }, [modal?.data]);
  const debouncedSearch = useDebounce(search, 300);
  usePageLeave(() => {
    dispatch(resetFilter());
    dispatch(resetRowSelection());
  });

  const { mutate: updateCondition } = useMutation({
    mutationFn: (values) => privateAxios(UPDATEVIDEOCONDITION, values),
    onSuccess: async (res) => {
      setModal({ open: false, data: null, mode: null })
      if (res.ResponseCode === "1") {
        queryClient.invalidateQueries({
          queryKey: [
            GETIFOLDERDETAILS,
            page,
            limit,
            debouncedSearch,
            start,
            end,
            id,
          ],
        });
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res)
    }
  })
  return (
    <Dialog open={modal.open}
      onOpenChange={(open) => setModal({ open, data: null })}>
      <DialogContent>
        <Label className="text-text text-2xl">
          Update Condition
        </Label>
        <Select value={selectedValue} onValueChange={setSelectedValue} >
          <SelectTrigger className="bg-bg2  ">
            <SelectValue placeholder="Select condition" />
          </SelectTrigger>
          <SelectContent className="bg-bg2">
            <SelectGroup>
              <SelectLabel>Condition</SelectLabel>
              <SelectItem value="good">Good</SelectItem>
              <SelectItem value="bad">Bad</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <div className="flex justify-end gap-x-3">
          <Button
            type="button"
            onClick={() =>
              setModal({ open: false, data: null, mode: null })
            }
          >
            Cancel
          </Button>
          <Button type="submit" onClick={() => { updateCondition({ videoId: modal?.data?._id, quality: selectedValue }) }}>
            Go
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateVideoCondition