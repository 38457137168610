import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import {
  BellRing,
  Database,
  FolderPlus,
  History,
  LayoutDashboard,
  Plus,
  Star,
  Trash2,
  Truck,
  Undo2,
  Users,
  Wallet,
  Gem,
  DownloadCloud,
  QrCode,
} from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { TbPasswordUser } from "react-icons/tb";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Badge } from "../../ui/badge";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CREATEFOLDER,
  GETIFOLDERDETAILS,
  STORAGE,
} from "../../../constant/endpoints";
import { privateAxios } from "../../../lib/axios";
import { useMemo } from "react";
import { formatFileSize, toastHandler } from "../../../lib/helper";
import { useSelector } from "react-redux";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createNewFolder } from "../../../lib/schemas";
import { useState } from "react";
import { Dialog, DialogContent } from "../../ui/dialog";
import { Button } from "../../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { cn } from "../../utils";
import usePaths from "../../../hooks/usePaths";
import usericon from "../../../assets/images/usericon.png";
import CreditModal from "../../modals/CreditModal";

const Sidebar = ({ visiable }) => {
  const { name, email } = useSelector((state) => state.adminAuthReducer.auth);
  const totaleStorage = useSelector(
    (state) => state.userAuthReducer.auth.storage
  );
  const dispatchParent = useSelector(
    (state) => state.intialFoldersReducer.dispatch
  );

  const returnParent = useSelector(
    (state) => state.intialFoldersReducer.return
  );
  const [creditModal, setCreditModal] = useState({ open: false, data: null })
  const [modal, setIsModal] = useState({ open: false, name: null });
  const { pathname } = useLocation();

  const { isMainuser } = usePaths();

  const queryClient = useQueryClient();

  const { data = [] } = useQuery({
    queryKey: [STORAGE],
    queryFn: () => privateAxios(STORAGE, null, "GET"),
    select: (res) => res.data,
    enabled: !pathname.includes("/login") && !pathname.includes("/admin"),
  });

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(CREATEFOLDER, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
      toastHandler(res);
    },
    onSettled: () => {
      folder.reset();
      setIsModal((prevState) => ({ ...prevState, open: false, name: null }));
    },
  });

  const folder = useForm({
    resolver: zodResolver(createNewFolder),
    defaultValues: {
      displayName: "",
      parent_folder: "",
    },
  });

  const totalUsedStorage = useMemo(
    () => data.reduce((acc, curr) => (acc += Number(curr.storage)), 0),
    [data]
  );

  const totalUsedStoragePercentage = useMemo(
    () => (totalUsedStorage * 100) / totaleStorage,
    [data, totalUsedStorage, totaleStorage]
  );

  const onSubmit = (values) => {
    const { displayName: name, parent_folder = dispatchParent._id } =
      folder.getValues();
    const body = {
      name,
      parent_folder: parent_folder ? parent_folder : dispatchParent._id,
    };
    mutate(body);
  };

  const handleClose = () => {
    setIsModal((prevState) => ({
      open: !prevState.open,
      data: null,
    }));
    folder.reset();
  };

  return (
    <aside
      className={twMerge(
        "hidden md:flex min-w-[90%] h-[calc(100vh-70px)] s:min-w-[280px] px-4 flex-col fixed left-0 top-[70px] bg-bg-primary overflow-y-auto",
        visiable && "h-screen pt-4 block top-0"
      )}
    >
      {pathname.includes("/admin") ? (
        <>
          <div>
            <div className="block md:hidden mb-6">
              <Link to="profile">
                <Avatar>
                  <AvatarImage src={usericon} />
                  <AvatarFallback className="uppercase">
                    {name ? name.slice(0, 2) ?? "" : ""}
                  </AvatarFallback>
                </Avatar>
              </Link>
              <p className="mt-2 text-text2">{email ?? "ADMIN"}</p>
              <hr className="-mx-4 mt-4" />
            </div>

            <ul className="text-text space-y-2 md:space-y-1">
              <li>
                <Link
                  to="/admin"
                  className={
                    pathname === "/admin" ||
                      pathname.includes("/admin/history/")
                      ? "sidebar-item-active"
                      : "sidebar-item"
                  }
                >
                  <LayoutDashboard />
                  <span>Dashboard</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/admin/pricing"
                  className={
                    pathname.includes("/pricing")
                      ? "sidebar-item-active"
                      : "sidebar-item"
                  }
                >
                  <Wallet />
                  <span>Pricing</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/admin/notification"
                  className={
                    pathname.includes("/notification")
                      ? "sidebar-item-active"
                      : "sidebar-item"
                  }
                >
                  <BellRing />
                  <span>Notification</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/admin/plans"
                  className={
                    pathname.includes("/plans")
                      ? "sidebar-item-active"
                      : "sidebar-item"
                  }
                >
                  <Gem />
                  <span>Membership</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/admin"
                  className={
                    pathname === "/admin/change-password" ||
                      pathname.includes("/admin/change-password/")
                      ? "sidebar-item-active"
                      : "sidebar-item"
                  }
                >
                  <TbPasswordUser className="size-6"/>
                  <span>Change Password</span>
                </Link>
              </li> */}
            </ul>
          </div>
        </>
      ) : (
        <>
          {isMainuser && (
            <div className="mb-1.5 md:mb-6" s>
              <DropdownMenu>
                <DropdownMenuTrigger className="hidden md:flex w-max p-4 items-center gap-x-3 outline-none text-text bg-bg2 rounded-2xl cursor-pointer hover:bg-bg2-hover transition-all">
                  <Plus />
                  <span className="text-sm">New</span>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  side="right"
                  align="start"
                  className="min-w-[300px] bg-bg2 text-text border-0"
                >
                  <DropdownMenuItem
                    className="flex items-center gap-x-4"
                    onSelect={() =>
                      setIsModal((prevState) => ({
                        ...prevState,
                        open: true,
                      }))
                    }
                  >
                    <FolderPlus className="w-5" />
                    <span>New Folder</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}

          <div className="block md:hidden">
            <Link to="profile">
              <Avatar>
                <AvatarImage src={usericon} />
                <AvatarFallback className="uppercase">
                  {name ? name.slice(0, 2) ?? "" : ""}
                </AvatarFallback>
              </Avatar>
            </Link>
            <p className="mt-2 text-text2">{email ?? ""}</p>
            <hr className="-mx-4 mt-4" />
          </div>

          <div className="h-full flex flex-col justify-between">
            <div>
              <ul className="text-text space-y-2 md:space-y-1">
                <li>
                  <Link
                    to="dispatch"
                    className={
                      pathname.includes("/dispatch")
                        ? "sidebar-item-active"
                        : "sidebar-item"
                    }
                  >
                    <Truck />
                    <span>My Dispatch</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="return"
                    className={
                      pathname.includes("/return")
                        ? "sidebar-item-active"
                        : "sidebar-item"
                    }
                  >
                    <Undo2 />
                    <span>My Return</span>
                  </Link>
                </li>
                {isMainuser && (
                  <li>
                    <Link
                      to="manage-subusers"
                      className={
                        pathname.includes("/manage-subusers")
                          ? "sidebar-item-active"
                          : "sidebar-item"
                      }
                    >
                      <Users />
                      <span>Manage Subusers</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to="starred"
                    className={
                      pathname.includes("/starred")
                        ? "sidebar-item-active"
                        : "sidebar-item"
                    }
                  >
                    <Star />
                    <span>Starred</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="barcode"
                    className={
                      pathname.includes("/barcode")
                        ? "sidebar-item-active"
                        : "sidebar-item"
                    }
                  >
                    <QrCode />
                    <span>Barcodes</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="trash"
                    className={
                      pathname.includes("/trash")
                        ? "sidebar-item-active"
                        : "sidebar-item"
                    }
                  >
                    <Trash2 />
                    <span>Trash</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="history"
                    className={
                      pathname.includes("/history")
                        ? "sidebar-item-active"
                        : "sidebar-item"
                    }
                  >
                    <History />
                    <span>History</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="storage"
                    className={
                      pathname.includes("/storage")
                        ? "sidebar-item-active"
                        : "sidebar-item"
                    }
                  >
                    <Database />
                    <span>Storage</span>
                  </Link>
                </li>

                <li className="pt-1">
                  <div className="mx-4 h-1 rounded-full bg-text3 relative">
                    <div
                      style={{ width: totalUsedStoragePercentage + "%" }}
                      className="absolute h-full rounded-full bg-red-500 left-0 transition-all"
                    ></div>
                  </div>
                  <p className="px-4 mt-1 text-sm font-lato">
                    {formatFileSize(totalUsedStorage, 2)} of{" "}
                    {formatFileSize(totaleStorage, 2)} used
                  </p>
                </li>
                <li className="pt-6 text-center">
                  {/* <Link > */}
                  <Badge
                    className={twMerge(
                      "px-4 py-2 border-2 bg-bg-primary border-[#747775] text-lg font-lato rounded-full cursor-pointer text-primary  hover:bg-[#e5ecf9]"
                    )}
                    onClick={() => { setCreditModal({ open: true, data: null }) }}
                  >
                    Credits balance
                  </Badge>
                  {/* </Link> */}
                </li>
                <li className="pt-2 text-center">
                  <Link to="get-storage">
                    <Badge
                      className={twMerge(
                        "px-4 py-2 border-2 bg-bg-primary border-[#747775] text-lg font-lato cursor-pointer text-primary",
                        pathname.includes("/get-storage")
                          ? "bg-[#e5ecf9] hover:bg-[#e5ecf9]"
                          : "bg-bg-primary hover:bg-[#e5ecf9]"
                      )}
                    >
                      Get more storage
                    </Badge>
                  </Link>
                </li>

                <li className="pt-2 text-center">
                  <Link to="get-credits">
                    <Badge
                      className={twMerge(
                        "px-4 py-2 border-2 bg-bg-primary border-[#747775] text-lg font-lato cursor-pointer text-primary",
                        pathname.includes("/get-credits")
                          ? "bg-[#e5ecf9] hover:bg-[#e5ecf9]"
                          : "bg-bg-primary hover:bg-[#e5ecf9]"
                      )}
                    >
                      Get more credits
                    </Badge>
                  </Link>
                </li>

                <li className="pt-2 text-center">
                  <a
                    target="_new"
                    href="https://drive.usercontent.google.com/download?id=1a5AzFJxLyjMYrX6UZu2lnqQ96Ifjw-aQ&export=download&authuser=0"
                  >
                    <Badge className="px-4 py-2 gap-x-2 border-2 bg-bg-primary border-[#747775] text-lg font-lato cursor-pointer text-primary">
                      Download APK
                      <DownloadCloud className="w-6 h-6" />
                    </Badge>
                  </a>
                </li>
              </ul>
            </div>

            <div className="mt-[80px]">
              <hr className="-mx-4 mt-1.5" />
              <div className="mt-1.5 text-text space-y-2 md:space-y-1">
                <a
                  target="_blank"
                  href="https://www.vidscantech.com/pages/how-it-works"
                  className="py-2 md:py-1.5 px-3 rounded-full flex items-center gap-x-4 text-sm font-medium font-lato cursor-pointer hover:bg-bg2-hover text-black transition-all"
                >
                  <span className="text-inherit">How It Works</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.vidscantech.com/pages/features"
                  className="py-2 md:py-1.5 px-3 rounded-full flex items-center gap-x-4 text-sm font-medium font-lato cursor-pointer hover:bg-bg2-hover text-black transition-all"
                >
                  <span className="text-inherit">Features</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.vidscantech.com/pages/success-stories"
                  className="py-2 md:py-1.5 px-3 rounded-full flex items-center gap-x-4 text-sm font-medium font-lato cursor-pointer hover:bg-bg2-hover text-black transition-all"
                >
                  <span className="text-inherit">Success Stories</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.vidscantech.com/pages/pricing"
                  className="py-2 md:py-1.5 px-3 rounded-full flex items-center gap-x-4 text-sm font-medium font-lato cursor-pointer hover:bg-bg2-hover text-black transition-all"
                >
                  <span className="text-inherit">Pricing</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.vidscantech.com/pages/faq"
                  className="py-2 md:py-1.5 px-3 rounded-full flex items-center gap-x-4 text-sm font-medium font-lato cursor-pointer hover:bg-bg2-hover text-black transition-all"
                >
                  <span className="text-inherit">FAQ</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.vidscantech.com/pages/privacy-policy"
                  className="py-2 md:py-1.5 px-3 rounded-full flex items-center gap-x-4 text-sm font-medium font-lato cursor-pointer hover:bg-bg2-hover text-black transition-all"
                >
                  <span className="text-inherit">Privacy Policy</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.vidscantech.com/pages/terms-and-conditions"
                  className="py-2 md:py-1.5 px-3 rounded-full flex items-center gap-x-4 text-sm font-medium font-lato cursor-pointer hover:bg-bg2-hover text-black transition-all"
                >
                  <span className="text-inherit">Terms and Conditions</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.vidscantech.com/pages/contact-us"
                  className="py-2 md:py-1.5 px-3 rounded-full flex items-center gap-x-4 text-sm font-medium font-lato cursor-pointer hover:bg-bg2-hover text-black transition-all"
                >
                  <span className="text-inherit">Contact Us</span>
                </a>
              </div>

              {/* <hr className="-mx-4 mt-1.5" />
                <ul className="mt-1.5 text-text space-y-2 md:space-y-1">
                    <li>
                        <Link to="return" className="sidebar-item">
                            <span>Get more storage</span>
                        </Link>
                    </li>
                </ul> */}
            </div>
          </div>

          {/* AddNewFolder  */}

          <Dialog open={modal.open} onOpenChange={handleClose}>
            <DialogContent
              closeIcon={false}
              className="w-full max-w-[400px] bg-bg-primary"
            >
              <form
                onSubmit={folder.handleSubmit(onSubmit)}
                className="flex flex-col gap-y-6"
              >
                <div className="flex flex-col gap-y-4">
                  <Label className="text-text text-2xl">New Folder</Label>
                  <Controller
                    name="parent_folder"
                    control={folder.control}
                    render={({ field }) => {
                      return (
                        <Select
                          defaultValue={dispatchParent._id}
                          onValueChange={field.onChange}
                        >
                          <SelectTrigger className="w-full bg-bg2-hover text-text1">
                            <SelectValue placeholder="Theme" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={dispatchParent._id}>
                              Dispatch
                            </SelectItem>
                            <SelectItem value={returnParent._id}>
                              Return
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      );
                    }}
                  />

                  <Input
                    {...folder.register("displayName")}
                    className={`py-5 border ${folder.formState.errors.displayName &&
                      "text-red-400 placeholder:text-red-600 border-red-400"
                      }`}
                    autoComplete="off"
                    placeholder="Enter folder name"
                  />
                </div>
                <div className="flex justify-end gap-x-3">
                  <Button type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit">Create</Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>

          {/* credit modal */}
          <CreditModal modal={creditModal} setModal={setCreditModal} />
        </>
      )}
    </aside>
  );
};

export default Sidebar;
