import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";
import { useSelector } from "react-redux";
import { privateAxios } from "../../lib/axios";
import { SHARE_CSV_DETAIL, SHARE_CSV_FILE } from "../../constant/endpoints";
import { toast } from "react-toastify";

const ShareCSV = ({ modal, setModal }) => {
  const { _id: userID, email } = useSelector(
    (state) => state.userAuthReducer.auth
  );
  const [emails, setEmails] = useState("");
  const [selectedValue, setSelectedValue] = useState(6);

  const handleShareCSV = async (payload) => {
    const response = await privateAxios(SHARE_CSV_FILE, payload);

    if (response?.ResponseCode == 1 && response?.Result == "True") {
      toast.success(response?.ResponseMsg);
      setModal({ open: false, data: null });
    } else {
      toast.error(response?.ResponseMsg);
    }
  };

  const getCsvDetails = async () => {
    const response = await privateAxios(SHARE_CSV_DETAIL, {
      user_id: userID,
      folder_id: modal?.data?._id,
    });

    if (response?.ResponseCode == 1 && response?.Result == "True") {
      setEmails(response?.data?.folderDetail?.emails?.join(", "));
      setSelectedValue(parseInt(response?.data?.folderDetail?.time));
    }
  };

  useEffect(() => {
    if (modal?.open == true) {
      getCsvDetails();
    }
  }, [modal?.open]);

  return (
    <Dialog
      open={modal.open}
      onOpenChange={(open) => setModal({ open, data: null })}
    >
      <DialogContent className="px-0 flex flex-col overflow-hidden">
        <h5 className="text-2xl px-4">Share CSV</h5>
        <div className="px-5 space-y-3">
          <div className="flex flex-col space-y-0.5">
            <label className="text-base font-normal">Enter Email Address</label>
            <Input
              name="emails"
              placeholder="Enter emails "
              type="text"
              onChange={(e) => {
                setEmails(e.target.value);
              }}
              value={emails}
            />
          </div>
          <div className="flex flex-col space-y-0.5">
            <label className="text-base font-normal">Enter Email Address</label>
            <Select value={selectedValue} onValueChange={setSelectedValue}>
              <SelectTrigger className="bg-bg2  ">
                <SelectValue placeholder="Select condition" />
              </SelectTrigger>
              <SelectContent className="bg-bg2">
                <SelectGroup>
                  <SelectLabel>Condition</SelectLabel>
                  <SelectItem value={6}>6</SelectItem>
                  <SelectItem value={12}>12</SelectItem>
                  <SelectItem value={24}>24</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-end gap-x-3 pt-5">
            <Button
              type="button"
              onClick={() => setModal({ open: false, data: null, mode: null })}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                handleShareCSV({
                  email: emails,
                  time: selectedValue,
                  user_id: userID,
                  folder_id: modal?.data?._id,
                });
              }}
              //   onClick={() => {
              //     updateCondition({
              //       videoId: modal?.data?._id,
              //       quality: selectedValue,
              //     });
              //   }}
            >
              Go
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareCSV;
