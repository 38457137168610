import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Dialog, DialogContent } from "../ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { privateAxios } from "../../lib/axios";
import { GETIFOLDERDETAILS, GETSTARREDLIST, MOVEFOLDER } from "../../constant/endpoints";
import { useState } from "react";
import { useMemo } from "react";
import { ArrowLeft, ChevronRight } from "lucide-react";
import Folder from "./Folder";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { toastHandler } from "../../lib/helper";
import { resetRowSelection } from "../../redux/reducers/rowSlice";

const MoveFolder = ({ modal, setModal }) => {
  const dispatchParent = useSelector((state) => state.intialFoldersReducer.dispatch);
  const returnParent = useSelector((state) => state.intialFoldersReducer.return);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const starredInitialData = useMemo(() => {
    if (Array.isArray(modal.data)) {
      if (modal.data.every((values) => values.type === "Dispatch"))
        return { id: dispatchParent._id, name: "Dispatch" };
      if (modal.data.every((values) => values.type === "Return"))
        return { id: returnParent._id, name: "Return" };
      return { id: null, name: "Invalid Folder's Selected" };
    } else {
      return modal.data.type === "Dispatch"
        ? { id: dispatchParent._id, name: "Dispatch" }
        : { id: returnParent._id, name: "Return" };
    }
  }, []);

  const initialFolderId = useMemo(
    () =>
      pathname.includes("/dispatch")
        ? { id: dispatchParent._id, name: "Dispatch" }
        : pathname.includes("/return")
          ? { id: returnParent._id, name: "Return" }
          : pathname.includes("/starred")
            ? starredInitialData
            : "",
    [pathname]
  );

  const [isStarredTab, setIsStarredTab] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 1000 });
  const [folderId, setFolderId] = useState(initialFolderId.id);
  const [navHistory, setNavHistory] = useState([
    { _id: initialFolderId.id, displayName: initialFolderId.name },
  ]);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const {
    data: { data = [] },
    isFetched,
    isLoading: folderDetailsLoading,
  } = useQuery({
    queryKey: [GETIFOLDERDETAILS, folderId],
    queryFn: () =>
      privateAxios(
        GETIFOLDERDETAILS,
        { parent_folder: folderId },
        null,
        pagination.page,
        pagination.limit
      ),
    onSuccess: (res) => {
      return setPagination((prevState) => ({
        ...prevState,
        totoalPage: Math.ceil(res.totalRecords / prevState.limit),
      }));
    },
    enabled: !!initialFolderId?.id,
    initialData: [],
  });

  const { data: starred, isLoading: isStarredLoading } = useQuery({
    queryKey: [GETSTARREDLIST],
    queryFn: () =>
      privateAxios(
        GETSTARREDLIST,
        null,
        null,
        pagination.page,
        pagination.limit
      ),
    onSuccess: (res) => {
      setPagination((prevState) => ({
        ...prevState,
        totoalPage: Math.ceil(res.totalRecords / prevState.limit),
      }));
      // return setPagination((prevState) => ({
      //   ...prevState,
      //   totoalPage: Math.ceil(res.data.reduce((acc, curr) => (curr.isFolder === "1" && acc++, 1)) / pagination.limit, []),
      // }));
    },
    initialData: [],
  });

  const starredData = starred?.data ?? [];

  const { mutate: moveFolder } = useMutation({
    mutationFn: (values) => privateAxios(MOVEFOLDER, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
      }
      return toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => {
      setModal({ open: false, data: null });
      setFolderId(initialFolderId.id);
      dispatch(resetRowSelection());
    },
  });

  const folders = useMemo(() => {
    if (modal.data && Array.isArray(modal.data)) {
      return data.filter((values) => {
        const isIn = modal.data.find((folder) => values._id === folder._id);
        return isIn ? false : true;
      });
    } else {
      return data;
    }
  }, [data]);

  const starredFolder = useMemo(() => {
    if (modal.data && Array.isArray(modal.data)) {
      return starredData.filter((values) => {
        const isIn = modal.data.find(
          (folder) => values.type !== folder.type || values._id === folder._id
        );
        return isIn ? false : true;
      });
    } else {
      if (pathname.includes("/return") || pathname.includes("/dispatch")) {
        return starredData.filter(
          (values) => initialFolderId.name === values.type
        );
      } else {
        return starredData.filter((values) => values.type !== modal.data.type);
      }
    }
  }, [starredData]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, offsetHeight } = e.target;
    if (
      scrollTop >= scrollHeight - offsetHeight - 103 &&
      pagination.totoalPage > pagination.page
    ) {
      setPagination((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  };

  const handleFolderClicked = (values) => {
    const { _id, displayName } = values;
    setNavHistory((prevState) => [...prevState, { _id, displayName }]);
    setFolderId(_id);
  };

  const handleNavigate = (values, index) => {
    setFolderId(values._id);
    setNavHistory((prevState) => prevState.slice(0, index + 1));
  };

  const handleBack = () => {
    setNavHistory((prevState) => {
      const slicedArr = prevState.slice(0, prevState.length - 1);
      const { _id } = slicedArr[slicedArr.length - 1];
      setFolderId(_id);
      return slicedArr;
    });
  };

  return (
    <Dialog
      open={modal.open}
      onOpenChange={(open) => setModal({ open, data: null })}
    >
      <DialogContent className="px-0 flex flex-col overflow-hidden">
        <h5 className="text-2xl px-4">
          Move
          {Array.isArray(modal.data)
            ? `"${modal.data.length} selected"`
            : `"${modal.data.displayName}"`}
        </h5>
        <Tabs
          defaultValue="suggested"
          className="flex flex-col"
          onValueChange={(e) => {
            setPagination({ page: 1, limit: 25 });
            setIsStarredTab(e === "starred");
          }}
        >
          {navHistory.length > 1 ? (
            <div className="px-2 py-1 font-medium text-text flex items-center gap-x-2">
              <span
                className="p-1 cursor-pointer rounded-full hover:bg-bg2-hover transition-all"
                onClick={handleBack}
              >
                <ArrowLeft className="w-6 h-6" />
              </span>
              <span>{navHistory[navHistory.length - 1].displayName}</span>
            </div>
          ) : (
            <div className="px-4">
              <TabsList className="p-0 bg-transparent">
                <TabsTrigger
                  className="p-0 pr-3 border-r-2 data-[state=active]:text-primary data-[state=active]:shadow-none"
                  value="suggested"
                >
                  Suggested
                </TabsTrigger>
                <TabsTrigger
                  className="p-0 pl-3 data-[state=active]:text-primary data-[state=active]:shadow-none"
                  value="starred"
                >
                  Starred
                </TabsTrigger>
              </TabsList>
            </div>
          )}

          <TabsContent
            value="suggested"
            className="mt-0 py-2 border-y-2 overflow-y-auto"
          >
            {folderDetailsLoading ? (
              <Loader />
            ) : data.length > 0 ? (
              folders.length ? (
                <ScrollArea handleScroll={handleScroll} className="h-[300px]">
                  <Folder
                    folders={folders}
                    handleFolderClicked={handleFolderClicked}
                    moveAbleFolder={modal.data}
                    moveFolder={moveFolder}
                  />
                </ScrollArea>
              ) : (
                <div className="h-[300px] flex justify-center items-center">
                  <h6 className="text-lg text-text2">
                    {initialFolderId.id
                      ? "No parent folder found"
                      : "Please select one type of folder (Return or Dispatch)"}
                  </h6>
                </div>
              )
            ) : (
              <div className="h-[300px] flex justify-center items-center">
                <h6 className="text-lg text-text2">
                  {initialFolderId.id
                    ? "This folder is empty"
                    : "Please select one type of folder (Return or Dispatch)"}
                </h6>
              </div>
            )}
          </TabsContent>
          <TabsContent
            value="starred"
            className="mt-0 py-2 border-y-2 overflow-y-auto"
          >
            {isStarredLoading ? (
              <Loader />
            ) : (navHistory.length > 1 ? data.length : starredData.length) ? (
              (
                navHistory.length > 1 ? folders.length : starredFolder.length
              ) ? (
                <ScrollArea className="h-[300px]">
                  <Folder
                    folders={navHistory.length > 1 ? folders : starredFolder}
                    handleFolderClicked={handleFolderClicked}
                    moveAbleFolder={modal.data}
                    moveFolder={moveFolder}
                  />
                </ScrollArea>
              ) : (
                <div className="h-[300px] flex justify-center items-center">
                  <h6 className="text-lg text-text2">No parent folder found</h6>
                </div>
              )
            ) : (
              <div className="h-[300px] flex justify-center items-center">
                <h6 className="text-lg text-text2">This folder is empty</h6>
              </div>
            )}
          </TabsContent>
        </Tabs>

        <div className="flex flex-col gap-y-2">
          <div className="px-4 flex gap-x-1 overflow-x-auto">
            {navHistory.map((values, index) => {
              return (
                <div
                  key={values._id}
                  className="flex items-center gap-x-1"
                  onClick={() => handleNavigate(values, index)}
                >
                  <span className="text-sm cursor-pointer border-b-2 border-transparent hover:border-b-bg2-hover transition-all">
                    {isStarredTab && index === 0
                      ? "Starred"
                      : values.displayName}
                  </span>
                  {navHistory.length - 1 !== index && (
                    <ChevronRight className="w-4 h-4 mb-[2.5px]" />
                  )}
                </div>
              );
            })}
          </div>
          <div className="px-4 flex items-center justify-end">
            <Button
              className="bg-transparent text-black hover:bg-transparent"
              onClick={() => setModal({ open: false, data: null })}
            >
              Cancel
            </Button>
            <Button
              className="px-6 rounded-full disabled:cursor-not-allowed"
              disabled={
                Array.isArray(modal.data)
                  ? modal.data.some(
                    (values) => values.parentFolder === folderId
                  )
                  : modal.data.parentFolder === folderId
              }
              onClick={() =>
                moveFolder({
                  folder_ids: Array.isArray(modal.data)
                    ? modal.data.map(({ _id }) => _id)
                    : [modal.data._id],
                  new_parent_folder: folderId,
                })
              }
            >
              Move
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MoveFolder;
