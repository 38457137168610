import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import {
  Share2,
  Star,
  ArrowDownToLine,
  Info,
  Link2,
  Pencil,
  Trash2,
  FolderInput,
  Eye,
} from "lucide-react";
import { privateAxios } from "../lib/axios";
import {
  GETIFOLDERDETAILS,
  GETSTARREDLIST,
  MOVETOTRASH,
} from "../constant/endpoints";
import { getPageName, toastHandler } from "../lib/helper";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import { useDispatch } from "react-redux";
import { setDetails } from "../redux/reducers/detailsSlice";
import { resetRowSelection } from "../redux/reducers/rowSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShareBtn from "./ShareBtn";
import usePaths from "../hooks/usePaths";

const ActionDropdown = ({
  children,
  data,
  setRenameFolder,
  handleStar,
  setMoveFolder,
  setShareCsvOpen,
  handlePreview,
  handleDownload,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { id } = useParams();

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(MOVETOTRASH, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        pathname.includes("/starred")
          ? queryClient.invalidateQueries({ queryKey: [GETSTARREDLIST] })
          : queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
      }
      return toastHandler(res);
    },
    onError: (err) => toast.error(err),
  });

  const { isMainuser } = usePaths();

  const handleFolderClicked = (item) => {
    dispatch(resetRowSelection());

    let path = pathname.split("/");

    if (path.includes(id)) {
      path = path
        .map((values) => (values === id ? item._id : values))
        .join("/");
    } else {
      path.push(item._id);
      path = path.join("/");
    }

    item.isFolder === "1" &&
      navigate(path, {
        state: {
          from: state?.from
            ? [
              ...state.from,
              {
                id: item._id,
                name: item.displayName,
              },
            ]
            : [
              {
                id: item._id,
                name: item.displayName,
              },
            ],
        },
      });
  };

  const getShareUrl = (values) => {
    if (values.isFolder === "0") {
      return values.path;
    } else {
      const pageName = getPageName(pathname);
      const _id = values?._id || "";
      return window.location.origin + "/".concat(pageName, "/", _id);
    }
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger className="outline-none">
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="left"
        align="start"
        className="min-w-[280px] bg-bg2 rounded-lg text-text border-none"
      >
        <DropdownMenuItem
          className="gap-x-5"
          onSelect={() =>
            data.isFolder === "1"
              ? handleFolderClicked(data)
              : handlePreview(data)
          }
        >
          <Eye className="w-4 h-4" />
          <span>View</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {isMainuser && (
          <DropdownMenuItem
            className="gap-x-5"
            onSelect={() => setRenameFolder({ isOpen: true, data })}
          >
            <Pencil className="w-4 h-4" />
            <span>Rename</span>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          className="gap-x-5"
          onSelect={() => handleStar({ folder_id: data._id })}
        >
          <Star
            className={twMerge(
              "w-4 h-4",
              data?.isStarred === "1" && "text-[#ffbc37] fill-[#ffbc37]"
            )}
          />
          <span>
            {data?.isStarred === "1" ? "Remove from starred" : "Add to starred"}
          </span>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <ShareBtn
            url={getShareUrl(data)}
            className="px-2 py-[6px] cursor-pointer hover:bg-bg2-hover"
          >
            <Share2 className="w-4 h-4" />
            <span>Share</span>
          </ShareBtn>
        </DropdownMenuItem>
        {
          data.isFolder === "1" && <DropdownMenuItem asChild onSelect={() => setShareCsvOpen({ open: true, data })}>
            <button
              // url={getShareUrl(data)}
              className="px-2 py-[6px] cursor-pointer hover:bg-bg2-hover flex items-center gap-x-5"
            // onClick={() => handleShareCsv(data)}

            >
              <Share2 className="w-4 h-4" />
              <span>Share CSV</span>
            </button>
          </DropdownMenuItem>
        }
        {/* <DropdownMenuItem
          className="gap-x-5"
          onSelect={() => handleCopyLink(data)}
        >
          <Link2 className="w-4 h-4" />
          <span>Get link</span> 
        </DropdownMenuItem> */}
        {isMainuser && (
          <DropdownMenuItem
            className="gap-x-5"
            onSelect={() => setMoveFolder({ open: true, data })}
          >
            <FolderInput className="w-4 h-4" />
            <span>Move</span>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          className="gap-x-5"
          onSelect={() => dispatch(setDetails(data))}
        >
          <Info className="w-4 h-4" />
          <span>File info</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="gap-x-5"
          onSelect={() => handleDownload(data, 0)}
        >
          <ArrowDownToLine className="w-4 h-4" />
          <span>Download</span>
        </DropdownMenuItem>
        {isMainuser && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="gap-x-5"
              onSelect={() => mutate({ folder_ids: [data._id] })}
            >
              <Trash2 className="w-4 h-4" />
              <span>Remove</span>
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ActionDropdown;
