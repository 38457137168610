import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    selectedRows: [],
    selectedRowsID: []
}

const rowSlice = createSlice({
    name: "selectRow",
    initialState,
    reducers: {
        setSelectedRows: (state, { payload }) => {
            state.selectedRows.push(payload)
            state.selectedRowsID.push(payload?._id);
        },
        removeSelectedRows: (state, { payload }) => {
            state.selectedRows = state.selectedRows.filter(rows => rows._id !== payload._id)
            state.selectedRowsID = state.selectedRowsID.filter(rows => rows !== payload._id)
        },
        resetRowSelection: () => {
            return initialState
        }
    }
})

export const { setSelectedRows, removeSelectedRows, resetRowSelection } = rowSlice.actions

export default rowSlice.reducer