import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Navigate, useNavigate } from "react-router-dom";
import { loginSchema } from "../../lib/schemas";
import Logo from "../../assets/images/logoWithSlogan.jpg";
import { useDispatch, useSelector } from "react-redux";
import { publibAxios } from "../../lib/axios";
import { useMutation } from "@tanstack/react-query";
import { toastHandler } from "../../lib/helper";
import { ADMINLOGIN } from "../../constant/endpoints";
import {
  setIsAuthenticated,
  setUserAuth,
} from "../../redux/reducers/adminAuthSlice";
import isEmail from "validator/lib/isEmail";
import md5 from "md5";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.adminAuthReducer.isAuthenticated);

  const form = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate } = useMutation({
    mutationFn: (values) => publibAxios(ADMINLOGIN, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        localStorage.setItem("adminToken", res.data.token);
        dispatch(setIsAuthenticated(true));
        dispatch(setUserAuth(res.data));
        navigate("/admin");
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const onSubmit = (values) => {
    const { email, password } = values;

    const body = {
      password: md5(password),
      [isEmail(email) ? "email" : "phone"]: email,
    };

    return mutate(body);
  };

  return isAuthenticated ? (
    <Navigate to="/admin" />
  ) : (
    <div className="w-full max-w-sm h-screen mx-auto flex flex-col justify-center">
      <div className="space-y-10">
        <div className="text-center space-y-6">
          <div className="max-w-[350px] mx-auto">
            <img src={Logo} alt="logo" />
          </div>
          <h1 className="text-primary text-2xl font-lato font-semibold">
            Sign in to Admin Vidscan Tech
          </h1>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full h-max space-y-8"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Mobile number or email
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter your mobile number or email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Password
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter your password"
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full font-lato font-bold text-lg bg-primary"
            >
              Sign In
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};
export default Login;
