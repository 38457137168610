import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalPage: 0,
  page: 1,
  limit: 100,
  search: undefined,
  start: undefined,
  end: undefined,
  selectedYear: undefined,
};

const filterSlice = createSlice({
  name: "Filters",
  initialState,
  reducers: {
    setTotalPage: (state, { payload }) => {
      state.totalPage = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setStart: (state, { payload }) => {
      state.start = payload;
    },
    setEnd: (state, { payload }) => {
      state.end = payload;
    },
    setSelectedYear: (state, { payload }) => {
      state.selectedYear = payload;
    },
    resetFilter: () => initialState,
  },
});

export const {
  setTotalPage,
  setLimit,
  setPage,
  setSearch,
  setStart,
  setEnd,
  setSelectedYear,
  resetFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
