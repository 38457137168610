import { useQueryClient } from "@tanstack/react-query";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { STORAGE } from "../../constant/endpoints";

ChartJS.register(ArcElement, Tooltip, Legend);

const Analytics = () => {
  const { storage = 1, credits = 0, totalDispatchCredits = 0, totalReturnCredits = 0, totalDispatchInvoices = 0, totalReturnInvoices = 0, } = useSelector((state) => state.userAuthReducer.auth);
  const { selectedYear } = useSelector((data) => data.filterReducer);
  const queryClient = useQueryClient();

  const { data } = queryClient.getQueryData({ queryKey: [STORAGE] }) ?? {};

  const { totalDispatch, totalReturn } = useMemo(
    () => ({
      totalDispatch: data.find((values) => values._id === "Dispatch"),
      totalReturn: data.find((values) => values._id === "Return"),
    }), [data]);

  const totalUsedStorage = useMemo(() => Number(totalDispatch.storage) + Number(totalReturn.storage), [data]);

  const { totalDispatchPercentage, totalReturnPercentage } = useMemo(
    () => ({
      totalDispatchPercentage: (Number(totalDispatch.storage) * 100) / Number(storage),
      totalReturnPercentage: (Number(totalReturn.storage) * 100) / Number(storage),
    }), [data]);

  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  const creditChart = {
    labels: ["Return", "Dispatch", "Total Credits"],
    datasets: [
      {
        label: "Items",
        data: [Number(totalReturnCredits), Number(totalDispatchCredits), Number(credits)],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const invoiceChart = {
    labels: ["Return", "Dispatch", "Total Invoices"],
    datasets: [
      {
        label: "Invoices",
        data: [Number(totalReturnInvoices), Number(totalDispatchInvoices), (Number(totalReturnInvoices) + Number(totalDispatchInvoices)) ?? 0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const storageChart = {
    labels: ["Dispatch", "Return", "Total"],
    datasets: [
      {
        label: "Storage",
        data: [totalDispatchPercentage, totalReturnPercentage, 100],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
      <div className="w-full max-w-[300px] md:max-w-[360px] mx-auto space-y-4">
        <h4 className="text-text3 text-xl text-center">Credits</h4>
        <Pie data={creditChart} options={options} />
      </div>
      <div className="w-full max-w-[300px] md:max-w-[360px] mx-auto space-y-4">
        <h4 className="text-text3 text-xl text-center">Invoices</h4>
        <Pie data={invoiceChart} options={options} />
      </div>
      <div className="w-full max-w-[300px] md:max-w-[360px] mx-auto space-y-4">
        <h4 className="text-text3 text-xl text-center">Storage</h4>
        <Pie data={storageChart} options={options} />
      </div>
    </div>
  );
};

export default Analytics;
