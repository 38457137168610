import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader";
import { Fragment, useCallback, useMemo, useState } from "react";
import Sidebar from "../../components/user/Sidebar/Sidebar";
import Header from "../../components/user/Header";
import { BsFiletypeCsv } from "react-icons/bs";
import {
  ArrowDownToLine,
  ChevronDown,
  ChevronRight,
  FileImage,
  FileVideo,
  FolderIcon,
  FolderInput,
  FolderPlus,
  FolderSync,
  Link2,
  Minus,
  Share2,
  Trash2,
  UserPlus2,
  X,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Badge } from "../../components/ui/badge";
import { Calendar } from "../../components/ui/calendar";
import { twMerge } from "tailwind-merge";
import { useDispatch, useSelector } from "react-redux";
import { resetRowSelection } from "../../redux/reducers/rowSlice";
import ScrollContainer from "react-indiana-drag-scroll";
import { Button } from "../../components/ui/button";
import {
  addDays,
  endOfYear,
  format,
  formatISO,
  getDate,
  getYear,
  isSameMonth,
  isSameYear,
  parseISO,
  startOfYear,
  subDays,
} from "date-fns";
import { enUS, fi } from "date-fns/locale";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CREATEFOLDER,
  CREATESUBUSER,
  DELETEFOREVER,
  DOWNLOAD_VIDEO,
  DOWNLOADCSV,
  GETIFOLDERDETAILS,
  GETINITIALFOLDER,
  GETSUBUSERS,
  GETTRASHLIST,
  MOVETOTRASH,
  RESTOREFOLSER,
} from "../../constant/endpoints";
import { privateAxios } from "../../lib/axios";
import {
  dowloadCSV,
  downloadMultipleFiles,
  formatFileSize,
  getPageName,
  isImage,
  isVideo,
  toastHandler,
} from "../../lib/helper";
import {
  setDispatch,
  setReturn,
} from "../../redux/reducers/intialFoldersSlice";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addNewSubuser, createNewFolder } from "../../lib/schemas";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../components/ui/command";
import countyCode from "../../lib/countrycode.json";
import { setDetails } from "../../redux/reducers/detailsSlice";
import { useEffect } from "react";
import {
  setEnd,
  setStart,
  setSelectedYear,
} from "../../redux/reducers/filterSlice";
import MoveFolder from "../../components/modals/MoveFolder";
import ShareBtn from "../../components/ShareBtn";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import usePaths from "../../hooks/usePaths";
import md5 from "md5";
import VideoQuality from "../../components/modals/VideoQuality";
import axios from "axios";

const Layout = () => {
  const dispatch = useDispatch();
  const { selectedRows, selectedRowsID } = useSelector((data) => data.rowReducer);
  const returnParent = useSelector((data) => data.intialFoldersReducer.return);
  const dispatchParent = useSelector(
    (data) => data.intialFoldersReducer.dispatch
  );
  const fileDetail = useSelector((data) => data.detailsReducer.details);
  const { selectedYear } = useSelector((data) => data.filterReducer);

  const [filter, setFilter] = useState("Select Date");
  const [isDateFilter, setIsDateFilter] = useState(false);
  const [isAddDropdown, setIsAddDropdown] = useState(false);
  const [isShowDates, setIsShowDates] = useState(false);
  const [date, setDate] = useState({ from: null, to: null });
  const [modal, setIsModal] = useState({ open: false, name: null });
  const [isCountrySelector, seIsCountrySelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const [moveFolder, setMoveFolder] = useState({ open: false, data: null });
  const [isCSVDowloading, setIsCSVDowloading] = useState(false);
  const [qualityVal, setQualityVal] = useState('')
  const [videoQualityModal, setVideoQualityModal] = useState({
    open: false,
    data: null,
    mode: null,
  })

  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [, copyToClipboard] = useCopyToClipboard();

  const queryClient = useQueryClient();

  const defaultYear = new Date().getFullYear();

  const { PATHS } = usePaths();

  useEffect(() => {
    dispatch(setDetails(null));
    handleClearAllFilter();
  }, [pathname]);

  useQuery({
    queryKey: [GETINITIALFOLDER],
    queryFn: () => privateAxios(GETINITIALFOLDER, null, "GET"),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        return res.data.initialFolder.forEach((obj) =>
          obj.displayName === "Dispatch"
            ? dispatch(setDispatch(obj))
            : dispatch(setReturn(obj))
        );
      } else {
        return navigate("/login");
      }
    },
    onError: (err) => {
      toast.error(err);
    },
    staleTime: Infinity,
    enabled:
      pathname.includes("/return") ||
      (pathname.includes("/dispatch") && !pathname.includes("/admin")),
  });

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(CREATEFOLDER, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
      toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => {
      folder.reset();
      setIsModal((prevState) => ({ ...prevState, open: false, name: 'folder' }));
    },
  });

  const { mutate: handleRestoreTrash } = useMutation({
    mutationFn: (values) => privateAxios(RESTOREFOLSER, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [GETTRASHLIST] });
      toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => dispatch(resetRowSelection()),
  });

  const { mutate: addSubuser } = useMutation({
    mutationFn: (values) => privateAxios(CREATESUBUSER, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [GETSUBUSERS],
      });
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
    onSettled: () => {
      folder.reset();
      setIsModal({ open: false, name: null });
      setSelectedCountry("+91");
    },
  });

  const { mutate: download } = useMutation({
    mutationFn: (values) => privateAxios(DOWNLOADCSV, values),
    onSuccess: async (res) => {
      setIsCSVDowloading(true);
      try {
        const message = await dowloadCSV(res.data);
        toast.success(message);
      } catch (err) {
        toast.error(err);
      } finally {
        setIsCSVDowloading(false);
        dispatch(resetRowSelection());
      }
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const { mutate: downloadZip } = useMutation({
    mutationFn: (values) => privateAxios(DOWNLOAD_VIDEO, values),
    onSuccess: async (res) => {
      setIsCSVDowloading(true);
      try {
        const zipUrl = res?.data?.zipUrl;
        if (!zipUrl) {
          throw new Error('No zip URL found in the response');
        }

        window.open(zipUrl, '_blank');

        // Download the zip file using the correct URL (zipUrl)
        // const downloadResponse = await axios.get(zipUrl, { responseType: 'blob' });
        // const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // // Get the file name from the URL and set the download attribute
        // link.setAttribute('download', zipUrl.split('/').pop());
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
        // window.URL.revokeObjectURL(url);

        // toast.success(res.ResponseMsg || 'File downloaded successfully.');
      } catch (error) {
        console.error('Error downloading the file:', error);
        toast.error(error?.response?.data?.message || 'Failed to download the file.');
      } finally {
        setIsCSVDowloading(false);
        setVideoQualityModal({ open: false, data: null, mode: null })
        dispatch(resetRowSelection()); // Reset row selection or any other state if needed
      }
    },
    onError: (res) => {
      return toastHandler(res)
    }
  })

  const { mutate: moveToTrash } = useMutation({
    mutationFn: (values) => privateAxios(MOVETOTRASH, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
      }
      return toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => dispatch(resetRowSelection()),
  });

  const { mutate: deleteForever } = useMutation({
    mutationFn: (values) => privateAxios(DELETEFOREVER, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        queryClient.invalidateQueries({
          queryKey: { queryKey: [GETTRASHLIST] },
        });
      }
      return toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => dispatch(resetRowSelection()),
  });

  const folder = useForm({
    resolver: zodResolver(
      modal.name === "subuser" ? addNewSubuser : createNewFolder
    ),
    defaultValues: {
      displayName: "",
      password: "",
      phone: "",
    },
  });

  const past10years = useMemo(() => {
    let years = [];
    const start = defaultYear;
    const end = 2023;
    for (let i = start; i >= end; i--) {
      years.push(i);
    }
    return years;
  }, []);

  const shareUrl = useCallback(() => {
    const pageName = getPageName(pathname);
    if (Array.isArray(selectedRows)) {
      if (selectedRows[0].isFolder === "0") {
        return selectedRows[0].path;
      } else {
        return (
          window.location.origin +
          "/".concat(pageName, "/", selectedRows[0]._id)
        );
      }
    }
  }, [selectedRows]);

  const handleRemoveAllSelectedRowClicked = () => {
    return dispatch(resetRowSelection());
  };

  const onBreadCrumbLinkClicked = (selectedId) => {
    const currentPathIndex = state.from.findIndex(
      (paths) => paths.id === selectedId
    );
    const paths = state.from.slice(0, currentPathIndex + 1);

    let path = pathname.split("/");

    if (path.includes(id)) {
      path = path
        .map((values) => (values === id ? selectedId : values))
        .join("/");
    } else {
      path.push(selectedId);
      path = path.join("/");
    }

    return navigate(path, {
      state: {
        from: paths,
      },
    });
  };

  const getPath = () => {
    const path = Object.keys(PATHS).findLast((key) => pathname.includes(key));
    return (
      PATHS[path] ?? {
        title: "Vidscan Tech",
        isAddDropdown: false,
      }
    );
  };

  const onSubmit = (values) => {
    if (values.hasOwnProperty("password")) {
      const { phone, password } = values;
      const ccode = selectedCountry.slice(1, selectedCountry.length);
      return addSubuser({ ccode, phone, password: md5(password) });
    } else {
      const { displayName: name } = values;
      if (id) {
        return mutate({ name, parent_folder: id });

      } else if (pathname.includes("/return")) {
        return mutate({ name, parent_folder: returnParent._id });
      } else if (pathname.includes("/dispatch")) {
        return mutate({ name, parent_folder: dispatchParent._id });
      } else {
      }
    }
  };

  const handleFilterDateChange = (e) => {
    e === "Custom" ? setIsDateFilter(true) : setIsDateFilter(false);
    setFilter(e);
    switch (e) {
      case "Last 7 days":
        dispatch(setStart(subDays(new Date(), 7).toISOString()));
        dispatch(setEnd(new Date().toISOString()));
        queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
        break;
      case "Last 30 days":
        dispatch(setStart(subDays(new Date(), 30).toISOString()));
        dispatch(setEnd(new Date().toISOString()));
        queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
        break;
      case `This year (${new Date().getFullYear()})`:
        dispatch(setStart(startOfYear(new Date(new Date().getFullYear(), 0))));
        dispatch(setEnd(endOfYear(new Date(new Date().getFullYear(), 11))));
        queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
        break;
      case `Last year (${new Date().getFullYear() - 1})`:
        dispatch(
          setStart(startOfYear(new Date(new Date().getFullYear() - 1, 0)))
        );
        dispatch(setEnd(endOfYear(new Date(new Date().getFullYear() - 1, 11))));
        queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
        break;
      default:
        return null;
    }
  };

  const handleApplyDateRange = () => {
    if (!date?.from) return toast.error("Please select start date");
    if (!date?.to) return toast.error("Please select end date");

    const { from, to } = date;

    if (from && to) {
      dispatch(setStart(formatISO(from)));
      dispatch(setEnd(formatISO(to)));
    }
    setIsDateFilter(false);
    setIsShowDates(true);
  };

  const handleClearAllFilter = () => {
    setIsDateFilter(false);
    setFilter("Select Date");
    setIsShowDates(false);
    setDate({ from: null, to: null });
    dispatch(setStart(undefined));
    dispatch(setEnd(undefined));
  };

  const handleCopyLink = () => {
    const path = window.location.href;
    if (Array.isArray(selectedRows) && selectedRows.length) {
      let p = path.split("/");
      if (selectedRows[0].isFolder === "0") {
        copyToClipboard(selectedRows[0].path);
      } else {
        if (id) {
          p.pop();
          copyToClipboard(p.join("/").concat("/", selectedRows[0]._id));
        } else {
          copyToClipboard(p.join("/").concat("/", selectedRows[0]._id));
        }
      }
    }
    return toast.success("Link copied successfully.");
  };

  const handleTrash = () => {
    if (Array.isArray(selectedRows)) {
      if (selectedRows.length === 1) {
        pathname.includes("/trash")
          ? deleteForever({ folder_ids: [selectedRows[0]._id] })
          : moveToTrash({ folder_ids: [selectedRows[0]._id] });
      } else {
        const ids = selectedRows.map(({ _id }) => _id);
        pathname.includes("/trash")
          ? deleteForever({ folder_ids: ids })
          : moveToTrash({ folder_ids: ids });
      }
    }
  };

  const handleClose = () => {
    setIsModal((prevState) => ({
      ...prevState,
      open: !prevState.open,
      data: null,
    }));
    folder.reset();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= 100) {
      setQualityVal(e.target.value); // Call the passed onChange handler only if the value is valid
    }
  };

  return pathname === "/vidscan-tech" ||
    pathname === "/" ||
    pathname === "/pricing-model" ||
    pathname === "/secure-transactions" ||
    pathname === "/success-stories" ||
    pathname === "/about-us" ? (
    <Outlet />
  ) : (
    <main className="h-screen w-screen overflow-auto">
      {isCSVDowloading && <Loader />}
      {/* {isCSVDowloading && <div className="absolute z-10 bottom-0 right-0 bg-[#EDF2FC] w-[300px] py-3 px-3 rounded-lg flex items-center gap-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-repeat animate-spin" viewBox="0 0 16 16">
          <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
          <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
        </svg>
        <p className="text-[#1F1F1F] text-base   font-medium">Downloading...</p>
      </div>} */}

      <div
        className={twMerge(
          "w-full md:w-[calc(100vw-280px)] h-[calc(100vh-70px)] mt-[70px] ml-auto",
          (pathname.includes("/login") ||
            pathname.includes("/signup") ||
            pathname.includes("/forgot-password")) &&
          "w-screen md:w-full h-screen m-0"
        )}
      >
        <ToastContainer limit={3} autoClose={1000} />
        {!(
          pathname.includes("/login") ||
          pathname.includes("/signup") ||
          pathname.includes("/forgot-password")
        ) && <Header />}
        <div className="h-full min-h-[calc(100vh-70px)] flex justify-between bg-bg-primary">
          {!(
            pathname.includes("/login") ||
            pathname.includes("/signup") ||
            pathname.includes("/forgot-password")
          ) && <Sidebar />}
          <div
            className={twMerge(
              "w-full mx-2 flex flex-col bg-white rounded-2xl py-3 overflo-y-auto overflow-x-hidden",
              (pathname.includes("/login") ||
                pathname.includes("/signup") ||
                pathname.includes("/forgot-password")) &&
              "p-0 m-0 bg-bg-primary"
            )}
          >
            {!(
              pathname.includes("/login") ||
              pathname.includes("/signup") ||
              pathname.includes("/get-storage") ||
              pathname.includes("/get-credits") ||
              pathname.includes("/forgot-password") ||
              pathname.includes("/profile") ||
              pathname.includes("/change-password") ||
              pathname.includes("/admin")
            ) && (
                <>
                  {getPath().isPlainText ? (
                    <h3 className="py-2 px-3 text-text text-center font-bold text-4xl font-lato">
                      {getPath().title}
                    </h3>
                  ) : (
                    <ScrollContainer
                      vertical
                      hideScrollbars={true}
                      className="min-h-[44px] flex items-center justify-between"
                    >
                      <div className="w-max pl-1.5 pr-4 flex items-center justify-between overflow-x-auto">
                        {state?.from ? (
                          <>
                            <Link
                              to={`/${pathname.includes("/dispatch")
                                ? "dispatch"
                                : pathname.includes("/return")
                                  ? "return"
                                  : pathname.includes("/starred")
                                    ? "starred"
                                    : pathname.includes("/trash")
                                      ? "trash"
                                      : ""
                                }`}
                              className="flex items-center text-text"
                            >
                              <Badge className="py-2 px-3 text-text text-base font-lato capitalize bg-transparent hover:bg-bg2-hover transition-all">
                                {getPath().title}
                              </Badge>
                              <ChevronRight />
                            </Link>
                            {state?.from?.map((prevPaths, index, array) => {
                              return (
                                <Fragment key={prevPaths.id}>
                                  {array.length - 1 === index &&
                                    getPath().isAddDropdown ? (
                                    <DropdownMenu
                                      open={isAddDropdown}
                                      onOpenChange={() =>
                                        setIsAddDropdown(!isAddDropdown)
                                      }
                                    >
                                      <DropdownMenuTrigger
                                        className={twMerge(
                                          "w-max py-2 px-3 flex items-center gap-x-3 outline-none text-text rounded-3xl cursor-pointer hover:bg-bg2-hover transition-all",
                                          isAddDropdown && "bg-bg2-hover"
                                        )}
                                      >
                                        <span className="text-xl font-semibold font-lato capitalize">
                                          {prevPaths.name}
                                        </span>
                                        <ChevronDown />
                                      </DropdownMenuTrigger>
                                      <DropdownMenuContent
                                        align="start"
                                        className="min-w-[300px] bg-bg2 text-text border-0"
                                      >
                                        <DropdownMenuItem
                                          className="flex items-center gap-x-4"
                                          onClick={() =>
                                            setIsModal((prevState) => ({
                                              ...prevState,
                                              open: true,
                                              name: "folder",
                                            }))
                                          }
                                        >
                                          <FolderPlus className="w-5" />
                                          <span>New Folder</span>
                                        </DropdownMenuItem>
                                      </DropdownMenuContent>
                                    </DropdownMenu>
                                  ) : (
                                    <div
                                      className="flex items-center text-text cursor-pointer"
                                      onClick={() =>
                                        onBreadCrumbLinkClicked(prevPaths.id)
                                      }
                                    >
                                      <Badge className="py-2 px-3 text-text text-base font-lato capitalize bg-transparent hover:bg-bg2-hover transition-all">
                                        {prevPaths.name}
                                      </Badge>
                                      {array.length - 1 !== index && (
                                        <ChevronRight />
                                      )}
                                    </div>
                                  )}
                                </Fragment>
                              );
                            })}
                          </>
                        ) : getPath().isAddDropdown ? (
                          <DropdownMenu
                            open={isAddDropdown}
                            onOpenChange={() => setIsAddDropdown(!isAddDropdown)}
                          >
                            <DropdownMenuTrigger
                              className={twMerge(
                                "w-max py-2 px-3 flex items-center gap-x-3 outline-none text-text rounded-3xl cursor-pointer hover:bg-bg2-hover transition-all",
                                isAddDropdown && "bg-bg2-hover"
                              )}
                            >
                              <span className="text-xl font-lato">
                                {getPath().title}
                              </span>
                              <ChevronDown />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                              align="start"
                              className="min-w-[300px] bg-bg2 text-text border-0"
                            >
                              {pathname.includes("/manage-subuser") ? (
                                <DropdownMenuItem
                                  className="flex items-center gap-x-4"
                                  onSelect={() =>
                                    setIsModal((prevState) => ({
                                      ...prevState,
                                      open: true,
                                      name: "subuser",
                                    }))
                                  }
                                >
                                  <UserPlus2 className="w-5" />
                                  <span>New Subuser</span>
                                </DropdownMenuItem>
                              ) : (
                                <DropdownMenuItem
                                  className="flex items-center gap-x-4"
                                  onSelect={() =>
                                    setIsModal((prevState) => ({
                                      ...prevState,
                                      open: true,
                                      name: "folder",
                                    }))
                                  }
                                >
                                  <FolderPlus className="w-5" />
                                  <span>New Folder</span>
                                </DropdownMenuItem>
                              )}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        ) : (
                          <h3 className="py-2 px-3 flex items-center gap-x-3 text-text text-xl font-lato">
                            {getPath().title}
                          </h3>
                        )}
                      </div>
                      {getPath().isYearFilter && (
                        <div className="w-max pl-1.5 pr-4 flex items-center justify-between overflow-x-auto">
                          <Select
                            defaultValue={defaultYear}
                            value={selectedYear}
                            onValueChange={(e) => dispatch(setSelectedYear(e))}
                          >
                            <SelectTrigger className="w-[180px]">
                              <SelectValue placeholder="Select Year" />
                            </SelectTrigger>
                            <SelectContent>
                              {past10years.map((years) => {
                                return (
                                  <SelectItem key={years} value={years}>
                                    {years}
                                  </SelectItem>
                                );
                              })}
                            </SelectContent>
                          </Select>
                        </div>
                      )}
                    </ScrollContainer>
                  )}
                  {(getPath().isDateFilter || pathname === "/admin") && (
                    <div
                      className={twMerge(
                        "h-[40px] mx-4 mt-3 flex items-center gap-x-2 transition-all",
                        selectedRows.length && "bg-bg2 rounded-3xl px-1"
                      )}
                    >
                      {selectedRows.length ? (
                        <>
                          <div
                            className="p-2 rounded-full hover:bg-bg-primary transition-all cursor-pointer"
                            onClick={handleRemoveAllSelectedRowClicked}
                          >
                            <X className="w-4 h-4 text-text" />
                          </div>
                          <div className="text-text text-sm">
                            <span>{selectedRows.length} selected</span>
                          </div>
                          {/* <div className="hidden s:block p-1 sm:p-2 rounded-full hover:bg-bg-primary transition-all cursor-pointer">
                            <ShareBtn url={shareUrl()}>
                              <Share2 className="w-4 h-4 text-text" />sasa
                            </ShareBtn>
                          </div> */}
                          <div className="hidden s:block p-1 sm:p-2 rounded-full hover:bg-bg-primary transition-all cursor-pointer" onClick={() =>
                            download({
                              folder_ids: selectedRows.map(({ _id }) => _id),
                            })
                          }>
                            <BsFiletypeCsv className="w-4 h-4 text-text font-semibold" />
                          </div>
                          <div
                            className="hidden s:block p-1 sm:p-2 rounded-full hover:bg-bg-primary transition-all cursor-pointer"
                            onClick={() =>
                              setMoveFolder({
                                open: true,
                                data:
                                  selectedRows.length > 1
                                    ? selectedRows
                                    : selectedRows[0],
                              })
                            }
                          >
                            <FolderInput className="w-4  h-4 text-text" />
                          </div>
                          {pathname.includes("/trash") && (
                            <div
                              className="hidden s:block p-1 sm:p-2 rounded-full hover:bg-bg-primary transition-all cursor-pointer"
                              onClick={() =>
                                handleRestoreTrash({
                                  folder_ids: selectedRows.map((row) => row._id),
                                })
                              }
                            >
                              <FolderSync className="w-4  h-4 text-text" />
                            </div>
                          )}
                          <div
                            className="hidden s:block p-1 sm:p-2 rounded-full hover:bg-bg-primary transition-all cursor-pointer"
                            onClick={handleTrash}
                          >
                            <Trash2 className="w-4  h-4 text-text" />
                          </div>
                          <div
                            className="hidden s:block p-1 sm:p-2 rounded-full hover:bg-bg-primary transition-all cursor-pointer"
                            onClick={() => {
                              const hasFolder = selectedRows.some(row => row.isFolder == 1);
                              if (hasFolder) {
                                const folderId = selectedRows.find(row => row.isFolder == 1)?._id;
                                if (folderId) {
                                  setIsCSVDowloading(true);
                                  downloadZip({ folderId });
                                  setIsCSVDowloading(false);
                                } else {
                                  console.error("Folder ID not found in selected rows");
                                }
                              } else {
                                if (selectedRows.length <= 400) {
                                  setIsCSVDowloading(true);
                                  downloadMultipleFiles(selectedRows);
                                  setIsCSVDowloading(false);
                                } else {
                                  downloadZip({ videosIds: selectedRowsID });
                                  setIsCSVDowloading(true);
                                }
                              }
                            }}
                          >
                            <ArrowDownToLine className="w-4  h-4 text-text" />
                          </div>
                          {/* <div className="">
                            <button className="cursor-pointer text-sm font-normal"
                              onClick={() => { setVideoQualityModal({ open: true, data: null, mode: null }) }}
                            >Quality</button>
                          </div> */}
                          {/* <div
                            className={`hidden s:block p-1 sm:p-2 rounded-full hover:bg-bg-primary transition-all cursor-pointer`}
                            onClick={handleCopyLink}
                          >
                            <Link2 className="w-4  h-4 text-text" />
                          </div> */}
                        </>
                      ) : (
                        <>
                          <Select
                            value={filter}
                            onValueChange={handleFilterDateChange}
                            onOpenChange={(e) => e && setIsDateFilter(false)}
                          >
                            <SelectTrigger className="w-max h-max py-1.5 pl-4  gap-x-3 bg-transparent text-text font-medium rounded-lg">
                              <SelectValue placeholder={filter} />
                            </SelectTrigger>
                            <SelectContent className="bg-bg2 text-text">
                              <SelectItem value="Select Date" className="hidden">
                                Select Date
                              </SelectItem>
                              <SelectItem value="Last 7 days">
                                Last 7 days
                              </SelectItem>
                              <SelectItem value="Last 30 days">
                                Last 30 days
                              </SelectItem>
                              <SelectItem
                                value={`This year (${new Date().getFullYear()})`}
                              >
                                This year ({new Date().getFullYear()})
                              </SelectItem>
                              <SelectItem
                                value={`Last year (${new Date().getFullYear() - 1
                                  })`}
                              >
                                Last year ({new Date().getFullYear() - 1})
                              </SelectItem>
                              <Popover open={isDateFilter} modal={true}>
                                <SelectItem value="Custom">
                                  <PopoverTrigger>
                                    {isShowDates && date.to && date.from ? (
                                      <p>
                                        {isSameYear(date.to, date.from) ? (
                                          isSameMonth(date.to, date.from) ? (
                                            <div className="flex items-center gap-x-2">
                                              <span>
                                                {format(date.from, "LLL", {
                                                  locale: enUS,
                                                })}
                                              </span>
                                              <div className="flex items-center">
                                                <span>{getDate(date.from)}</span>
                                                <span>
                                                  <Minus className="w-2.5 h-3.5 mx-0.5 text-text" />
                                                </span>
                                                <span>{getDate(date.to)}</span>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="flex items-center gap-x-2">
                                              <span>
                                                {format(date.from, "LLL", {
                                                  locale: enUS,
                                                })}{" "}
                                                {getDate(date.from)}
                                              </span>
                                              <span>
                                                <Minus className="w-2.5 h-3.5 mx-0.5 text-text" />
                                              </span>
                                              <span>
                                                {format(date.to, "LLL", {
                                                  locale: enUS,
                                                })}{" "}
                                                {getDate(date.to)}
                                              </span>
                                            </div>
                                          )
                                        ) : (
                                          <div className="flex items-center gap-x-2">
                                            <span>
                                              {format(date.from, "LLL", {
                                                locale: enUS,
                                              })}{" "}
                                              {getDate(date.from)},{" "}
                                              {getYear(date.from)}
                                            </span>
                                            <span>
                                              <Minus className="w-2.5 h-3.5 mx-0.5 text-text" />
                                            </span>
                                            <span>
                                              {format(date.to, "LLL", {
                                                locale: enUS,
                                              })}{" "}
                                              {getDate(date.to)},{" "}
                                              {getYear(date.to)}
                                            </span>
                                          </div>
                                        )}
                                      </p>
                                    ) : (
                                      "Custom"
                                    )}
                                  </PopoverTrigger>
                                </SelectItem>
                                <PopoverContent
                                  align="start"
                                  alignOffset={-20}
                                  side="bottom"
                                  sideOffset={10}
                                  className="p-0 bg-bg2"
                                >
                                  <Calendar
                                    initialFocus
                                    mode="range"
                                    defaultMonth={new Date()}
                                    selected={date}
                                    onSelect={setDate}
                                  />
                                  <div className="px-3 pb-3 flex gap-x-3 justify-end">
                                    <Button onClick={handleClearAllFilter}>
                                      Cancel
                                    </Button>
                                    <Button onClick={handleApplyDateRange}>
                                      Apply
                                    </Button>
                                  </div>
                                </PopoverContent>
                              </Popover>
                            </SelectContent>
                          </Select>
                        </>
                      )}
                      {filter !== "Select Date" && (
                        <Badge
                          className="bg-bg2 hover:bg-bg2-hover text-text font-normal text-sm cursor-pointer"
                          onClick={handleClearAllFilter}
                        >
                          Clear all
                        </Badge>
                      )}
                    </div>
                  )}
                </>
              )}
            <Outlet />
          </div>

          <div
            className={twMerge(
              "w-full mx-2 flex flex-col bg-white rounded-2xl py-3 overflo-y-auto overflow-x-hidden transition-all",
              fileDetail ? "max-w-[300px]" : "max-w-0"
            )}
          >
            {fileDetail && (
              <>
                <div className="px-6 py-0.5 flex justify-between text-text">
                  <div className="flex items-center gap-x-4">
                    {fileDetail.isFolder === "1" ? (
                      <FolderIcon className="w-6 h-6" />
                    ) : isVideo(fileDetail.mimetype) ? (
                      <FileVideo className="w-6 h-6" />
                    ) : isImage(fileDetail.mimetype) ? (
                      <FileImage className="w-6 h-6" />
                    ) : (
                      "Invalid formate"
                    )}
                    <span className="text-xl font-medium">
                      {fileDetail.displayName}
                    </span>
                  </div>
                  <span
                    className="p-2 flex items-center hover:bg-bg2-hover rounded-full cursor-pointer"
                    onClick={() => dispatch(setDetails(null))}
                  >
                    <X className="w-6 h-6" />
                  </span>
                </div>
                <div className="w-full max-w-[40%] my-4 mx-auto">
                  {fileDetail.isFolder === "1" ? (
                    <FolderIcon className="w-full h-auto" />
                  ) : isVideo(fileDetail.mimetype) ? (
                    <FileVideo className="w-full h-auto" />
                  ) : isImage(fileDetail.mimetype) ? (
                    <FileImage className="w-full h-auto" />
                  ) : (
                    "Invalid formate"
                  )}
                </div>
                <hr className="border-2" />
                <div className="py-2 px-4">
                  <h6 className="text-lg font-medium">
                    {fileDetail.isFolder === "1"
                      ? "Folder"
                      : isVideo(fileDetail.mimetype)
                        ? "Video"
                        : isImage(fileDetail.mimetype)
                          ? "Image"
                          : "Invalid formate"}
                    &nbsp; Details
                  </h6>
                  <ul className="mt-4 flex flex-col gap-y-3">
                    <li className="text-sm flex flex-col gap-y-1">
                      <p className="font-medium">Type</p>
                      <p>{fileDetail.isFolder === "1" ? "Folder" : "File"}</p>
                    </li>
                    <li className="text-sm flex flex-col gap-y-1">
                      <p className="font-medium">Size</p>
                      <p>
                        {fileDetail.isFolder === "1" ? (
                          <Minus className="w-4 h-4 text-text2" />
                        ) : (
                          formatFileSize(fileDetail.size, 2)
                        )}
                      </p>
                    </li>
                    <li className="text-sm flex flex-col gap-y-1">
                      <p className="font-medium">Last Modified</p>
                      <p>
                        {format(
                          parseISO(fileDetail.updatedAt),
                          "MMM dd, yyyy / hh:mm:ss"
                        )}
                      </p>
                    </li>
                    <li className="text-sm flex flex-col gap-y-1">
                      <p className="font-medium">Created</p>
                      <p>
                        {format(
                          parseISO(fileDetail.createdAt),
                          "MMM dd, yyyy / hh:mm:ss"
                        )}
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* AddNewFolder  */}

      <Dialog open={modal.open} onOpenChange={handleClose}>
        <DialogContent
          closeIcon={false}
          className="w-full max-w-[400px] bg-bg-primary"
        >
          <form
            onSubmit={folder.handleSubmit(onSubmit)}
            className="flex flex-col gap-y-6"
          >
            {modal.name === "folder" ? (
              <div className="flex flex-col gap-y-4">
                <Label className="text-text text-2xl">New Folder</Label>
                <Input
                  {...folder.register("displayName")}
                  className={`py-5 border ${folder.formState.errors.displayName &&
                    "text-red-400 placeholder:text-red-600 border-red-400"
                    }`}
                  autoComplete="off"
                  placeholder="Enter folder name"
                />
              </div>
            ) : modal.name === "subuser" ? (
              <div className="flex flex-col gap-y-4">
                <Label className="text-text text-2xl">New Subuser</Label>
                <div className="flex flex-col gap-y-2">
                  <Label className="text-text text-lg">Phone number</Label>
                  <div className="flex">
                    <Popover
                      open={isCountrySelector}
                      onOpenChange={() =>
                        seIsCountrySelector(!isCountrySelector)
                      }
                    >
                      <PopoverTrigger className="w-full max-w-[75px] px-2 flex items-center justify-between gap-x-1 bg-bg2-hover rounded-tl-md rounded-bl-md outline-none">
                        <span className="text-sm text-text">
                          {selectedCountry}
                        </span>
                        <ChevronDown className="w-4 h-4 text-text2" />
                      </PopoverTrigger>
                      <PopoverContent align="start" className="p-0">
                        <Command className="bg-bg2 text-text2">
                          <CommandInput
                            placeholder="Search country name"
                            className="placeholder:text-text2"
                          />
                          <CommandList>
                            <CommandEmpty className="py-4 text-center">
                              No country found
                            </CommandEmpty>
                            {countyCode.countries.map((country, index) => {
                              return (
                                <CommandItem
                                  key={index}
                                  className={twMerge(
                                    "aria-selected:bg-bg2-hover text-text aria-selected:text-text cursor-pointer",
                                    selectedCountry === country.dial &&
                                    "bg-bg2-hover aria-selected:bg-bg2-hover cursor-default"
                                  )}
                                  onSelect={() =>
                                    selectedCountry === country.dial
                                      ? null
                                      : (setSelectedCountry(country.dial),
                                        seIsCountrySelector(false))
                                  }
                                >
                                  {country.name}
                                </CommandItem>
                              );
                            })}
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <Input
                      {...folder.register("phone")}
                      type="number"
                      className={`pl-0 py-5 border-0 rounded-md border-l-0 rounded-tl-none rounded-bl-none ${folder.formState.errors.phone &&
                        "text-red-400 placeholder:text-red-600 border-red-400"
                        }`}
                      autoComplete="off"
                      placeholder="Enter phone number"
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <Label className="text-text text-lg">Password</Label>
                  <Input
                    {...folder.register("password")}
                    className={`py-5 border-0 rounded-md ${folder.formState.errors.password &&
                      "text-red-400 placeholder:text-red-600 border-red-400"
                      }`}
                    autoComplete="off"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="flex justify-end gap-x-3">
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">
                {modal.name === "folder"
                  ? "Create"
                  : modal.name === "subuser"
                    ? "Add subuser"
                    : ""}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* MoveFolder Modal */}
      {moveFolder.data && (
        <MoveFolder modal={moveFolder} setModal={setMoveFolder} />
      )}
      {
        <VideoQuality modal={videoQualityModal} setModal={setVideoQualityModal} value={qualityVal} onChange={(e) => { handleChange(e) }} handleSubmit={() => {
          if (qualityVal) {
            const hasFolder = selectedRows.some(row => row.isFolder == 1);
            if (hasFolder) {
              const folderId = selectedRows.find(row => row.isFolder == 1)?._id;
              if (folderId) {
                setIsCSVDowloading(true);
                downloadZip({ folderId });
                setIsCSVDowloading(false);
              } else {
                console.error("Folder ID not found in selected rows");
              }
            }
            else {
              downloadZip({ videosIds: selectedRowsID, quality: parseInt(qualityVal) })
              setIsCSVDowloading(true)
            }

          } else {
            toast.warn("Please add quality to download videos")
          }

        }} />
      }
    </main>
  );
};

export default Layout;
