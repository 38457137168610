import { X } from "lucide-react";
import Video from "../Video";
import { Dialog, DialogContent } from "../ui/dialog";
import { isImage, isVideo } from "../../lib/helper";
import { useRef } from "react";

const VideoPreview = ({ open, setOpen, data }) => {
  const source = {
    type: "video",
    title: data.displayName,
    sources: data.path
  };

  const videoPlyerRef = useRef();

  const closeDialog = () => {
    setOpen({ open: false, data: null });
  };

  return (
    <Dialog open={open}>
      <DialogContent className="w-full max-w-none h-full max-h-screen p-0 flex flex-col items-center justify-center bg-transparent border-none outline-none shadow-none">
        <div className="w-full py-3 px-10 absolute flex items-center justify-between top-0 bg-[#0000001A]">
          <span className="text-white text-lg font-medium">
            {data.displayName}
          </span>
          <div
            className="p-1 text-white rounded-full hover:bg-white hover:text-black cursor-pointer transition-all"
            onClick={closeDialog}
          >
            <X className="w-8 h-8" />
          </div>
        </div>
        <div className="mt-[64px] flex justify-center items-center">
          {isVideo(data.mimetype) ? (
            // data.orientation === "Landscape"
              // ? <div className="landscape-plyr w-full min-w-[390px] h-full max-h-[calc(100vh-64px)] flex items-center justify-center">
              //   <Video ref={videoPlyerRef} source={source} />
              // </div>
              // : <div className="potrait-plyr w-full max-w-[25%] min-w-[390px] h-[calc(100vh-64px)] flex items-center justify-center">
              //   <Video ref={videoPlyerRef} source={source} />
              // </div>
            <div className=" w-full min-w-[390px] h-full max-h-[calc(100vh-64px)] flex items-center justify-center">
              <Video ref={videoPlyerRef} source={source} />
            </div>
          ) : isImage(data.mimetype) ? (
            <div className="w-full max-w-[1200px]">
              <img
                src={data.path}
                className="w-full h-auto max-h-[calc(100vh-64px)] object-contain"
                alt="uploaded-img"
              />
            </div>
          ) : (
            "No soure found"
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VideoPreview;
