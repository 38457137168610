const Footer = () => {
  return (
    <footer className="flex justify-between items-center px-6 py-4 bg-white">
      <div></div>

      <div>
        <ul>
          <li>
            
          </li>
        </ul>
      </div>

    </footer>
  )
}

export default Footer