import { useMutation } from "@tanstack/react-query";
import { IndianRupee } from "lucide-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, buttonVariants } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { cn } from "../../components/utils";
import { REQUEST_PAYMENT } from "../../constant/endpoints";
import { privateAxios } from "../../lib/axios";
import { toastHandler } from "../../lib/helper";

const GetCredits = () => {
  const { _id, name } = useSelector((state) => state.userAuthReducer.auth);
  const cutomePlantInputRef = useRef(null);

  const [plan, setPlan] = useState({
    amount: null,
    credits: null,
    isCustom: false,
    credits: null
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (values) => privateAxios(REQUEST_PAYMENT, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        window.open(res.data.payLink, "_blank", "noreferrer");
      }
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const plans = [
    {
      amount: "10000.00",
      credits: 10000,
      condition: "Unlimited validity"
    },
    {
      amount: "30000.00",
      credits: 36000,
      condition: "Unlimited validity",
    },
    {
      amount: "50000.00",
      credits: 65000,
      condition: "Unlimited validity",
    },
  ];

  return (
    <section className="px-4">
      <Table mainStyle="h-auto">
        <TableHeader>
          <TableRow>
            <TableHead className="text-base text-label whitespace-nowrap">
              Recharge Amount
            </TableHead>
            <TableHead className="text-base text-label">Credits</TableHead>
            <TableHead className="text-base text-label">Conditions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {plans.map((row, index) => {
            return (
              <TableRow
                key={index}
                className="border-b-text cursor-pointer hover:bg-text3/25"
                onClick={() =>
                  setPlan({
                    amount: row.amount,
                    credits: row.credits,
                    isCustom: false,
                  })
                }
              >
                <TableCell className="py-3 text-base flex items-center gap-x-3 text-text font-semibold">
                  <Checkbox
                    checked={
                      plan.amount === row.amount && plan.credits === row.credits
                    }
                    iconStyle="text-white"
                    className="w-[18px] h-[18px] rounded-[4px] data-[state=checked]:bg-blue-400 data-[state=checked]:text-white transition-all"
                  />
                  <div className="flex items-center">
                    <IndianRupee className="size-4" /> {row.amount}
                  </div>
                </TableCell>
                <TableCell className="lg:w-[450px] py-3 text-base text-text font-semibold whitespace-nowrap">
                  {row.credits} credits
                </TableCell>
                <TableCell className="py-3 text-base font-medium">
                  {row.condition}
                </TableCell>
              </TableRow>
            );
          })}
          {/* <TableRow
            className="border-b-text cursor-pointer hover:bg-text3/25"
            onClick={() => {
              setPlan({ amount: "", credits: 0, isCustom: true });
              if (cutomePlantInputRef.current)
                cutomePlantInputRef.current.focus();
            }}
          >
            <TableCell className="py-3 text-base flex items-center gap-x-3 text-text font-semibold">
              <Checkbox
                checked={plan.isCustom}
                iconStyle="text-white"
                className="w-[18px] h-[18px] rounded-[4px] data-[state=checked]:bg-blue-400 data-[state=checked]:text-white transition-all"
              />
              <Input
                type="number"
                value={plan.isCustom ? plan.amount ?? "" : ""}
                onChange={(e) => {
                  if (plan.isCustom && e.target.value.length < 8) {
                    setPlan((prevState) => ({
                      ...prevState,
                      amount: isNaN(e.target.value)
                        ? prevState.credits
                        : e.target.value,
                      credits: isNaN(e.target.value)
                        ? prevState.credits
                        : e.target.value,
                    }));
                  } else e.preventDefault();
                }}
                ref={cutomePlantInputRef}
                disabed={!plan.isCustom}
                className="h-auto w-full max-w-[280px] text-base px-2 py-1.5 bg-transparent border-text2/75"
              />
            </TableCell>
            <TableCell className="w-[450px] py-2 text-base text-text font-semibold">
              {plan.isCustom && plan.amount ? plan.amount + " credits" : "-"}
            </TableCell>
            <TableCell className="py-2 text-base font-medium whitespace-nowrap">
              Unlimited validity
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
      <div className="py-4 text-end border-t border-t-text">
        <Dialog>
          <DialogTrigger
            disabled={(!plan.amount && !plan.credits) || isLoading}
          >
            <Button
              type="submit"
              className="h-auto bg-primary px-6 text-lg tracking-wide transition-all"
            >
              Buy Now
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-[90%] sm:max-w-lg p-4 md:p-6 rounded-xl">
            <h6 className="text-text text-2xl font-medium">Summary</h6>
            {/* <p>
              Recharge your account and get{" "}
              <span className="font-semibold">{plan.credits} credits</span>.
              This amount is valid indefinitely.
            </p> */}

            <div>
              <p>
                <span className="font-semibold">Amount to be credited:</span>{" "}
                {plan.credits} credits
              </p>
            </div>

            <div className="border divide-y rounded-lg">
              <div className="px-2 py-2 flex items-center gap-x-2">
                <span className="w-full max-w-[140px] border-r font-semibold">
                  Recharge Amount:
                </span>{" "}
                <div className="text-base flex items-center gap-x-0.5">
                  <IndianRupee className="size-4 stroke-[1.3px]" />{" "}
                  <span>{plan.amount}</span>{" "}
                  <span className="ml-0.5 text-xs text-primary/75 font-medium">
                    (Exclusive of taxes)
                  </span>
                </div>
              </div>
              <div className="px-2 py-2 flex items-center gap-x-2">
                <span className="w-full max-w-[140px] border-r font-semibold">
                  Tax <span className="text-sm font-medium">(18% GST)</span>:
                </span>{" "}
                <div className="text-base flex items-center gap-x-0.5">
                  <IndianRupee className="size-4 stroke-[1.3px]" />{" "}
                  <span>{(Number(plan.amount) * 18) / 100}</span>
                </div>
              </div>
              <div className="px-2 py-2 flex items-center gap-x-2">
                <span className="w-full max-w-[140px] border-r font-semibold">
                  Total Amount:
                </span>{" "}
                <div className="text-base flex items-center gap-x-0.5">
                  <IndianRupee className="size-4 stroke-[2px]" />{" "}
                  <span className="font-semibold">
                    {Number(plan.amount) + (Number(plan.amount) * 18) / 100}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end flex-col sm:flex-row gap-y-2 sm:gap-x-2 md:gap-x-4">
              <DialogClose
                className={cn(
                  buttonVariants(),
                  "w-full max-w-[140px] font-lato font-bold text-lg bg-primary"
                )}
              >
                Cancel
              </DialogClose>
              <Button
                className="w-full max-w-[140px] items-center font-lato font-bold text-lg bg-primary"
                onClick={() =>
                  mutate({
                    billing_name: name,
                    user_id: _id,
                    amount: Number(plan.amount) + (Number(plan.amount) * 18) / 100,
                    credits: plan.credits
                  })
                }
              >
                Pay <IndianRupee className="size-4 ml-1" />{" "}
                {Number(plan.amount) + (Number(plan.amount) * 18) / 100}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </section>
  );
};

export default GetCredits;
