import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PLANS, UPDATEPLANS } from "../../constant/endpoints";
import { privateAdminAxios } from "../../lib/axios";
import { Badge } from "../../components/ui/badge";
import { formatFileSize, toastHandler } from '../../lib/helper'
import { Check, Edit2, Plus, Tag, Trash2 } from "lucide-react";
import { Button } from "../../components/ui/button";
import { useMemo, useState } from "react";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { useFieldArray, useForm } from "react-hook-form";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { plans } from "../../lib/schemas";
import Loader from '../../components/Loader'
import { toast } from "react-toastify";

const Plans = () => {
  const [editPlan, setEditPlan] = useState({ open: false, data: {} })
  const [selectedFileFormat, setSelectedFileFormat] = useState("KB")
  const fileSizes = useMemo(() => ["Bytes", "KB", "MB", "GB", "TB"], [])
  const queryClient = useQueryClient()

  const { data = [], isLoading } = useQuery({
    queryKey: [PLANS],
    queryFn: () => privateAdminAxios(PLANS, null, "GET"),
    select: res => res.data
  });

  const folder = useForm({
    resolver: zodResolver(plans),
    values: editPlan.data
  });

  const { fields, append, remove } = useFieldArray({
    control: folder.control,
    name: "description",
  });

  const { mutate } = useMutation({
    mutationFn: (values) => privateAdminAxios(UPDATEPLANS, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [PLANS] });
      toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: handleClose,
  });

  const handleEdit = (data) => {
    const updateDescription = data.description.map(d => ({ value: d }))
    const { _id, updatedAt, storage, ...rest } = data
    const [size, type] = formatFileSize(data.storage).split(" ")
    setEditPlan({ open: true, data: { ...rest, storage: size, plan_id: _id, description: updateDescription } })
    setSelectedFileFormat(type)
  }

  const onSubmit = (e) => {
    const fileSize = { "Bytes": 1, "KB": 1024, "MB": Math.pow(1024, 2), "GB": Math.pow(1024, 3), "TB": Math.pow(1024, 4) }
    const { storage, ...rest } = e
    const description = e.description.map(d => d.value)
    return mutate({ ...rest, storage: storage * fileSize[selectedFileFormat], description })
  }

  function handleClose() {
    setEditPlan({ open: false, data: {} })
    setSelectedFileFormat(0)
  };
  return (
    <>
      {isLoading
        ? <Loader />
        : <div className="w-full h-full mx-auto p-4 grid grid-cols-3 gap-6 place-items-center overflow-y-auto">
          {data.map(p => {
            return <div key={p._id} className="p-4 w-full max-w-[320px] mx-auto h-max flex flex-col justify-center items-center rounded-md shadow-lg border-2 border-primary relative">

              <div className="absolute p-2 right-0 top-0 border-2 border-transparent border-r-0 border-t-0 rounded-bl-xl bg-primary cursor-pointer text-white transition-all hover:bg-transparent hover:text-primary hover:border-primary"
                onClick={() => handleEdit(p)}>
                <Edit2 className="w-4 h-4 text-current" />
              </div>

              <Badge className="w-max bg-[#40704e] text-[#e6f4ea] gap-x-1.5 text-base font-normal hover:bg-[#40704e]">
                <Tag className='w-4 h-4' />
                {p.plan_name}
              </Badge>
              <div className='mt-4 space-y-1 text-center'>
                <h6 className='text-text2 text-3xl font-lato'>{formatFileSize(p.storage)}</h6>
              </div>
              <div className='mt-4 space-y-1 text-center'>
                <h6 className='text-text2 text-lg text-center'>{p.credits} Credit per Month</h6>
              </div>
              <div className="my-6">
                <Button className="px-8 bg-transparent border border-primary text-primary font-lato font-semibold text-base cursor-auto hover:bg-transparent">
                  {p.price}
                </Button>
              </div>
              <div className='w-full px-2'>
                <p className='text-text2 text-sm font-lato font-semibold'>Vidscan Tech includes</p>
                <ul className='mt-3 space-y-3'>
                  <li className='flex items-center gap-x-4 text-sm text-text2'>
                    <Check className='w-4 h-4 text-primary' />
                    <span>Share with up to {p.share_upto} others</span>
                  </li>
                  {p.description.map((d, i) => {
                    return <li key={p._id + i} className='flex items-center gap-x-4 text-sm text-text2'>
                      <Check className='w-4 h-4 text-primary' />
                      <span>{d}</span>
                    </li>
                  })}
                </ul>
              </div>
            </div>
          })}
        </div>}

      <Dialog open={editPlan.open} onOpenChange={handleClose}>
        <DialogContent closeIcon={false} className="w-full max-w-[680px] h-full max-h-[90%] m-auto p-0 border-0 bg-transparent flex justify-center items-center">
          <form noValidate onSubmit={folder.handleSubmit(onSubmit)} className="w-full h-full p-5 rounded-xl bg-bg-primary flex flex-col gap-y-6 overflow-auto">
            <h6 className="text-text text-2xl font-medium">Edit Plan Details</h6>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col space-y-2">
                <Label className="text-text text-lg">Plan Name</Label>
                <Input
                  {...folder.register("plan_name")}
                  className={`py-5 border-0 rounded-md ${folder.formState.errors.plan_name && "text-red-400 placeholder:text-red-600 border-red-400"}`}
                  autoComplete="off"
                  placeholder="Enter plan name"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <Label className="text-text text-lg">Price</Label>
                <Input
                  type="number"
                  className={`py-5 border-0 rounded-md ${folder.formState.errors.price && "text-red-400 placeholder:text-red-600 border-red-400"}`}
                  autoComplete="off"
                  placeholder="Enter price"
                  {...folder.register("price")}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <Label className="text-text text-lg">Credit Per Month</Label>
                <Input
                  type="number"
                  className={`py-5 border-0 rounded-md ${folder.formState.errors.credits && "text-red-400 placeholder:text-red-600 border-red-400"}`}
                  autoComplete="off"
                  placeholder="Enter allowcate credit per month"
                  {...folder.register("credits")}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <Label className="text-text text-lg">Subuser</Label>
                <Input
                  type="number"
                  className={`py-5 border-0 rounded-md ${folder.formState.errors.share_upto && "text-red-400 placeholder:text-red-600 border-red-400"}`}
                  autoComplete="off"
                  placeholder="Enter max subuser count"
                  {...folder.register("share_upto")}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <Label className="text-text text-lg">Storage</Label>
                <div className="flex">
                  <Input
                    type="number"
                    className={`py-5 border-0 rounded-md rounded-r-none ${folder.formState.errors.storage && "text-red-400 placeholder:text-red-600 border-red-400"}`}
                    autoComplete="off"
                    placeholder="Enter storage"
                    {...folder.register("storage")}
                  />
                  <Select
                    defaultValue={selectedFileFormat}
                    value={selectedFileFormat}
                    onValueChange={(e) => setSelectedFileFormat(e)}
                  >
                    <SelectTrigger className="w-max gap-2 rounded-l-none bg-[#e6e7ea]">
                      <SelectValue className="gap-2" placeholder="Select Year" />
                    </SelectTrigger>
                    <SelectContent>
                      {fileSizes.map((s, i) => {
                        return (
                          <SelectItem key={i} value={s}>
                            {s}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <div className="flex justify-between items-center gap-4">
                <Label className="text-text text-lg">Benfits</Label>
                <Button type="button" className="rounded-full p-0 aspect-square" onClick={() => append({ value: "" })}>
                  <Plus className="w-4 h-4 stroke-[3px]" />
                </Button>
              </div>
              {fields.map((f, i) => {
                return <div className="flex justify-between items-center gap-4">
                  <Input
                    key={f.id}
                    className={`py-5 border-0 rounded-md ${typeof folder.formState.errors.description?.[i] === "object" && "text-red-400 placeholder:text-red-600 border-red-400"}`}
                    autoComplete="off"
                    placeholder="Enter description"
                    {...folder.register(`description.${i}.value`)}
                  />
                  <Button type="button" className="rounded-full p-0 aspect-square hover:bg-red-600" onClick={() => {
                    console.log(i)
                    remove(i)
                  }}>
                    <Trash2 className="w-4 h-4 stroke-[3px]" />
                  </Button>
                </div>
              })}
            </div>
            <div className="h-full flex gap-x-3 items-end">
              <Button type="button" onClick={handleClose} className="w-full">
                Cancel
              </Button>
              <Button type="submit" className="w-full" disabled={!folder.formState.isDirty}>
                Edit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Plans