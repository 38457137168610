import { combineReducers } from "@reduxjs/toolkit";
import rowReducer from "./rowSlice";
import userAuthReducer from "./userAuthSlice";
import adminAuthReducer from "./adminAuthSlice";
import intialFoldersReducer from "./intialFoldersSlice";
import filterReducer from "./filterSlice";
import detailsReducer from "./detailsSlice";

const rootReducer = combineReducers({
    rowReducer,
    userAuthReducer,
    adminAuthReducer,
    intialFoldersReducer,
    filterReducer,
    detailsReducer,
})

export default rootReducer