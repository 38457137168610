const LOGIN = "/auth/login";
const SIGNUP = "/auth/signUp";
const LOGOUT = "/auth/logout";
const SENDOTP = "/auth/sendOtp";
const VERIFYOTP = "/auth/verifyOtp";
const FORGOTPASSWORD = "/auth/forgotPassword";
const PROFILE = "/user/profile";
const GETSUBUSERS = "/user/getSubUsers";
const DELETESUBUSERS = "/user/deleteSubUser";
const UPDATEPROFILE = "/user/updateProfile";
const CHANGEPASSWORD = "/user/changePassword";
const CREATESUBUSER = "/user/createSubUser";
const UPDATESUBUSER = "/user/updateSubUser";
const STORAGE = "/user/getStorage";
const DELETEACCOUNT = "/user/deleteAccount";
const UPDATEPLAN = "/user/updateUserPlan";
const DOWNLOAD_VIDEO = '/user/downloadVideos';
const USERPLAN = "/plan";
const NOTIFICATION_LIST = "/user/getNotificaitonList"
const NOTIFICATION_DELETE = '/user/deleteNotification'

const GETINITIALFOLDER = "/folder/getInitialFolders";
const GETIFOLDERDETAILS = "/folder/getFolderDetails";
const CREATEFOLDER = "/folder/createFolder";
const RENAMEFOLDER = "/folder/renameFolder";
const MOVETOTRASH = "/folder/moveToTrash";
const ADDREMOVETOSTARRED = "/folder/addRemoveToStarred";
const GETSTARREDLIST = "/folder/getStarredList";
const GETTRASHLIST = "/folder/getTrashedList";
const RESTOREFOLSER = "/folder/restoreFromTrash";
const DELETEFOREVER = "/folder/deleteForever";
const MOVEFOLDER = "/folder/moveFolder";
const DOWNLOADCSV = "/folder/downloadCsv";
const UPDATEVIDEOCONDITION = "/folder/updateVideoQuality"

const CREDITS = "history/credits";

const ADMINLOGIN = "/admin/auth/login";
const ADMINPROFILE = "/admin/profile";
const ADMINUPDATEPROFILE = "/admin/updateProfile";
const ADMINCHANGEPASSWORD = "/admin/changePassword";
const ADMINLOGOUT = "/admin/auth/logout";
const GETUSERS = "admin/getUsers";
const DELETEUSERS = 'admin/deleteUser'
const GETSETTINGS = "admin/getSettings";
const UPDATESETTINGS = "admin/updateSettings";
const GETCREDITS = "/admin/history/credits";
const ALLOWCATECREDITS = "/admin/allocateCredits";
const DOWNLOADUSERCSV = "/admin/downloadUserCsv";
const GETALLUSER = "/admin/getUsersForNotifications";
const SENDNOTIFICATIONS = "/admin/sendNotifications";
const PLANS = "/admin/plan";
const UPDATEPLANS = "/admin/plan/updatePlan";
const UPDATECONFIGURATION = "/admin/updateConfiguration";
const ADD_OFFLINE_PAYMENT = "/admin/offlinePayment"

const CONFIRMEMAIL = "/auth/confirmEmail";
const RESETPASSWORD = "/auth/resetPassword";

const REQUEST_PAYMENT = "/payment/request_payment";

const UPDATEUSER_PLAN = "/admin/updateUserPlan";
const PLAN_HISTORY = "/admin/userPaymentHistory";
const USER_PLAN_HISTORY = "/user/userPaymentHistory";

const GETIGNOREDBARCODE = "/user/getIgnoreBarcodeList";
const ADDUPDATEBARCODE = "/user/addUpdateBarcode";
const DELETEBARCODE = "/user/deleteBarcode";

const SHARE_CSV_FILE = '/folder/shareCsv';
const SHARE_CSV_DETAIL = "/folder/getFolderShareDetail"

export {
  LOGIN,
  SIGNUP,
  LOGOUT,
  PROFILE,
  UPDATEPLAN,
  DOWNLOAD_VIDEO,
  GETSUBUSERS,
  DELETESUBUSERS,
  SENDOTP,
  VERIFYOTP,
  UPDATEPROFILE,
  CHANGEPASSWORD,
  CREATESUBUSER,
  UPDATESUBUSER,
  GETINITIALFOLDER,
  GETIFOLDERDETAILS,
  CREATEFOLDER,
  RENAMEFOLDER,
  MOVETOTRASH,
  ADDREMOVETOSTARRED,
  GETSTARREDLIST,
  GETTRASHLIST,
  RESTOREFOLSER,
  DELETEFOREVER,
  MOVEFOLDER,
  CREDITS,
  DOWNLOADCSV,
  UPDATEVIDEOCONDITION,
  STORAGE,
  FORGOTPASSWORD,
  DELETEACCOUNT,
  USERPLAN,
  ADMINLOGIN,
  ADMINPROFILE,
  ADMINUPDATEPROFILE,
  ADMINCHANGEPASSWORD,
  ADMINLOGOUT,
  ADD_OFFLINE_PAYMENT,
  GETUSERS,
  DELETEUSERS,
  GETSETTINGS,
  UPDATESETTINGS,
  GETCREDITS,
  ALLOWCATECREDITS,
  CONFIRMEMAIL,
  RESETPASSWORD,
  DOWNLOADUSERCSV,
  GETALLUSER,
  SENDNOTIFICATIONS,
  PLANS,
  UPDATEPLANS,
  REQUEST_PAYMENT,
  UPDATECONFIGURATION,
  UPDATEUSER_PLAN,
  PLAN_HISTORY,
  USER_PLAN_HISTORY,
  GETIGNOREDBARCODE,
  ADDUPDATEBARCODE,
  DELETEBARCODE,
  NOTIFICATION_LIST,
  SHARE_CSV_FILE,
  SHARE_CSV_DETAIL,
  NOTIFICATION_DELETE
};
