import { useLocation } from "react-router-dom"

const PrivacyPolicy = () => {
  const { pathname } = useLocation()

  return (
    <section className="py-4">
      {!pathname.includes("/privacy-policy")
        ? <h3 className="py-2 px-3 text-text text-center font-bold text-4xl font-lato">
          Privacy Policy - Vidscan Tech
        </h3>
        : null}
      <div className="p-4 w-full max-w-[750px] mx-auto space-y-6">
        <p className="text-base leading-[30px] tracking-wide">At Vidscan Tech, we are committed to protecting the privacy and confidentiality of our users' personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard the data we collect from users of our software service. By accessing or using Vidscan Tech, you agree to the terms outlined in this Privacy Policy.</p>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">1. Information We Collect:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">Personal Information: When you sign up for Vidscan Tech, we may collect personal information such as your name, email address, contact number, and billing information.</li>
            <li className="text-base leading-[25px] tracking-wide">Video Proof Data: Our software records video proof of dispatches and returns, which may contain information related to your products and shipments.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">2. How We Use Your Information:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">To Provide Services: We use your personal information to set up and maintain your Vidscan Tech account, process transactions, and deliver the software service to you.</li>
            <li className="text-base leading-[25px] tracking-wide">Video Proof Data: The video proof data recorded using Vidscan Tech is used solely for the purpose of providing the service to you and to protect your business against false claims and fraudulent returns.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">3. Data Security:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">We employ industry-standard security measures to safeguard your personal information and video proof data from unauthorized access, use, or disclosure.</li>
            <li className="text-base leading-[25px] tracking-wide">We use secure servers and encryption technologies to protect your data during transmission and storage.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">4. Sharing Your Information:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">We do not sell, trade, or rent your personal information or video proof data to third parties.</li>
            <li className="text-base leading-[25px] tracking-wide">Your video proof data is stored securely and is not accessible to any other users of Vidscan Tech.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">5. Retention of Data:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">We retain your personal information and video proof data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">6. Account Deletion:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">If your account goes to zero or negative credits and is not recharged within 30 days, your account and all associated data will be permanently deleted.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">7. Cookies and Tracking Technologies:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">Vidscan Tech may use cookies and tracking technologies to improve user experience, understand user behavior, and enhance the software's functionality.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">8. Third-Party Services:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">Vidscan Tech may integrate with third-party services or platforms to enhance its features. Any data shared with these third parties will be subject to their respective privacy policies.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">9. Updates to Privacy Policy:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">We may update this Privacy Policy from time to time. Changes will be posted on our website, and your continued use of Vidscan Tech will indicate your acceptance of the updated policy.</li>
          </ul>
        </div>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">10. Contact Us:</h5>
          <ul className="pl-[23px] list-disc marker:font-semibold space-y-4">
            <li className="text-base leading-[25px] tracking-wide">If you have any questions, concerns, or requests regarding your personal information or this Privacy Policy, please contact us at [contact email/phone number].</li>
          </ul>
        </div>
        <p className="text-base leading-[30px] tracking-wide">By using Vidscan Tech, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your personal information and video proof data as described herein.</p>
        <address className="flex flex-col gap-y-1 text-lg font-medium leading-[25px] tracking-wider">
          <p>Vidscan Tech</p>
          <p>E-1102 Suncity Heights, Gurgaon, Haryana.</p>
          <a className="text-primary" href="mailto:Naman@vidscantech.com" target="_vidscan">Naman@vidscantech.com</a>
        </address>
        <div className="space-y-2">
          <h5 className="text-2xl font-semibold tracking-wider">Data Security and Privacy</h5>
          <p className="text-base leading-[30px] tracking-wide">We understand the importance of protecting your data. Vidscan Tech utilizes robust security measures, encryption protocols, and compliance standards to safeguard your information and ensure your peace of mind.</p>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPolicy