import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { privateAdminAxios } from "../../lib/axios";
import { ADD_OFFLINE_PAYMENT } from "../../constant/endpoints";
import { toast } from "react-toastify";

const AddOfflinePayment = ({ modal, setModal }) => {
  const [data, setData] = useState({
    credits: "",
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleAddOfflinePayment = async (payload) => {
    const response = await privateAdminAxios(ADD_OFFLINE_PAYMENT, payload);
    if (response?.ResponseCode == 1 && response?.Result == "True") {
      toast.success(response?.ResponseMsg);
      setModal({data:null,open:false})
    } else {
      toast.success(response?.ResponseMsg);
    }
  };

  return (
    <Dialog
      open={modal.open}
      onOpenChange={(open) => setModal({ open, data: null })}
    >
      <DialogContent className="px-0 flex flex-col overflow-hidden">
        <h5 className="text-2xl px-4">{modal?.data?.name}</h5>
        <div className="px-5 space-y-3">
          <div className="flex flex-col space-y-0.5">
            <label className="text-base font-normal">Enter Credits</label>
            <Input
              name="credits"
              placeholder="Enter credits "
              type="number"
              onChange={(e) => {
                handleChange(e);
              }}
              // onChange={(e) => {
              //   setEmails(e.target.value);
              // }}
              // value={emails}
            />
          </div>
          <div className="flex flex-col space-y-0.5">
            <label className="text-base font-normal">Enter Amount</label>
            <Input
              name="amount"
              placeholder="Enter amount "
              type="number"
              onChange={(e) => {
                handleChange(e);
              }}
              // onChange={(e) => {
              //   setEmails(e.target.value);
              // }}
              // value={emails}
            />
          </div>
          <div className="flex justify-end gap-x-3 pt-5">
            <Button
              type="button"
              onClick={() => setModal({ open: false, data: null, mode: null })}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                handleAddOfflinePayment({
                  user_id: modal?.data?.user_id,
                  credits: data?.credits,
                  amount: data?.amount,
                });
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddOfflinePayment;
