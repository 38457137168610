import { useLocation } from "react-router-dom"

const Aboutus = () => {
    const { pathname } = useLocation()

    return (
        <section className="py-4">
            {!pathname.includes("/aboutus")
                ? <h3 className="py-2 px-3 text-text text-center font-bold text-4xl font-lato">
                    About Us: Vidscan Tech
                </h3>
                : null}
            <div className="p-4 w-full max-w-[750px] mx-auto space-y-6">
                <p className="text-base leading-[30px] tracking-wide">
                    Welcome to Vidscan Tech, your reliable partner in safeguarding e-commerce vendors against false claims and fraud returns. Our innovative software service empowers vendors to record video proof of dispatches and returns, providing an indisputable record of each shipment's condition and packaging.
                </p>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Our Story</h5>
                    <p className="text-base leading-[30px] tracking-wide">At Vidscan Tech, we believe in the power of technology to solve real-world challenges. Our journey began when our founder, Naman Goyal, encountered numerous fraudulent return claims and false dispatch accusations while running an e-commerce business. Determined to find a solution, we embarked on a mission to create a user-friendly platform that would protect vendors' interests and foster trust in online transactions.</p>
                </div>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Our Mission</h5>
                    <p className="text-base leading-[30px] tracking-wide">Our mission at Vidscan Tech is to revolutionize the e-commerce industry by providing vendors with an efficient and cost-effective tool to capture video evidence of their dispatches and returns. We aim to empower businesses of all sizes, from small vendors to large enterprises, to protect their revenue and reputation with our video proof service.</p>
                </div>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Our Values</h5>
                    <p className="text-base leading-[30px] tracking-wide">Transparency, trust, and excellence are the cornerstones of Vidscan Tech. We are committed to upholding the highest standards of data security and privacy, ensuring that our users' information is safe and confidential. Our team is passionate about providing an exceptional user experience, and we constantly strive to innovate and improve our service.</p>
                </div>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Our Team</h5>
                    <p className="text-base leading-[30px] tracking-wide">Behind Vidscan Tech is a dedicated team of technology enthusiasts and industry experts. Each team member brings unique skills and perspectives, contributing to our collective vision of creating a reliable and user-friendly solution for e-commerce vendors.</p>
                </div>
                <div className="space-y-2">
                    <h5 className="text-2xl font-semibold tracking-wider">Why Choose Us</h5>
                    <ul className="list-decimal marker:font-semibold list-inside space-y-2">
                        <li className="text-base leading-[25px] tracking-wide">Seamless Video Proof: Our application allows you to record video proof with just a few taps, ensuring hassle-free and efficient dispatch and return documentation.</li>
                        <li className="text-base leading-[25px] tracking-wide">Cost-Effective: With our pay-per-use pricing model, both small and large vendors can access our service at a nominal cost, saving money on false claims and fraudulent returns.</li>
                        <li className="text-base leading-[25px] tracking-wide">Offline Recording: Record video proof even without an internet connection. Our auto-sync feature ensures your data is securely uploaded once you're back online.</li>
                        <li className="text-base leading-[25px] tracking-wide">Custom Branding: Premium vendors can reinforce their brand identity by customizing the application's branding with their logo and colors, creating a consistent and professional experience for customers.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Aboutus