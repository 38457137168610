import React from 'react'
import { Dialog, DialogContent } from '../ui/dialog'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Button } from '../ui/button'

        const VideoQuality = ({ modal, setModal, onChange, handleSubmit, value }) => {
    return (
        <Dialog open={modal.open}
            onOpenChange={(open) => setModal({ open, data: null })}>
            <DialogContent>
                <Label className="text-text text-2xl">
                    Add Video Quality
                </Label>
                <Input placeholder="Enter video quality" type="number" onChange={onChange}  max={100} value={value} />
                <div className="flex justify-end gap-x-3">
                    <Button
                        type="button"
                        onClick={() =>
                            setModal({ open: false, data: null, mode: null })
                        }
                    >
                        Cancel
                    </Button>
                    <Button type="submit" onClick={handleSubmit}>
                        Go
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default VideoQuality