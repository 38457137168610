import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Checkbox } from "../../components/ui/checkbox";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ChevronDown } from "lucide-react";
import { twMerge } from "tailwind-merge";
import { signupSchema } from "../../lib/schemas";
import { GETINITIALFOLDER, SIGNUP } from "../../constant/endpoints";
import { privateAxios } from "../../lib/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toastHandler } from "../../lib/helper";
import countyCode from "../../lib/countrycode.json";
import Logo from "../../assets/images/logoWithSlogan.jpg";
import md5 from "md5";

const Signup = () => {
  const [isCountrySelector, seIsCountrySelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("+91");

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const form = useForm({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      phone: "",
      name: "",
      email: "",
      gst: "",
      company: "",
      password: "",
      confirm_password: "",
      isPolicyAccepted: false,
    },
  });

  const { mutate: signUp } = useMutation({
    mutationFn: (values) => privateAxios(SIGNUP, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1" || res.ResponseCode === "2") {
        navigate("/login");
        queryClient.invalidateQueries({ queryKey: [GETINITIALFOLDER] });
        form.reset();
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const onSubmit = (values) => {
    const { isPolicyAccepted, confirm_password, password, ...rest } = values;
    signUp({
      ...rest,
      password: md5(password),
      ccode: selectedCountry.slice(1, selectedCountry.length),
    });
    return;
  };

  return (
    <div className="w-full max-w-xl h-max min-h-screen mx-auto flex flex-col justify-center">
      <div className="space-y-10">
        <div className="text-center space-y-6">
          <div className="max-w-[350px] mx-auto">
            <img src={Logo} alt="logo" />
          </div>
          <h1 className="text-primary text-2xl font-lato font-semibold">
            Sign up to Vidscan Tech
          </h1>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full h-max space-y-6"
          >
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel
                    htmlFor="mobileNumber"
                    className="text-text text-base font-lato"
                  >
                    Mobile Number
                  </FormLabel>
                  <FormControl>
                    <div className="flex">
                      <Popover
                        open={isCountrySelector}
                        onOpenChange={() =>
                          seIsCountrySelector(!isCountrySelector)
                        }
                      >
                        <PopoverTrigger className="w-full max-w-[85px] px-4 flex items-center justify-between gap-x-1 bg-bg2-hover rounded-tl-lg rounded-bl-lg outline-none">
                          <span className="text-sm text-text">
                            {selectedCountry}
                          </span>
                          <ChevronDown className="w-4 h-4 text-text2" />
                        </PopoverTrigger>
                        <PopoverContent align="start" className="p-0">
                          <Command className="bg-bg2 text-text2">
                            <CommandInput
                              placeholder="Search country name"
                              className="placeholder:text-text2"
                            />
                            <CommandList>
                              <CommandEmpty className="py-4 text-center">
                                No country found
                              </CommandEmpty>
                              {countyCode.countries.map((country, index) => {
                                return (
                                  <CommandItem
                                    key={index}
                                    className={twMerge(
                                      "aria-selected:bg-bg2-hover text-text aria-selected:text-text cursor-pointer",
                                      selectedCountry === country.dial &&
                                        "bg-bg2-hover aria-selected:bg-bg2-hover cursor-default"
                                    )}
                                    onSelect={() =>
                                      selectedCountry === country.dial
                                        ? null
                                        : (setSelectedCountry(country.dial),
                                          seIsCountrySelector(false))
                                    }
                                  >
                                    {country.name}
                                  </CommandItem>
                                );
                              })}
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <Input
                        id="mobileNumber"
                        type="number"
                        placeholder="Enter your mobile number"
                        {...field}
                        className="ps-0 rounded-tl-none rounded-bl-none border-transparent"
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex gap-x-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel className="text-text text-base font-lato">
                      Name *
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel className="text-text text-base font-lato">
                      Email *
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Enter your email"
                        onChange={({ target: { value } }) =>
                          form.setValue("email", value.toLowerCase())
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex gap-x-4">
              <FormField
                control={form.control}
                name="gst"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel className="text-text text-base font-lato">
                      GST Number
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your GST number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="company"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel className="text-text text-base font-lato">
                      Company Name
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your company name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Password
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirm_password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Confirm Password
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Re-enter your password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="isPolicyAccepted"
              render={({ field }) => (
                <FormItem className="w-full flex flex-col">
                  <div className="flex items-center space-x-3">
                    <FormControl>
                      <Checkbox
                        placeholder="Re-enter your password"
                        {...field}
                        onCheckedChange={(bool) =>
                          form.setValue("isPolicyAccepted", bool)
                        }
                      />
                    </FormControl>
                    <FormLabel className="text-text font-lato text-sm">
                      You are agree to our &nbsp;
                      <a
                        href="terms"
                        className="text-primary font-semibold underline"
                        target="_vidscan"
                      >
                        Terms and Conditions
                      </a>
                      &nbsp; and that you have read out &nbsp;
                      <a
                        href="privacy"
                        className="text-primary font-semibold underline"
                        target="_vidscan"
                      >
                        Privacy Policy
                      </a>
                      .
                    </FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full font-lato font-bold text-lg bg-primary"
            >
              Sign Up
            </Button>

            <div className="space-y-1 text-center">
              <p className="text-center text-text2 text-sm">
                Already have an account? &nbsp;
                <Link to="/login" className="text-primary font-semibold">
                  Log In
                </Link>
              </p>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default Signup;
